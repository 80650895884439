const Routes = {
  default: "/",
  auth: "/auth",
  termsAndCondition: "/termsandcondition",
  privacyPolicy: "/privacypolicy",
  login: "/auth/login",
  register: "/auth/register",
  team: "/team",
  joinTeam: "/team/joinTeam",
  createTeam: "/team/createTeam",
  event: "/event",
  adminUsers: "/dashboard/users",
  challenge_category: "/dashboard/challenge/:category",
  challenge: "/dashboard/challenge",
  plans: "/dashboard/plans",
  adminTeams: "/dashboard/teams",
  adminChallenges: "/dashboard/challenges",
  adminAnnouncements: "/dashboard/announcements",
  adminStatistics: "/dashboard/statistics",
  scoreboard: "/dashboard/scoreboard",
  adminLogs: "/dashboard/logs",
  adminEvent: "/dashboard/event",
  dashboard: "/dashboard",
  announcements: "/dashboard/announcements",
  subscribePlan: "/subscribePlan",
  profile: "/dashboard/profile/:username",
  teamStates: "/dashboard/teamstats/:teamname",
  refundAndCancellationPolicy: "/refund-and-cancellation-policy",
  contactUs: "/contact-us",
  notfound: "*",
};
export default Routes;
