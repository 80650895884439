import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import {
  UserEdit,
  api,
  headers,
  GET_USERS,
  SEARCH,
  PaginationComponent,
  LOADING,
  ALERT,
  SmLoading,
  Toggle,
} from "../../";

import orderBy from "lodash/orderBy";
import AddUser from "./AddUser";

const TableBody = ({ paginateItems, setUser }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector((state) => state.config);
  const [id, setID] = useState("");
  async function handleEdit(username) {
    dispatch({
      type: LOADING,
    });
    try {
      const res = await api.getUser(username);
      setUser(res.data);
      history.push(`/dashboard/users/${username}`);
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  const filtered = orderBy(
    paginateItems,
    [(s) => s.username.toLowerCase()],
    ["asc"]
  );

  return (
    <div className="m_table_data">
      {filtered &&
        filtered.map((user, index) => (
          <div
            key={index}
            onClick={() => setID(user.id)}
            className={`m_table_data_item  ${
              id === user.id ? "white-space" : ""
            }`}
          >
            <div className="m_flex-1">
              <span
                onClick={() =>
                  history.push(`/dashboard/profile/${user.username}`)
                }
                className="m_pointer"
              >
                {user.username}
              </span>
            </div>

            <div className="m_flex-1 m_d-flex">
              {config.team_mode && (
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() =>
                    history.push(`/dashboard/teamstats/${user.team}`)
                  }
                  className="m_pointer"
                >
                  {user.team}
                </span>
              )}
              <div
                onClick={() => handleEdit(user.username)}
                className="m_icon m_icon_light m_d-flex m_flex-1 m_justify-content-end"
              >
                <ArrowForwardIosOutlinedIcon />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

function Users() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const config = useSelector((state) => state.config);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const res = await api.getUsers();
        dispatch({
          type: GET_USERS,
          data: res.data.users,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [dispatch]);

  useEffect(() => {
    console.log("HERE");
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "users",
        showImport: true,
      },
    });
    return () => {
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "", showImport: false },
      });
    };
  }, [dispatch]);

  var header = headers.users;
  var filtered_users = users;
  if (!config.team_mode) {
    header = header.filter((h) => h !== "Team");
    filtered_users = users.map((user) => {
      return {
        id: user.id,
        username: user.username,
      };
    });
  }
  return (
    <div className="m_users_container">
      {loading ? (
        <SmLoading />
      ) : (
        <Switch>
          <Route path="/dashboard/users/add">
            <Toggle>
              <AddUser />
            </Toggle>
          </Route>
          <Route path="/dashboard/users/:username_edit">
            <Toggle>
              <UserEdit user={user} />
            </Toggle>
          </Route>
          <Route exact path="/dashboard/users">
            <PaginationComponent
              heading="USERS"
              headers={header}
              items={filtered_users}
              TableBody={(items) => (
                <TableBody setUser={setUser} paginateItems={items} />
              )}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default Users;
