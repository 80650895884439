import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { GET_DEPLOYMENTS, Input, api, LOADING, ALERT } from "../../";

function AddDeployment({ id, handleToggle }) {
    const dispatch = useDispatch();
    const [docker_image, setdocker_image] = useState("");
    const [container_port, setcontainer_port] = useState("");
    const handleAddChallengeDeployment = async () => {
        try {
            dispatch({
                type: LOADING,
                data: true,
            });
            await api.addChallengeDeployment({
                cid: id,
                docker_image,
                container_port: parseInt(container_port),
            });
            const res = await api.getChallengesDeployments();
            dispatch({
                type: GET_DEPLOYMENTS,
                data: res.data.challenge_deployments,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment created`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment failed`,
                    type: "error",
                },
            });
        } finally {
            handleToggle();
            dispatch({
                type: LOADING,
                data: true,
            });
        }
    };
    return (
        <>
            <Input
                label="DOCKER IMAGE"
                placeholder="Docker image"
                value={docker_image}
                onChange={setdocker_image}
            />
            <Input
                label="CONTAINER PORT"
                placeholder="Container port"
                value={container_port}
                onChange={setcontainer_port}
            />

            <div className="text-center">
                <button
                    onClick={handleAddChallengeDeployment}
                    className="btn btn--medium"
                >
                    Submit
                </button>
            </div>
        </>
    );
}

export default AddDeployment;
