import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import {
    api,
    searchData,
    GET_CHALLENGE_LOGS,
    date_time,
    Tooltips,
} from "../../";
import { useHistory } from "react-router";

function ChallengeLogs({ search }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const challengeLogs = useSelector((state) => state.challengeLogs);
    var filteredChallenges = searchData(search, challengeLogs);
    const config = useSelector((state) => state.config);
    const [id, setID] = useState("");
    useEffect(() => {
        api.getChallengeLogs().then((res) => {
            let logs = res.data.challenge_logs;
            logs = orderBy(logs, ["time"], ["desc"]);
            dispatch({
                type: GET_CHALLENGE_LOGS,
                data: logs,
            });
        });
    }, [dispatch]);

    return (
        <div className="m_table_data">
            {filteredChallenges &&
                filteredChallenges.map((cl, index) => (
                    <div
                        key={index}
                        onClick={() => setID(cl.id)}
                        className={`m_table_data_item ${
                            id === cl.id ? "white-space" : ""
                        }`}
                    >
                        <div className="m_log_row">
                            <span
                                className="truncate text-left m_flex-1"
                                onClick={() =>
                                    history.push(
                                        `/dashboard/profile/${cl.user}`
                                    )
                                }
                            >
                                {cl.user}
                            </span>
                            <Tooltips
                                title={cl.challenge}
                                className="truncate text-right m_flex-1"
                            >
                                {cl.challenge}
                            </Tooltips>
                        </div>
                        <div className="m_log_row">
                            {config.team_mode && (
                                <div className="m_flex-1">
                                    <span
                                        className="m_pointer"
                                        onClick={() =>
                                            history.push(
                                                `/dashboard/teamstats/${cl.team}`
                                            )
                                        }
                                    >
                                        {cl.team}
                                    </span>
                                </div>
                            )}
                            <Tooltips
                                title={cl.log_message}
                                className="truncate text-right m_flex-1"
                            >
                                {cl.log_message}
                            </Tooltips>
                        </div>
                        <div className="m_log_row">
                            <div className="m_flex-1">
                                <h6 className="m_spaced">
                                    {date_time(cl.time)}
                                </h6>
                            </div>
                            <Tooltips
                                title={cl.ip_address}
                                className="truncate m_flex-1 text-right"
                                style={{
                                    fontSize: "1rem",
                                    color: "var(--text_color_5)",
                                }}
                            >
                                {cl.ip_address}
                            </Tooltips>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default ChallengeLogs;
