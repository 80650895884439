import React from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { gravatar } from "../../";
import { useHistory } from "react-router";
function Profile({
    showEdit,
    profile,
    points,
    challenges_solved,
    total_challenges,
}) {
    const history = useHistory();
    var c = 0;
    var t = 0;
    if (challenges_solved) {
        c = challenges_solved;
    }
    if (total_challenges) {
        t = total_challenges;
    }
    const perChallenges = `${c <= 9 ? "0" + c : c}/${t <= 9 ? "0" + t : t}`;
    return (
        <div className="m_card__container">
            <div className="m_responsive_profile">
                <div className="m_d-flex">
                    <div className="m_setting_profile_avatar">
                        <img
                            style={{ width: "11rem", height: "11rem" }}
                            src={gravatar(profile.gravatar_hash)}
                            alt=""
                        />

                        {showEdit && (
                            <div
                                onClick={() =>
                                    window.open("https://en.gravatar.com/")
                                }
                                className="m_icon m_icon__sm"
                            >
                                <EditOutlinedIcon />
                            </div>
                        )}
                    </div>
                    <div className="m_setting_profile m_ml-1">
                        <h2>{profile.username}</h2>
                        {profile.teamname !== "NA" && (
                            <h4
                                style={{
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                onClick={() =>
                                    history.push(
                                        `/dashboard/teamstats/${profile.teamname}`
                                    )
                                }
                            >
                                {profile.teamname}
                            </h4>
                        )}
                        <h4>{profile.email}</h4>
                    </div>
                </div>
                <div className="m_d-flex justify-content-between mt-1">
                    <div className="m_flex-1">
                        <div style={{ justifySelf: "flex-end" }}>
                            <h6 className="m_spaced mb-1">POINTS</h6>
                        </div>
                        <h2>
                            <strong>
                                {points <= 9 ? "0" + points : points || "00"}
                            </strong>
                        </h2>
                    </div>
                    <div className="m_flex-1">
                        <div style={{ justifySelf: "flex-end" }}>
                            <h6
                                className="m_spaced mb-1"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                SOLVES
                            </h6>
                        </div>
                        <h2>
                            <strong>{perChallenges}</strong>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
