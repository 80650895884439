import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ALERT } from "../";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function AlertMessage() {
    const dispatch = useDispatch();
    const alertMessage = useSelector((state) => state.alertMessage);
    const classes = useStyles();
    const handleClose = () => {
        dispatch({
            type: ALERT,
            data: { open: false },
        });
    };

    return (
        <div className={classes.root}>
            <Snackbar
                open={alertMessage.open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={
                        alertMessage.type === "error"
                            ? "error"
                            : alertMessage.type === "success"
                            ? "success"
                            : "warning"
                    }
                >
                    {alertMessage.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
