import React from "react";
import { Toggle } from "../../";
import cup from "../../assets/cup.svg";
function Congratulation({ handleToggle }) {
  return (
    <Toggle style={{ zIndex: 100 }} handleToggle={handleToggle}>
      <div className="d-flex align-items-center flex-column">
        <div style={{ width: "11rem" }}>
          <img src={cup} alt="" />
        </div>
        <h2>Congratulations</h2>
        <h3>You've completed this challenge.</h3>
        <button
          onClick={handleToggle}
          className="btn btn--medium"
          style={{ margin: "2rem 0" }}
        >
          Close
        </button>
      </div>
    </Toggle>
  );
}

export default Congratulation;
