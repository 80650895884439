import React from "react";
import { TableHeading, date_time, getIcon } from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import hard from "../../assets/hard.png";
import medium from "../../assets/medium.png";
import easy from "../../assets/easy.png";
import { useSelector } from "react-redux";
function ChallengeInfo({ categorical_challenges, categorical_solves, solves }) {
    function getDefault(difficulty) {
        if (difficulty.toLowerCase() === "hard") {
            return hard;
        } else if (difficulty.toLowerCase() === "medium") {
            return medium;
        } else {
            return easy;
        }
    }
    const challenges = useSelector((state) => state.user_challenges);
    if (!(challenges && challenges.length > 0)) return null;
    return (
        <div className="challenges_info">
            {categorical_challenges &&
                Object.keys(categorical_challenges).length > 0 && (
                    <div className="table">
                        <TableHeading heading="Categorical Solves" />
                        <div className="challenges_info_data">
                            {categorical_challenges &&
                                Object.keys(categorical_challenges).map(
                                    (category_name, index) => {
                                        const percentage = isNaN(
                                            categorical_solves[category_name] /
                                                categorical_challenges[
                                                    category_name
                                                ]
                                        )
                                            ? 0
                                            : categorical_solves[
                                                  category_name
                                              ] /
                                              categorical_challenges[
                                                  category_name
                                              ];
                                        const boxShadow = percentage
                                            ? "0px 0px 10px 4px rgba(61, 159, 0, 0.15)"
                                            : "none";

                                        if (
                                            categorical_challenges[
                                                category_name
                                            ]
                                        )
                                            return (
                                                <div
                                                    key={index}
                                                    className="challenge_data_item"
                                                >
                                                    <div
                                                        style={{
                                                            flex: 0.35,
                                                            textAlign: "left",
                                                            overflow: "hidden",
                                                            whiteSpace:
                                                                "no-wrap",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                    >
                                                        <h4
                                                            style={{
                                                                whiteSpace:
                                                                    "no-wrap",
                                                            }}
                                                        >
                                                            {category_name}
                                                        </h4>
                                                    </div>
                                                    <div className="slider">
                                                        <div
                                                            style={{
                                                                flex: percentage,
                                                                height: "1rem",
                                                                boxShadow,
                                                                background:
                                                                    "var(--green)",
                                                                borderRadius:
                                                                    "1rem",
                                                                position:
                                                                    "relative",
                                                                display: "flex",
                                                                transition:
                                                                    "all 1s linear",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 0.1,
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <h4>
                                                            {
                                                                categorical_solves[
                                                                    category_name
                                                                ]
                                                            }
                                                            /
                                                            {
                                                                categorical_challenges[
                                                                    category_name
                                                                ]
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                            );
                                        return null;
                                    }
                                )}
                        </div>
                    </div>
                )}
            {solves && solves.length > 0 && (
                <div className="table">
                    <TableHeading heading="Solves" />
                    <div className="challenge_page">
                        {solves &&
                            solves.map((solve) => (
                                <div
                                    key={solve.cid}
                                    className="profile_challenge_card"
                                >
                                    <div className="team_challenges_img">
                                        <img
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = getDefault(
                                                    solve.difficulty
                                                );
                                            }}
                                            src={solve.challenge_logo}
                                            alt=""
                                            style={{ width: "5rem" }}
                                        />
                                    </div>
                                    <div className="team_state_challenge text-left mr-1">
                                        <div className="d-flex">
                                            <h4
                                                style={{
                                                    overflow: "hidden",
                                                    flex: 1,
                                                    width: "5rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {solve.challenge_name}
                                            </h4>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="icon">
                                                {getIcon(
                                                    solve.category.category_icon
                                                )}
                                            </div>
                                            <h4
                                                style={{
                                                    marginLeft: "0.5rem",
                                                    overflow: "hidden",
                                                    flex: 1,
                                                    width: "5rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {solve.category.category_name}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="team_state_challenge_points text-right mr-1">
                                        <div className="d-flex align-items-center">
                                            <h4
                                                style={{
                                                    marginRight: "0.5rem",
                                                    marginTop: "0.5rem",
                                                }}
                                            >
                                                {solve.points}
                                            </h4>
                                            <OfflineBoltOutlinedIcon
                                                style={{
                                                    color: "var(--yellow)",
                                                    fontSize: "2rem",
                                                    marginRight: "0.5",
                                                    marginTop: "0.5rem",
                                                }}
                                            />
                                        </div>
                                        <h4 style={{ whiteSpace: "nowrap" }}>
                                            {date_time(solve.solve_time)}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChallengeInfo;
