import React from "react";
import Input from "./Input";
import { useDispatch } from "react-redux";
import { SEARCH } from "../store/actionsTypes";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
function Search({ handleClose, search }) {
    const dispatch = useDispatch();
    const onChange = (value) => {
        dispatch({
            type: SEARCH,
            data: { ...search, value },
        });
    };
    if (search.placeholder) {
        return (
            <div className="m_search">
                <Input
                    placeholder="search"
                    value={search.value}
                    onChange={onChange}
                    className="m_input__icon"
                >
                    <div className="m_icon">
                        <ClearOutlinedIcon onClick={handleClose} />
                    </div>
                </Input>
            </div>
        );
    } else return null;
}

export default Search;
