import { useState } from "react";
import { Input, api } from "../../";
import { useHistory } from "react-router";
import { ALERT, ToolTip } from "../../";
import { useDispatch } from "react-redux";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
function CreateTeam() {
    const [teamName, setTeamName] = useState("");
    const [_teamName, set_TeamName] = useState("");
    const [message, setMessage] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const handleCreateTeam = async () => {
        if (!teamName) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please enter team name",
                    type: "error",
                },
            });
            return;
        }
        api.createTeam({ team_name: teamName })
            .then((res) => {
                setMessage(res.data);
                set_TeamName(teamName);
                setTeamName("");
            })
            .catch((err) => {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: err.response.data.status,
                        type: "error",
                    },
                });
            });
    };

    function handleTeamName(teamname) {
        const len = teamname.length - 1;
        if (!teamname.match(/^[0-9a-zA-Z_ ]+$/) && teamname[len]) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `' ${teamname[len]} ' not allowed`,
                    type: "error",
                },
            });
        } else {
            setTeamName(teamname);
        }
    }
    function handleKeyDown(e) {
        if (e.key === "Enter") {
            handleCreateTeam();
        }
    }
    return (
        <div className="auth__container">
            <div className="auth card__container" style={{ width: "55rem" }}>
                <div className="card__primary auth__inputs">
                    <Input
                        label="TEAM NAME"
                        type="text"
                        placeholder="Team Name"
                        value={teamName}
                        onChange={handleTeamName}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="text-center">
                        <button
                            onClick={!message ? handleCreateTeam : ""}
                            className={`btn  ${
                                message ? "tab-btn" : "btn btn--medium"
                            }`}
                        >
                            Create Team
                        </button>
                        {message && (
                            <div className="text-center mt-2">
                                <div className="d-flex align-items-center">
                                    <div
                                        className="d-flex flex-column"
                                        style={{ textAlign: "left" }}
                                    >
                                        <h3>
                                            <strong>Team Name : </strong>
                                            {_teamName}
                                        </h3>
                                        <div className="d-flex align-items-center">
                                            <h3>
                                                <strong>Team Token : </strong>
                                                {message.team_token}
                                            </h3>
                                            <ToolTip n={2} item="Copy token">
                                                <div
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            message.team_token
                                                        );
                                                        dispatch({
                                                            type: ALERT,
                                                            data: {
                                                                open: true,
                                                                message:
                                                                    "Copied",
                                                                type: "success",
                                                            },
                                                        });
                                                    }}
                                                    className="ml-1 icon icon__primary"
                                                >
                                                    <FileCopyOutlinedIcon />
                                                </div>
                                            </ToolTip>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    onClick={() =>
                                        history.push("/dashboard/challenge")
                                    }
                                    className="btn btn--medium mt-2"
                                >
                                    Dashboard
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateTeam;
