import React, { useState } from "react";
import { api, InputPassword, ALERT } from "../../";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
function ResetPassword({ site_key }) {
    const { userid, secret_token } = useParams();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [password, setPassword] = useState("");
    const history = useHistory();
    const [confirm_password, setConfirmNewPassword] = useState("");
    const dispatch = useDispatch();
    async function handleSubmit() {
        try {
            if (password !== confirm_password) {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Password does not match",
                        type: "error",
                    },
                });
                return;
            }
            var data = {
                userid,
                secret_token,
                password,
                confirm_password,
            };
            if (site_key) {
                const g_recaptcha_response = await executeRecaptcha(
                    "reset_password"
                );
                data = { ...data, g_recaptcha_response };
            }
            const res = await api.resetpassword(data);
            if (res && res.data && res.data.status === "ok") {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Password changed",
                        type: "success",
                    },
                });
            }
            history.push("/auth/login");
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response.data.status,
                    type: "error",
                },
            });
        }
    }
    return (
        <>
            <InputPassword
                label="New Password"
                placeholder="New Password"
                value={password}
                onChange={setPassword}
            />
            <InputPassword
                label="Confirm New Password"
                placeholder="Confirm New Password"
                value={confirm_password}
                onChange={setConfirmNewPassword}
            />
            <div className="m_text-center mt-1">
                <button onClick={handleSubmit} className="btn btn--medium">
                    Submit
                </button>
            </div>
        </>
    );
}

export default ResetPassword;
