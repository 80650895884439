import "date-fns";
import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
export default function Mui_DateTimePicker({ value, onChange, id1, id2 }) {
    return (
        <div className="time_responsive">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    id={id1}
                    format="MM/dd/yyyy"
                    value={new Date(value * 1000)}
                    onChange={onChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    keyboardIcon={<EventAvailableIcon />}
                />
                <div className="margin_time">
                    <KeyboardTimePicker
                        id={id2}
                        value={new Date(value * 1000)}
                        onChange={onChange}
                        ampm={false}
                        KeyboardButtonProps={{
                            "aria-label": "change time",
                        }}
                        keyboardIcon={<AccessTimeIcon />}
                    />
                </div>
            </MuiPickersUtilsProvider>
        </div>
    );
}
