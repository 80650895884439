import { Nav } from "../";
function Dropdown({ dropdownlist, hide, item }) {
    return (
        <div onMouseLeave={hide} className="dropdown_container">
            <div className="dropdownList">
                {dropdownlist.map((d, index) => (
                    <Nav key={index} nav={d} onClick={() => d.handler(item)} />
                ))}
            </div>
        </div>
    );
}
export default Dropdown;
