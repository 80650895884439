import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import { api, GET_USERS_LOGS, searchData, date_time, Tooltips } from "../../";

import { useHistory } from "react-router";

function AdminLogs({ search }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLogs = useSelector((state) => state.userLogs);
    const [id, setID] = useState("");

    const filteredUsers = searchData(search, userLogs);
    useEffect(() => {
        async function fetch() {
            try {
                const res = await api.getAdminLogs();
                let logs = res.data.user_logs;
                logs = orderBy(logs, ["time"], ["desc"]);
                dispatch({
                    type: GET_USERS_LOGS,
                    data: logs,
                });
            } finally {
            }
        }
        fetch();
    }, [dispatch]);
    return (
        <div className="m_table_data">
            {filteredUsers &&
                filteredUsers.map((u, index) => (
                    <div
                        key={index}
                        onClick={() => setID(u.id)}
                        className={`m_table_data_item ${
                            id === u.id ? "white-space" : ""
                        }`}
                    >
                        <div className="m_log_row">
                            <span
                                className="truncate text-left m_flex-1"
                                onClick={() =>
                                    history.push(`/dashboard/profile/${u.user}`)
                                }
                            >
                                {u.user}
                            </span>
                            <Tooltips
                                title={u.log_message}
                                className="truncate text-right m_flex-1"
                            >
                                {u.log_message}
                            </Tooltips>
                        </div>
                        <div className="m_log_row">
                            <div className="m_flex-1">
                                <h6 className="m_spaced">
                                    {date_time(u.time)}
                                </h6>
                            </div>
                            <Tooltips
                                title={u.ip_address}
                                className="truncate m_flex-1 text-right"
                                style={{
                                    fontSize: "1rem",
                                    color: "var(--text_color_5)",
                                }}
                            >
                                {u.ip_address}
                            </Tooltips>
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default AdminLogs;
