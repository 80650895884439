import React from "react";
import { TableHeading, date_time, getIcon, Tooltips } from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import hard from "../../assets/hard.png";
import medium from "../../assets/medium.png";
import easy from "../../assets/easy.png";
import { useSelector } from "react-redux";

function ChallengeInfo({ categorical_challenges, categorical_solves, solves }) {
    function getDefault(difficulty) {
        if (difficulty.toLowerCase() === "hard") {
            return hard;
        } else if (difficulty.toLowerCase() === "medium") {
            return medium;
        } else {
            return easy;
        }
    }
    const challenges = useSelector((state) => state.user_challenges);

    if (!(challenges && challenges.length > 0)) return null;
    return (
        <div className="m_challenges_info mb-1">
            {categorical_challenges &&
                Object.keys(categorical_challenges).length > 0 && (
                    <div className="m_table" style={{ overflow: "visible" }}>
                        <h6
                            className="m_spaced"
                            style={{ textTransform: "uppercase" }}
                        >
                            Categorical Solves
                        </h6>
                        <div className="m_challenges_info">
                            {categorical_challenges &&
                                Object.keys(categorical_challenges).map(
                                    (category_name, index) => {
                                        const percentage = isNaN(
                                            categorical_solves[category_name] /
                                                categorical_challenges[
                                                    category_name
                                                ]
                                        )
                                            ? 0
                                            : categorical_solves[
                                                  category_name
                                              ] /
                                              categorical_challenges[
                                                  category_name
                                              ];
                                        const boxShadow = percentage
                                            ? "0px 0px 10px 4px rgba(61, 159, 0, 0.15)"
                                            : "none";

                                        if (
                                            categorical_challenges[
                                                category_name
                                            ]
                                        )
                                            return (
                                                <div
                                                    key={index}
                                                    className="m_challenge_data_item"
                                                >
                                                    <Tooltips
                                                        title={category_name}
                                                        style={{
                                                            flex: 0.8,
                                                            textAlign: "left",
                                                            overflow: "hidden",
                                                            whiteSpace:
                                                                "nowrap",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                    >
                                                        {category_name}
                                                    </Tooltips>

                                                    <div className="m_slider ml-1">
                                                        <div
                                                            style={{
                                                                flex: percentage,
                                                                height: "1rem",
                                                                boxShadow,
                                                                background:
                                                                    "var(--green)",
                                                                borderRadius:
                                                                    "1rem",
                                                                position:
                                                                    "relative",
                                                                display: "flex",
                                                                transition:
                                                                    "all 1s linear",
                                                            }}
                                                        ></div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            flex: 0.35,
                                                            textAlign: "right",
                                                            marginLeft:
                                                                "0.5rem",
                                                        }}
                                                    >
                                                        {
                                                            categorical_solves[
                                                                category_name
                                                            ]
                                                        }
                                                        /
                                                        {
                                                            categorical_challenges[
                                                                category_name
                                                            ]
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        return null;
                                    }
                                )}
                        </div>
                    </div>
                )}
            {solves && solves.length > 0 && (
                <div className="m_table">
                    <TableHeading heading="Solves" />
                    <div className="m_challenge_page">
                        {solves &&
                            solves.map((solve) => (
                                <div
                                    key={solve.cid}
                                    className="m_profile_challenge_card"
                                >
                                    <div className="m_team_challenges_img">
                                        <img
                                            className="m_team_challenges_img"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = getDefault(
                                                    solve.difficulty
                                                );
                                            }}
                                            src={solve.challenge_logo}
                                            alt=""
                                        />
                                    </div>

                                    <div className="m_d-flex m_flex-1 flex-column ml-1">
                                        <div className="m_log_row">
                                            <div className="m_flex-1">
                                                <Tooltips
                                                    title={solve.challenge_name}
                                                    style={{
                                                        width: "40vw",
                                                        overflow: "hidden",
                                                        flex: 0.9,
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    {solve.challenge_name}
                                                </Tooltips>
                                            </div>
                                            <div className="m_flex-1">
                                                <div className="m_d-flex m_justify-content-end m_align-items-center">
                                                    {solve.points}
                                                    <OfflineBoltOutlinedIcon
                                                        style={{
                                                            color: "var(--yellow)",
                                                            marginLeft:
                                                                "0.5rem",
                                                            marginTop: "0.2rem",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m_log_row">
                                            <div className="m_flex-1">
                                                <Tooltips
                                                    title={
                                                        solve.category
                                                            .category_name
                                                    }
                                                    style={{
                                                        overflow: "hidden",
                                                        flex: 0.9,
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                >
                                                    <div className="m_d-flex m_align-items-center">
                                                        <div className="m_icon">
                                                            {getIcon(
                                                                solve.category
                                                                    .category_icon
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "0.5rem",
                                                            }}
                                                        >
                                                            {
                                                                solve.category
                                                                    .category_name
                                                            }
                                                        </div>
                                                    </div>
                                                </Tooltips>
                                            </div>
                                            <div className="m_flex-1">
                                                <div
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {date_time(
                                                        solve.solve_time
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChallengeInfo;
