import React from "react";
import error from "../../assets/error.svg";
function ErrorFlag({ handleToggle, toggle, show = true }) {
    return (
        <div className="m_toggle">
            <div className="m_toggle-container">
                <div
                    className="m_toggle_card animate__fadeInUp"
                    style={{ background: "var(--dark)" }}
                >
                    <div className="m_flex-1 m_d-flex m_align-items-center m_flex-column">
                        <div style={{ width: "11rem" }}>
                            <img src={error} alt="" />
                        </div>
                        <h2>Oops!</h2>
                        <h3>{toggle}</h3>
                        {show && (
                            <button
                                onClick={handleToggle}
                                className="m_btn m_btn--medium"
                                style={{
                                    margin: "2rem 0",
                                }}
                            >
                                Close
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorFlag;
