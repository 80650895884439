import React from "react";
function TableHeading({ heading }) {
    return (
        <div className="table__heading">
            <h5 className="spaced">{heading}</h5>
        </div>
    );
}

export default TableHeading;
