import { useState, useEffect } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useSelector, useDispatch } from "react-redux";
import {
    GET_IMAGE_FILES,
    api,
    ALERT,
    LOADING,
    SEARCH,
    Toggle,
    AutoUpload,
} from "../";
import { useHistory } from "react-router";
import { Switch, Route } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
function MediaLibrary({ onInsert, type, route }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const files = useSelector((state) => state.imageFiles);
    useEffect(() => {
        async function fetch() {
            dispatch({
                type: LOADING,
            });
            try {
                var res;
                if (type === "add" || type === "update") {
                    res = await api.getMedia("challenge");
                } else {
                    res = await api.getMedia(type);
                }
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            } catch (error) {
            } finally {
                dispatch({
                    type: LOADING,
                });
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                newCategory: "Files",
            },
        });
        return () => {
            var placeholder = type;
            if (type === "add" || type === "update") {
                placeholder = type + " challenge";
            }
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder },
            });
        };
    }, [dispatch, type]);
    const [file, setFile] = useState("");
    function handleInsert() {
        onInsert(file);
        dispatch({
            type: ALERT,
            data: {
                open: true,
                message: "Inserted",
                type: "success",
            },
        });
        history.go(-2);
    }
    async function deleteImage() {
        var _type = type;
        if (type === "add" || type === "update") {
            _type = "challenge";
        }
        dispatch({
            type: LOADING,
        });
        try {
            let res = await api.deleteMedia(_type, file.split("/").pop());
            if (res && res.status === 200) {
                setFile("");
                res = await api.getMedia(_type);
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            }
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File deleted",
                    type: "success",
                },
            });
            history.goBack();
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be deleted",
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
        }
    }
    async function upload(file) {
        if (!file) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please choose file",
                    type: "warning",
                },
            });
            return;
        }
        var _type = type;
        if (type === "add" || type === "update") {
            _type = "challenge";
        }
        dispatch({
            type: LOADING,
        });
        try {
            var data;
            let res = await api.uploadMedia(file, _type);
            data = res.data;
            if (res && res.status === 200) {
                res = await api.getMedia(_type);
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            }

            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File uploaded",
                    type: "success",
                },
            });
            setFile(data.file_url);
            history.replace(`/dashboard/${route}/${type}/medialibrary/detail`);
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be uploaded",
                    type: "error",
                },
            });
            history.goBack();
        } finally {
            dispatch({
                type: LOADING,
            });
        }
    }
    function setRow(file) {
        setFile(file.url);
    }

    return (
        <Switch>
            <Route path={`/dashboard/${route}/${type}/medialibrary/detail/add`}>
                <AutoUpload
                    upload={upload}
                    url={`/dashboard/${route}/${type}/medialibrary`}
                />
            </Route>
            <Route path={`/dashboard/${route}/${type}/medialibrary/add`}>
                <AutoUpload
                    upload={upload}
                    url={`/dashboard/${route}/${type}/medialibrary`}
                />
            </Route>
            <Route path={`/dashboard/${route}/${type}/medialibrary/detail`}>
                <Toggle>
                    <div className="m_image_viewer mt-1">
                        {file ? (
                            <img src={file} alt="" />
                        ) : (
                            <DescriptionIcon
                                style={{
                                    fontSize: 100,
                                    color: "var(--text_color)",
                                }}
                            />
                        )}
                    </div>
                    <div className="m_text-center mt-1">
                        <h4>{file.split("-").pop()}</h4>
                    </div>
                    <div className="mt-2">
                        <button
                            onClick={file ? handleInsert : null}
                            className={`m_btn mb-1 ${
                                !file ? "m_tab-btn" : "m_btn m_btn--medium"
                            }`}
                        >
                            Insert
                        </button>

                        {file ? (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                id={file}
                                href={file}
                            >
                                <div
                                    className={`m_btn ${
                                        !file
                                            ? "m_tab-btn"
                                            : "m_btn m_btn--medium"
                                    }`}
                                >
                                    Download
                                </div>
                            </a>
                        ) : (
                            <div
                                className={`m_btn  ${
                                    !file ? "m_tab-btn" : "m_btn m_btn--medium"
                                }`}
                            >
                                Download
                            </div>
                        )}

                        <button
                            onClick={file ? deleteImage : null}
                            className={`m_btn mt-1 mb-1 ${
                                !file
                                    ? "m_tab-btn"
                                    : "m_btn m_btn--secondary mt-1"
                            }`}
                        >
                            Delete
                        </button>
                    </div>
                </Toggle>
            </Route>
            <Route exact path={`/dashboard/${route}/${type}/medialibrary`}>
                <div className="m_table_data">
                    {files &&
                        files.map((file, index) => (
                            <div key={index} className="m_table_data_item">
                                <div className="m_flex-1">
                                    {file.filename.split("-").pop()}
                                </div>

                                <div
                                    style={{ flex: 0.1 }}
                                    onClick={() => {
                                        setRow(file);
                                        history.push(
                                            `/dashboard/${route}/${type}/medialibrary/detail`
                                        );
                                    }}
                                    className="m_icon m_icon_light m_d-flex m_justify-content-end"
                                >
                                    <ArrowForwardIosOutlinedIcon />
                                </div>
                            </div>
                        ))}
                </div>
            </Route>
        </Switch>
    );
}

export default MediaLibrary;
