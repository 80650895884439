import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Challenge from "./Challenge";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import {
  headers,
  Toggle,
  api,
  PaginationComponent,
  filterChallenges,
  GET_CHALLENGES,
  SEARCH,
  GET_DEPLOYMENTS,
  LOADING,
  ALERT,
  SmLoading,
} from "../../";
import orderBy from "lodash/orderBy";
import { useHistory } from "react-router";

const TableBody = ({ paginateItems, setChallenge }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cid, setCID] = useState(null);
  async function handleChallenge(id) {
    dispatch({
      type: LOADING,
    });
    try {
      const res = await api.getAdminChallenge(id);
      setChallenge(res.data);
      history.push(`/dashboard/challenges/update`);
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Deployment can't be deleted`,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  return (
    <div className="m_table_data">
      {paginateItems &&
        paginateItems.map((c, index) => (
          <div
            key={index}
            onClick={() => setCID(c.id)}
            className={`m_table_data_item ${cid === c.id ? "white-space" : ""}`}
          >
            <div className="m_flex-1">{c.name}</div>
            <div className="m_flex-1">{c.category}</div>
            <div
              style={{
                color: c.status.color,
                opacity: 0.8,
                flex: 0.2,
              }}
            >
              <c.status.icon />
            </div>
            <div
              onClick={() => handleChallenge(c.id)}
              style={{ flex: 0.1 }}
              className="m_icon m_icon_light m_d-flex m_justify-content-end"
            >
              <ArrowForwardIosOutlinedIcon />
            </div>
          </div>
        ))}
    </div>
  );
};

function AdminChallenges() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [challenge, setChallenge] = useState({});
  const [challenges, deployments, search] = useSelector((state) => [
    state.challenges,
    state.deployments,
    state.search,
  ]);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => setToggle(!toggle);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        let res = await api.getAdminChallenges();
        let data = res.data.challenges;
        dispatch({
          type: GET_CHALLENGES,
          data,
        });

        res = await api.getChallengesDeployments();
        data = res.data.challenge_deployments;
        dispatch({
          type: GET_DEPLOYMENTS,
          data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "challenges",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);

  var filteredChallenges = [];

  if (challenges) {
    filteredChallenges = filterChallenges(search, challenges, deployments);
    filteredChallenges = orderBy(
      filteredChallenges,
      [(s) => s.name.toLowerCase()],
      ["asc"]
    );
  }
  return (
    <div className="m_challenges">
      {toggle && (
        <Toggle classes="m_challengePanel" handleToggle={handleToggle}>
          <Challenge handleToggle={handleToggle} />
        </Toggle>
      )}

      {loading ? (
        <SmLoading />
      ) : (
        <Switch>
          <Route path="/dashboard/challenges/update">
            <Challenge type="update" challenge={challenge} />
          </Route>
          <Route path="/dashboard/challenges/add">
            <Challenge />
          </Route>
          <Route exact path="/dashboard/challenges">
            <PaginationComponent
              heading="CHALLENGES"
              headers={headers.challenges}
              items={filteredChallenges}
              TableBody={(items) => (
                <TableBody paginateItems={items} setChallenge={setChallenge} />
              )}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default AdminChallenges;
