import React, { useState } from "react";
import { TableHeading, Toggle } from "../../";
import ChangePassword from "./modals/ChangePassword";
import ChangeUsername from "./modals/ChangeUsername";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ALERT } from "./../../store/actionsTypes";
import api from "./../../services/api";

function ChangeProfile({ profile }) {
    const [toggle, setToggle] = useState("");
    const [confirm, setConfirm] = useState("");
    const handleToggle = () => setToggle("");
    const config = useSelector((state) => state.config);
    const dispatch = useDispatch();
    async function handleDeleteAccount() {
        try {
            await api.deleteAccount();
            window.location = "/auth/login";
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Account deleted successfully.",
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response && error.response.data.status,
                    type: "error",
                },
            });
        }
        setConfirm("");
    }
    async function handleLeaveAccount() {
        try {
            await api.leaveTeam();
            window.location = "/team";
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Team leave successfully",
                    type: "success",
                },
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Team leave successfully.",
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response && error.response.data.status,
                    type: "error",
                },
            });
        }
        setConfirm("");
    }
    return (
        <>
            {typeof confirm === "function" && (
                <Toggle handleToggle={() => setConfirm(false)}>
                    <div className="text-center">
                        <h2>Are you sure?</h2>
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                        <div
                            className="btn btn--medium"
                            onClick={() => setConfirm("")}
                        >
                            Cancel
                        </div>
                        <div className="btn btn--medium" onClick={confirm}>
                            Ok
                        </div>
                    </div>
                </Toggle>
            )}

            {toggle === "username" && (
                <Toggle handleToggle={handleToggle}>
                    <ChangeUsername handleToggle={handleToggle} />
                </Toggle>
            )}
            {toggle === "password" && (
                <Toggle handleToggle={handleToggle}>
                    <ChangePassword handleToggle={handleToggle} />
                </Toggle>
            )}
            <div className="card__container mb-2">
                <TableHeading heading="user setting" />
                <div className="settings__user">
                    <div className="settings_username">
                        <h4>Username</h4>
                        <h4 className="ml-2">{profile.username}</h4>
                    </div>
                    <button
                        onClick={() => setToggle("username")}
                        className="btn btn--medium"
                    >
                        Change
                    </button>
                </div>
                <div className="settings__user">
                    <div className="settings_username">
                        <h4>Password</h4>
                        <h4 className="ml-2">*********</h4>
                    </div>
                    <button
                        onClick={() => setToggle("password")}
                        className="btn btn--medium"
                    >
                        Change
                    </button>
                </div>
                {config.team_mode && (
                    <div className="settings__user">
                        <div className="settings_username">
                            <h5>
                                <strong>Leave your current team</strong> ( You
                                will lose all your points )
                            </h5>
                        </div>
                        <button
                            onClick={() => setConfirm(() => handleLeaveAccount)}
                            className="btn btn--medium danger"
                        >
                            Leave Team
                        </button>
                    </div>
                )}
                <div className="settings__user">
                    <div className="settings_username">
                        <h5>
                            <strong>Permanently delete your account </strong>(
                            You will lose all data )
                        </h5>
                    </div>
                    <button
                        onClick={() => setConfirm(() => handleDeleteAccount)}
                        className="btn btn--medium danger"
                    >
                        Delete Account
                    </button>
                </div>
            </div>
        </>
    );
}

export default ChangeProfile;
