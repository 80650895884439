import { useState } from "react";
import { Nav } from "../";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
function Select({
    className,
    onChange,
    children,
    label = null,
    label__side = null,
    value,
    options,
    placeholder,
}) {
    const [hide, setHide] = useState(false);
    const handleClick = (item) => {
        onChange(item.category_name);
        setHide(false);
    };

    return (
        <div className={`m_input-group-container ${className}`}>
            {(label || label === "") && (
                <label className="m_d-flex">
                    <h6 className="m_spaced">{label.split("*")[0]}</h6>
                    {label.slice(-1) === "*" && (
                        <h6 style={{ color: "var(--red)" }}>
                            {label.slice(-1)}
                        </h6>
                    )}
                </label>
            )}
            <div className="m_input-group-icon-container">
                {(label__side || label__side === "") && (
                    <label className="m_event_label_side">
                        <h3>{label__side}</h3>
                    </label>
                )}
                <div
                    onClick={() => setHide(!hide)}
                    className="m_input-group-icon"
                >
                    <div className="m_input-group">
                        <div className="m_input select-container">
                            {value ? (
                                <h6
                                    style={{ fontSize: "1.2rem" }}
                                    className="m_white_space"
                                >
                                    {value}
                                </h6>
                            ) : (
                                <div className="m_select-placeholder">
                                    <h5>{placeholder}</h5>
                                </div>
                            )}
                            <div className="m_icon">
                                <KeyboardArrowDownIcon />
                            </div>
                            {hide && (
                                <div className="m_options-container">
                                    <div className="m_options">
                                        {options.map((d, index) => (
                                            <Nav
                                                key={index}
                                                nav={d}
                                                cat={true}
                                                onClick={
                                                    d.handler
                                                        ? d.handler
                                                        : () => handleClick(d)
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default Select;
