import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import headers from "../../utils/headers";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import TeamEdit from "./TeamEdit";
import { Switch, useHistory, Route } from "react-router-dom";
import {
    Toggle,
    PaginationComponent,
    GET_TEAMS,
    SEARCH,
    api,
    LOADING,
    SmLoading,
} from "../../";
import orderBy from "lodash/orderBy";
const TableBody = ({ paginateItems, setTeam }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [id, setID] = useState("");
    async function handleEdit(teamname) {
        dispatch({
            type: LOADING,
        });
        try {
            const res = await api.getAdminTeam(teamname);
            setTeam(res.data);
            history.push(`/dashboard/teams/${teamname}`);
        } catch (error) {
        } finally {
            dispatch({
                type: LOADING,
            });
        }
    }
    const filtered = orderBy(
        paginateItems,
        [(s) => s.team_name.toLowerCase()],
        ["asc"]
    );
    return (
        <div className="m_table_data">
            {filtered &&
                filtered.map((team, index) => (
                    <div
                        key={index}
                        onClick={() => setID(team.id)}
                        className={`m_table_data_item ${
                            id === team.id ? "white-space" : ""
                        }`}
                    >
                        <div className="m_flex-1">
                            <span
                                onClick={() =>
                                    history.push(
                                        `/dashboard/teamstats/${team.team_name}`
                                    )
                                }
                                className="m_pointer"
                            >
                                {team.team_name}{" "}
                            </span>
                        </div>
                        <div
                            style={{ flex: 0.1 }}
                            onClick={() => {
                                handleEdit(team.team_name);
                            }}
                            className="m_icon m_icon_light m_d-flex m_justify-content-end"
                        >
                            <ArrowForwardIosOutlinedIcon />
                        </div>
                    </div>
                ))}
        </div>
    );
};
function Teams() {
    const dispatch = useDispatch();
    const teams = useSelector((state) => state.teams);
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState({});
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const res = await api.getTeams();
                dispatch({
                    type: GET_TEAMS,
                    data: res.data.teams,
                });
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "teams",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch]);

    return (
        <div className="m_teams_container">
            {loading ? (
                <SmLoading />
            ) : (
                <Switch>
                    <Route path="/dashboard/teams/:team_edit">
                        <Toggle>
                            <TeamEdit team={team} />
                        </Toggle>
                    </Route>
                    <Route path="/dashboard/teams">
                        <PaginationComponent
                            heading="TEAMS"
                            headers={headers.teams}
                            items={teams}
                            TableBody={(items) => (
                                <TableBody
                                    setTeam={setTeam}
                                    paginateItems={items}
                                />
                            )}
                        />
                    </Route>
                </Switch>
            )}
        </div>
    );
}

export default Teams;
