import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, api, GET_SOLVES, getIcon, ALERT, date_time } from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import LinkIcon from "@material-ui/icons/Link";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import MDEditor from "@uiw/react-md-editor";
import orderBy from "lodash/orderBy";
import { useHistory, useParams } from "react-router-dom";
import Congratulation from "./../Challenges/Congratulation";
import { Switch, Route } from "react-router-dom";
import ErrorFlag from "./ErrorFlag";
import { useLocation } from "react-router";
function ChallengeDetail({ challenge, solve, site_key, handleChallenge }) {
    const { name, id } = useParams();
    const { pathname } = useLocation();
    const [flag, setFlag] = useState("");
    const [tabs, setTabs] = useState("challenge");
    const [btn, setBtn] = useState("Submit");
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState("");
    const history = useHistory();
    var solved = null;
    const [app, config] = useSelector((state) => [state.app, state.config]);
    if (solve && app && app.profile)
        solved = solve.find((s) => s.user === app.profile.username);
    const sorted_solve = orderBy(solve, ["solve_time"], ["asc"]);

    useEffect(() => {
        if (!challenge) {
            handleChallenge(id, name);
        }
    }, [challenge, handleChallenge, name, id]);
    const submitFlag = async () => {
        try {
            setLoading(true);
            var data = {
                cid: challenge.id,
                flag,
            };
            if (site_key) {
                const g_recaptcha_response = await executeRecaptcha(
                    "submit_flag"
                );
                data = { ...data, g_recaptcha_response };
            }
            let res = await api.submitFlag(data);
            if (res.data.status !== "ok") {
                alert(res.data.status);
                return;
            }
            res = await api.solves();
            dispatch({ type: GET_SOLVES, data: res.data.solves });
            history.push(`${pathname}/congratulations`);
            setBtn("Completed");
        } catch (err) {
            setToggle(err.response && err.response.data.status);
            history.push(`${pathname}/errorflag`);
        } finally {
            setLoading(false);
        }
    };

    var solves_btn = "Solves";
    if (solve && solve.length === 1) {
        solves_btn = `${solve.length} Solve`;
    } else if (solve && solve.length > 1) {
        solves_btn = `${solve.length} Solves`;
    }
    const style = {
        backgroundColor: "var(--light)",
        padding: "0.5em 2em",
        borderRadius: "0",
    };
    const handleToggle = () => history.go(-2);
    if (!challenge) return null;
    return (
        <Switch>
            <Route path="/dashboard/challenge/:name/:id">
                <Route path="/dashboard/challenge/:name/:id/congratulations">
                    <Congratulation handleToggle={handleToggle} />
                </Route>
                <Route path="/dashboard/challenge/:name/:id/errorflag">
                    <ErrorFlag handleToggle={handleToggle} toggle={toggle} />
                </Route>
                <div className="m_d-flex m_flex-1 m_flex-column">
                    <div className="m_d-flex">
                        <div
                            onClick={() => setTabs("challenge")}
                            style={tabs === "challenge" ? style : null}
                            className="m_log_tab m_btn"
                        >
                            Challenge
                        </div>
                        <div
                            onClick={() => setTabs("solves")}
                            style={tabs === "solves" ? style : null}
                            className="m_log_tab m_btn"
                        >
                            {solves_btn}
                        </div>
                    </div>
                    <>
                        {tabs === "challenge" && (
                            <div className="m_p-2 m_flex-1">
                                <div className="m_mb-2">
                                    <div className="m_text-center mb-1">
                                        <h1>{challenge.name}</h1>
                                    </div>
                                    <h2>
                                        <MDEditor.Markdown
                                            source={challenge.description}
                                        />
                                    </h2>
                                </div>
                                <div className="m_d-flex m_align-items-center m_justify-content-between">
                                    <div className="m_d-flex  align-items-center">
                                        <div className="m_icon">
                                            {getIcon(
                                                challenge.category.category_icon
                                            )}
                                        </div>
                                        <h4 style={{ marginLeft: "0.5rem" }}>
                                            {challenge.category.category_name}
                                        </h4>
                                    </div>

                                    <div className="m_d-flex m_enpoint_challenges">
                                        {challenge.endpoint && (
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        challenge.endpoint
                                                    );
                                                    dispatch({
                                                        type: ALERT,
                                                        data: {
                                                            open: true,
                                                            message:
                                                                "Endpoint copied.",
                                                            type: "success",
                                                        },
                                                    });
                                                }}
                                                className="m_icon icon__primary"
                                            >
                                                <LinkIcon
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        marginLeft: "0.5rem",
                                                        transform:
                                                            "rotate(90deg)",
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {challenge.file && (
                                            <div
                                                onClick={() =>
                                                    window.open(challenge.file)
                                                }
                                                className="m_icon m_icon__sm"
                                            >
                                                <InsertDriveFileOutlinedIcon />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="m_challenge_category_type">
                                    <button
                                        style={{
                                            backgroundColor:
                                                challenge.difficulty.toLowerCase() ===
                                                "easy"
                                                    ? "var(--blue)"
                                                    : challenge.difficulty.toLowerCase() ===
                                                      "medium"
                                                    ? "var(--yellow)"
                                                    : "var(--red)",
                                        }}
                                        className="m_btn m_mr-1"
                                    >
                                        {challenge.difficulty}
                                    </button>
                                    <div
                                        style={{ width: "100%" }}
                                        className="m_flex-1 m_icon m-d-flex m_justify-content-end"
                                    >
                                        <h4> {challenge.points} </h4>
                                        <OfflineBoltOutlinedIcon
                                            style={{
                                                color: "var(--yellow)",
                                                marginLeft: "0.5rem",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="m_justify-content-evenly">
                                    <Input
                                        placeholder="Flag Input"
                                        value={flag}
                                        onChange={setFlag}
                                    />
                                    <div
                                        className={`${
                                            solved ? "m_disable" : ""
                                        }`}
                                    >
                                        <button
                                            style={{
                                                background:
                                                    btn === "Completed"
                                                        ? "green"
                                                        : "",
                                            }}
                                            onClick={
                                                !solved ? submitFlag : null
                                            }
                                            className={`m_btn  ${
                                                loading || solved
                                                    ? "m_tab-btn"
                                                    : "m_btn m_btn--medium"
                                            }`}
                                        >
                                            {solved
                                                ? "Completed"
                                                : `${
                                                      loading
                                                          ? "Submitting"
                                                          : "Submit"
                                                  } `}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {tabs === "solves" && (
                            <div
                                className="m_flex-1"
                                style={{ overflow: "auto" }}
                            >
                                {sorted_solve &&
                                    sorted_solve.map((solve, index) => (
                                        <div
                                            key={index}
                                            className="m_table_data_item"
                                            style={{ height: "5rem" }}
                                        >
                                            <div className="m_flex-1">
                                                <span
                                                    onClick={() =>
                                                        history.push(
                                                            `/dashboard/profile/${solve.user}`
                                                        )
                                                    }
                                                    className="m_pointer"
                                                >
                                                    <h5> {solve.user}</h5>
                                                </span>
                                            </div>
                                            <div className="m_flex-1 m_d-flex m_flex-column m_align-items-end">
                                                {config.team_mode && (
                                                    <div className="m_flex-1">
                                                        <span
                                                            onClick={() =>
                                                                history.push(
                                                                    `/dashboard/teamstats/${solve.teamname}`
                                                                )
                                                            }
                                                            className="m_pointer"
                                                        >
                                                            <h5
                                                                style={{
                                                                    fontSize:
                                                                        "1.2rem",
                                                                }}
                                                            >
                                                                {solve.teamname}
                                                            </h5>
                                                        </span>
                                                    </div>
                                                )}
                                                <div
                                                    className="m_flex-1"
                                                    style={{ opacity: 0.5 }}
                                                >
                                                    <h6 className="mt-1">
                                                        {date_time(
                                                            solve.solve_time
                                                        )}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </>
                </div>
            </Route>
        </Switch>
    );
}

export default ChallengeDetail;
