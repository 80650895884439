import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChallengeInfo from "./ChallengeInfo";
import UserGraph from "./UserGraph";
import Profile from "./Profile";
import ChangeProfile from "./ChangeProfile";
import { api, ALERT, SmLoading } from "../../";
import { useParams, useHistory } from "react-router";
import MyPlan from "./MyPlan";
function Settings() {
  const { username } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userStat, setUserState] = useState("");
  const app = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetch() {
      setLoading(true);
      var res;
      try {
        try {
          await api.getAdminStatus();
          res = await api.getAdminUserState(username);
        } catch (err) {
          res = await api.getUserState(username);
        }
        setUserState(res.data);
      } catch (err) {
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: "User not found",
            type: "error",
          },
        });
        history.goBack();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [dispatch, history, username]);

  return (
    <div className="settings">
      {loading || !userStat ? (
        <SmLoading />
      ) : (
        <>
          <Profile
            profile={userStat}
            points={userStat.points}
            challenges_solved={userStat.solved_challenges}
            total_challenges={userStat.total_challenges}
            showEdit={app && app.profile && app.profile.username === username}
          />
          <MyPlan userStat={userStat} />
          <UserGraph
            data={userStat.graph_points}
            heading="points"
            label={username}
          />
          <ChallengeInfo
            categorical_challenges={userStat.categorical_challenges}
            categorical_solves={userStat.categorical_solves}
            solves={userStat.solves}
            points={userStat.points}
            challenges_solved={userStat.solved_challenges}
            total_challenges={userStat.total_challenges}
          />
          {app && app.profile && app.profile.username === username && (
            <ChangeProfile profile={userStat} />
          )}
        </>
      )}
    </div>
  );
}

export default Settings;
