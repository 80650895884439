import React from "react";

import MApp from "./mobile_app/MApp";
import DApp from "./app/DApp";
function App() {
    function mobileAndTabletCheck() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    return mobileAndTabletCheck() ? <MApp /> : <DApp />;
}

export default App;
