import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
function GameClock({ hide }) {
    const [timer, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const config = useSelector((state) => state.config);
    const calculateTimeLeft = (target_time) => {
        let difference = +new Date(parseInt(target_time) * 1000) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(config.end_time);
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [config.end_time, timer]);
    let { days, hours, minutes, seconds } = timer;
    if (days <= 9) {
        days = "0" + days.toString();
    }
    if (hours <= 9) {
        hours = "0" + hours.toString();
    }
    if (minutes <= 9) {
        minutes = "0" + minutes.toString();
    }
    if (seconds <= 9) {
        seconds = "0" + seconds.toString();
    }

    return (
        <div className={`game_clock ${hide ? "hide" : "show"}`}>
            <h5 className="spaced">GAME CLOCK</h5>
            <div className="d-flex justify-content-between time_clock">
                <div className="game_time">
                    <h2>{days || "00"}</h2>
                </div>
                <h2>:</h2>
                <div className="game_time">
                    <h2>{hours || "00"}</h2>
                </div>
                <h2>:</h2>
                <div className="game_time">
                    <h2>{minutes || "00"}</h2>
                </div>
                <h2>:</h2>
                <div className="game_time">
                    <h2>{seconds || "00"}</h2>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="game_time">
                    <h5 className="spaced">DAYS</h5>
                </div>

                <div className="game_time">
                    <h5 className="spaced">HRS</h5>
                </div>

                <div className="game_time">
                    <h5 className="spaced">MINS</h5>
                </div>
                <div className="game_time">
                    <h5 className="spaced">SEC</h5>
                </div>
            </div>
        </div>
    );
}

export default GameClock;
