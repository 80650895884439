import SideBarItem from "./SideBarItem";
import { useSelector } from "react-redux";

function SideBar() {
  let [app, hide, config, notifications] = useSelector((state) => [
    state.app,
    state.hide,
    state.config,
    state.notifications,
  ]);
  var sidebar_items = [];
  sidebar_items.push({
    category_name: "Categories",
    category_icon: "LocalOfferOutlined",
    endpoint: "/dashboard/categories",
  });
  if (app.isAdmin) {
    sidebar_items.push({
      category_name: "Dashboard",
      category_icon: "SpeedOutlined",
      endpoint: `/dashboard`,
    });
  } else {
    sidebar_items.push({
      category_name: "Announcement",
      category_icon: "NotificationsNoneOutlined",
      endpoint: `/dashboard/announcements`,
      notification: notifications.length,
    });
    if (config.scoreboard_enabled) {
      sidebar_items.push({
        category_name: "Scoreboard",
        category_icon: "AssessmentOutlined",
        endpoint: `/dashboard/scoreboard`,
      });
    }
  }

  sidebar_items.push({
    category_name: "Profile",
    category_icon: "AccountCircleOutlined",
    endpoint: `/dashboard/profile/${app.profile && app.profile.username}`,
  });

  return (
    <div className="m_sidebar-container">
      <SideBarItem items={sidebar_items} hide={hide} />
    </div>
  );
}

export default SideBar;
