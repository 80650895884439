import { useEffect } from "react";
import { getIcon } from "./../utils/getIcon";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH, searchData } from "../";
function Categories({ items }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "categories",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch]);
    const search = useSelector((state) => state.search);
    var searchItem = items;
    if (search && search.value) {
        searchItem = searchData(search.value, items);
    }
    return (
        <div className="main_items">
            {searchItem.map((item, index) => (
                <Link key={index} to={item.endpoint}>
                    <div className="m_category_item">
                        <div className="m_icon m_icon__primary">
                            {getIcon(item.category_icon)}
                        </div>
                        <h3>{item.category_name}</h3>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default Categories;
