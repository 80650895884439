import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import {
    api,
    ChallengeInfo,
    TeamUsers,
    TeamProfile,
    UserGraph,
    ALERT,
    SmLoading,
    SEARCH,
    Input,
} from "../../";

import CloseIcon from "@material-ui/icons/Close";

function TeamStatesPage() {
    const { teamname } = useParams();
    const [teamstat, setTeamStat] = useState({});
    const [team, setTeam] = useState("");
    const [rank, setRank] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [newTeamName, setNewTeamName] = useState("");
    const config = useSelector((state) => state.config);
    useEffect(() => {
        async function fetch() {
            try {
                setLoading(true);
                var res;
                try {
                    await api.getAdminStatus();
                    res = await api.getAdminTeamStat(teamname);
                } catch (err) {
                    res = await api.getTeamStat(teamname);
                }
                setTeamStat(res.data);
                try {
                    res = await api.getTeam();
                    setTeam(res.data);
                } catch (error) {}
                if (config.scoreboard_enabled) {
                    res = await api.getScoreboard();
                    const ranks = res.data.standings;
                    if (ranks) {
                        const obj = ranks.find(
                            (rank) => rank.team === teamname
                        );
                        if (obj) {
                            setRank(obj.pos);
                        }
                    }
                }
            } catch (err) {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Team not found",
                        type: "error",
                    },
                });
                history.goBack();
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "team stats",
                icon: true,
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch, history, teamname, config]);
    async function handleSubmit() {
        setLoading(true);
        try {
            await api.updateTeamName({ team_name: newTeamName });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Team name Changed",
                    type: "success",
                },
            });
            setNewTeamName("");
            history.push(`/dashboard/teamstats/${newTeamName}`);
            setToggle(false);
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response && error.response.data.status,
                    type: "error",
                },
            });
        }
        setLoading(false);
    }

    return (
        <div className="m_settings">
            {loading || !teamstat ? (
                <SmLoading />
            ) : (
                <>
                    {toggle && (
                        <div className="m_toggle">
                            <div className="m_toggle-container">
                                <div
                                    className="m_toggle_card animate__fadeInUp"
                                    style={{
                                        background: "var(--dark)",
                                    }}
                                >
                                    <div className="close">
                                        <div
                                            onClick={() => setToggle(false)}
                                            className="icon"
                                        >
                                            <CloseIcon />
                                        </div>
                                    </div>
                                    <Input
                                        label="new team name"
                                        placeholder="New Team Name"
                                        value={newTeamName}
                                        onChange={setNewTeamName}
                                    />
                                    <div className="text-center">
                                        <button
                                            onClick={handleSubmit}
                                            className="m_btn m_btn--medium"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <TeamProfile
                        handleToggle={() => setToggle(true)}
                        rank={rank}
                        points={teamstat.points}
                        challenges_solved={teamstat.solved_challenges}
                        team_name={teamstat.team_name}
                        team_members={
                            teamstat.members ? teamstat.members.length : 0
                        }
                        team={team}
                        show_rank={config.scoreboard_enabled}
                        total_challenges={teamstat.total_challenges}
                    />
                    <UserGraph
                        data={teamstat.graph_points}
                        heading="GRAPH"
                        label={teamname}
                    />
                    <ChallengeInfo
                        categorical_challenges={teamstat.categorical_challenges}
                        categorical_solves={teamstat.categorical_solves}
                        solves={teamstat.solves}
                    />
                    <TeamUsers members={teamstat.members} />
                </>
            )}
        </div>
    );
}

export default TeamStatesPage;
