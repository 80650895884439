import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MENU } from "./../store/actionsTypes";
import MenuIcon from "@material-ui/icons/Menu";
import default_event from "../assets/default-company.png";
import { useHistory } from "react-router";
function NavLogo({ app }) {
    const dispatch = useDispatch();
    const config = useSelector((state) => state.config);
    const history = useHistory();
    return (
        <div className="navbar_logo">
            <div
                onClick={() =>
                    dispatch({
                        type: MENU,
                    })
                }
                className="icon icon_4"
            >
                {app.profile && <MenuIcon />}
            </div>
            <div onClick={() => history.push("/")} className="nav_logo">
                <img
                    style={{ height: "100%" }}
                    src={config.company_logo || default_event}
                    alt=""
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = default_event;
                    }}
                />
            </div>
        </div>
    );
}

export default NavLogo;
