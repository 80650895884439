import React from "react";
import { useSelector } from "react-redux";
import default_event from "../assets/default-company.png";
import { useHistory } from "react-router";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
function NavLogo({ app, show = false }) {
    const config = useSelector((state) => state.config);
    const history = useHistory();
    return (
        <div className="m_navbar_logo">
            <div onClick={() => history.goBack()} className="m_icon">
                {app.profile && <ArrowBackOutlinedIcon />}
            </div>
            {show && (
                <div onClick={() => history.push("/")} className="m_nav_logo">
                    <img
                        style={{ height: "100%" }}
                        src={config.company_logo || default_event}
                        alt=""
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = default_event;
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default NavLogo;
