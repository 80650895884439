import React from "react";

import { useHistory } from "react-router";
import not_found from "../assets/error.png";
function ErrorPage({ height }) {
    const history = useHistory();
    return (
        <div
            className="m_not-found_container"
            style={{ height: height + 64 + 54 }}
        >
            <div className="m_not_found_img">
                <img src={not_found} alt="" />
            </div>
            <div className="m_text">
                <h3> We’re sorry this happened to you!</h3>
                <h4>
                    We apologize for the inconvenience caused. We’re working on
                    this issue and will fix it soon.
                </h4>
                <div
                    onClick={() => history.push("/")}
                    className="m_btn m_not_found_btn mt-2"
                >
                    Go Home
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
