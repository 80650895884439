import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Zoom from "@material-ui/core/Zoom";

export default function Tooltips({ children, title, style, className }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        var ele = document.getElementsByClassName("m_table_data");
        if (ele) {
            if (ele[0]) ele[0].addEventListener("scroll", handleTooltipClose);
        }
        return () => {
            if (ele) {
                if (ele[0])
                    ele[0].removeEventListener("scroll", handleTooltipClose);
            }
        };
    }, []);
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                TransitionComponent={Zoom}
                arrow
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={title}
                className={className}
                style={style}
            >
                <div onClick={handleTooltipOpen}>{children}</div>
            </Tooltip>
        </ClickAwayListener>
    );
}
