import React from "react";
import Input from "./Input";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { useSelector, useDispatch } from "react-redux";
import { SEARCH } from "../store/actionsTypes";
function Search() {
    const dispatch = useDispatch();
    const search = useSelector((state) => state.search);
    const onChange = (value) => {
        dispatch({
            type: SEARCH,
            data: { ...search, value },
        });
    };
    if (search.placeholder) {
        return (
            <div className="search mr-1">
                <div className="icon-search">
                    <SearchOutlinedIcon />
                </div>
                <div className="flex-1">
                    <Input
                        placeholder={search.placeholder}
                        value={search.value}
                        onChange={onChange}
                    />
                </div>
            </div>
        );
    } else return null;
}

export default Search;
