import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import {
    headers,
    api,
    searchData,
    TableHeader,
    GET_CHALLENGE_LOGS,
    date_time,
} from "../../";
import { useHistory } from "react-router";

function ChallengeLogs({ search }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const challengeLogs = useSelector((state) => state.challengeLogs);
    var filteredChallenges = searchData(search, challengeLogs);
    const config = useSelector((state) => state.config);
    const [id, setID] = useState("");
    useEffect(() => {
        api.getChallengeLogs().then((res) => {
            let logs = res.data.challenge_logs;
            logs = orderBy(logs, ["time"], ["desc"]);
            dispatch({
                type: GET_CHALLENGE_LOGS,
                data: logs,
            });
        });
    }, [dispatch]);

    var header = headers.challengeLogs;
    if (!config.team_mode) {
        header = header.filter((h) => h !== "Team");
        filteredChallenges = filteredChallenges.map((c) => {
            return {
                challenge: c.challenge,
                ip_address: c.ip_address,
                log_message: c.log_message,
                time: c.time,
                user: c.user,
            };
        });
    }

    return (
        <>
            <TableHeader headers={header} />
            <div className="table_data">
                {filteredChallenges &&
                    filteredChallenges.map((cl, index) => (
                        <div
                            key={index}
                            onClick={() => setID(cl.id)}
                            className={`table_data_item ${
                                id === cl.id ? "white-space" : ""
                            }`}
                        >
                            <div className="flex-1">{date_time(cl.time)}</div>
                            <div className="flex-1">
                                <span
                                    className="pointer"
                                    onClick={() =>
                                        history.push(
                                            `/dashboard/profile/${cl.user}`
                                        )
                                    }
                                >
                                    {cl.user}
                                </span>
                            </div>

                            {config.team_mode && (
                                <div className="flex-1">
                                    <span
                                        className="pointer"
                                        onClick={() =>
                                            history.push(
                                                `/dashboard/teamstats/${cl.team}`
                                            )
                                        }
                                    >
                                        {cl.team}
                                    </span>
                                </div>
                            )}
                            <div className="flex-1">{cl.challenge}</div>

                            <div className="flex-1">{cl.log_message}</div>
                            <div style={{ flex: 1.7 }}>{cl.ip_address}</div>
                        </div>
                    ))}
            </div>
        </>
    );
}

export default ChallengeLogs;
