import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    api,
    Input,
    Checkbox,
    GET_TEAMS,
    LOADING,
    ALERT,
    SEARCH,
} from "../../";
import { useHistory } from "react-router-dom";
function TeamEdit({ team }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [team_token, setteam_token] = useState(team.team_token || "");
    const [team_name, setteam_name] = useState(team.team_name || "");
    const [banned, setBanned] = useState(team.banned || false);
    const [hidden, setHidden] = useState(team.hidden || false);

    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "edit team",
                icon: true,
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "teams" },
            });
    }, [dispatch]);
    const handleSubmit = async () => {
        dispatch({
            type: LOADING,
        });
        const teamObj = {
            id: team.id,
            team_name,
            team_token,
            banned,
            hidden,
        };
        try {
            await api.updateTeam(teamObj);
            const res = await api.getTeams();
            dispatch({
                type: GET_TEAMS,
                data: res.data.teams,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Changes applied",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            history.goBack();
            dispatch({
                type: LOADING,
            });
        }
    };

    async function handleDelete() {
        dispatch({
            type: LOADING,
        });
        try {
            await api.deleteTeam(team.team_name);
            const res = await api.getTeams();
            dispatch({
                type: GET_TEAMS,
                data: res.data.teams,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `'${team.team_name}' deleted successfully`,
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `'${team.team_name}' can't be deleted`,
                    type: "error",
                },
            });
        } finally {
            history.goBack();
            dispatch({
                type: LOADING,
            });
        }
    }
    function handleTeamName(teamname) {
        const len = teamname.length - 1;
        if (!teamname.match(/^[0-9a-zA-Z_ ]+$/) && teamname[len]) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `' ${teamname[len]} ' not allowed`,
                    type: "error",
                },
            });
        } else {
            setteam_name(teamname);
        }
    }
    return (
        <>
            <Input
                label="TEAM NAME"
                placeholder="Enter team name"
                value={team_name}
                onChange={handleTeamName}
            />
            <Input
                label="TEAM TOKEN"
                placeholder="Enter team token"
                value={team_token}
                onChange={setteam_token}
            />
            <div className="d-flex justify-content-evenly mt-1 mb-2">
                <Checkbox
                    checked={hidden}
                    onChange={() => setHidden(!hidden)}
                    label="hidden"
                    id="team_hidden"
                />
                <Checkbox
                    checked={banned}
                    onChange={() => setBanned(!banned)}
                    label="banned"
                    id="team_banned"
                />
            </div>
            <div className="m_text-center">
                <button onClick={handleSubmit} className="m_btn m_btn--medium">
                    Submit
                </button>
            </div>
            <div className="m_text-center m_mt-2">
                <button
                    onClick={handleDelete}
                    className="m_btn m_btn--secondary"
                >
                    Delete
                </button>
            </div>
        </>
    );
}

export default TeamEdit;
