import React from "react";
import { ALERT } from "../";
import { useDispatch } from "react-redux";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
const max_size = 32 * 1024 * 1024;
function InputFile({
    className,
    label,
    file,
    onChange,
    onClick,
    btn,
    id,
    className2,
    value,
    loading,
    label__side = null,
    show = true,
    tag = "No file chosen",
}) {
    const dispatch = useDispatch();
    return (
        <div
            className={`m_input-group-container ${className ? className : ""}`}
        >
            {(label || label === "") && (
                <label>
                    <h6 className="m_spaced"> {label}</h6>
                </label>
            )}
            <div className="m_input-group-icon-container">
                {(label__side || label__side === "") && (
                    <label className="m_event_label_side">
                        <h3>{label__side}</h3>
                    </label>
                )}
                <div className="m_input-group-icon m_event_responsive_file">
                    <div
                        className={`m_input-group ${
                            className2 ? className2 : ""
                        }`}
                    >
                        <input
                            hidden
                            type="file"
                            id={id}
                            onChange={(e) => {
                                if (e.target.files[0].size > max_size) {
                                    dispatch({
                                        type: ALERT,
                                        data: {
                                            open: true,
                                            message:
                                                "File size cannot exceed 32 MB",
                                            type: "warning",
                                        },
                                    });

                                    return;
                                }
                                onChange(e.target.files[0]);
                            }}
                        />
                        <div className="m_filenames">
                            {show && (
                                <div className="m_flex-1">
                                    <h6 className="m_over_flow">
                                        {value
                                            ? value.split("-").pop()
                                            : "No file uploded"}
                                    </h6>
                                </div>
                            )}
                            <div
                                style={{
                                    textAlign: `${show ? "right" : "left"}`,
                                    flex: 1,
                                    marginLeft: "1rem",
                                }}
                            >
                                <h6>{file ? file.name : tag}</h6>
                            </div>
                        </div>
                        <div className="m_input-file ml-1">
                            <label htmlFor={id} className="m_icon m_icon__sm">
                                <NoteAddOutlinedIcon />
                            </label>
                            <div
                                onClick={onClick}
                                className={`${
                                    loading
                                        ? "m_tab-btn m_icon m_icon__sm"
                                        : "m_icon m_icon__sm"
                                }`}
                            >
                                <PublishOutlinedIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InputFile;
