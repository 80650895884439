import React from "react";
import * as Icons from "@material-ui/icons";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
function NavItem({ nav }) {
    let Icon = Icons[nav.category_icon];
    if (!Icon) {
        Icon = PublicOutlinedIcon;
    }

    return (
        <div className="m_nav">
            {nav.category_icon !== "no-icon" && (
                <div className="m_icon">
                    <Icon />
                    {!isNaN(parseInt(nav.notification)) &&
                        nav.notification !== 0 &&
                        nav.notification > 0 && (
                            <h6 className="m_notification">
                                {nav.notification}
                            </h6>
                        )}
                </div>
            )}
            <h6>{nav.category_name}</h6>
        </div>
    );
}

export default NavItem;
