import { useEffect } from "react";
import ChallengeLogs from "./ChallengeLogs";
import UserLogs from "./UserLogs";
import {
  LocalAtm,
  OutlinedFlag,
  PersonOutlineOutlined,
} from "@material-ui/icons";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import AdminLogs from "./AdminLogs";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH } from "../../";
import {
  useLocation,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import SubscriptionLogs from "./SubscriptionLogs";

function Logs() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useSelector((state) => state.search);
  const style = {
    backgroundColor: "var(--light)",
    padding: "0.5em 2em",
    borderRadius: "0",
  };
  const { pathname } = useLocation();
  const toggle = pathname.split("/").pop();
  useEffect(() => {
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "logs",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);
  return (
    <div className="m_logs">
      <div className="m_d-flex">
        <div
          style={toggle === "userlogs" ? style : null}
          className="m_log_tab"
          onClick={() => history.push("/dashboard/logs/userlogs")}
        >
          <div className="m_icon">
            <PersonOutlineOutlined />
          </div>
          <h5>User</h5>
        </div>
        <div
          style={toggle === "challengelogs" ? style : null}
          className="m_log_tab"
          onClick={() => history.push("/dashboard/logs/challengelogs")}
        >
          <div className="m_icon">
            <OutlinedFlag />
          </div>
          <h5>Challenge</h5>
        </div>

        <div
          style={toggle === "adminlogs" ? style : null}
          className="m_log_tab"
          onClick={() => history.push("/dashboard/logs/adminlogs")}
        >
          <div className="m_icon">
            <VerifiedUserOutlinedIcon />
          </div>
          <h5>Admin</h5>
        </div>
        <div
          style={toggle === "subscriptionlogs" ? style : null}
          className="m_log_tab"
          onClick={() => history.push("/dashboard/logs/subscriptionlogs")}
        >
          <div className="m_icon">
            <LocalAtm />
          </div>
          <h5>Subscription</h5>
        </div>
      </div>

      <div className="m_table">
        <Switch>
          <Route path="/dashboard/logs/userlogs">
            <UserLogs search={search.value} />
          </Route>
          <Route path="/dashboard/logs/challengelogs">
            <ChallengeLogs search={search.value} />
          </Route>
          <Route path="/dashboard/logs/adminlogs">
            <AdminLogs search={search.value} />
          </Route>
          <Route path="/dashboard/logs/subscriptionlogs">
            <SubscriptionLogs search={search.value} />
          </Route>
          <Redirect to="/dashboard/logs/userlogs" from="/dashboard/logs" />
        </Switch>
      </div>
    </div>
  );
}

export default Logs;
