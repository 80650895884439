import { useState } from "react";
import Pagination from "./Pagination";
import TableHeading from "./TableHeading";
import TableHeader from "./TableHeader";
import { useSelector } from "react-redux";
import { searchData, paginate } from "./../utils/";
import AddIcon from "@material-ui/icons/Add";
import { ImportExportOutlined } from "@material-ui/icons";
import { api } from "./../../app/services";
function PaginationComponent({
  TableBody,
  items,
  pageSize,
  headers,
  heading,
  handleToggle,
  icon,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalCount = items.length;
  const search = useSelector((state) => state.search);
  function handlePageChange(page, pages, action = "default") {
    const newPage = currentPage + page - (action === "jump" && currentPage);
    if (newPage > 0 && newPage <= pages) setCurrentPage(newPage);
  }

  var searchItem = items;
  if (search && search.value) {
    searchItem = searchData(search.value, items);
  }
  let paginateItems = paginate(searchItem, currentPage, pageSize);
  async function handleDownloadUsers() {
    try {
      const res = await api.exportLogs("users");
      var data = new Blob([JSON.stringify(res.data)], {
        type: "application/json",
      });
      var url = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", "users.json");
      tempLink.click();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="table">
        {heading === "USERS" && (
          <div
            onClick={handleDownloadUsers}
            className="icon icon__primary corner"
          >
            <ImportExportOutlined />
          </div>
        )}
        {handleToggle && (
          <div onClick={handleToggle} className="close">
            <div className="icon__primary">
              <AddIcon />
            </div>
          </div>
        )}
        <TableHeading
          handleToggle={handleToggle}
          heading={heading}
          icon={icon}
        />
        <TableHeader headers={headers} />
        <TableBody paginateItems={paginateItems} />
      </div>
      <Pagination
        itemsCount={totalCount}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </>
  );
}

export default PaginationComponent;
