import React, { useState } from "react";
import { api, InputPassword, ALERT } from "../../../";
import { useDispatch } from "react-redux";
function ChangePassword({ handleToggle }) {
    const [password, setPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_new_password, setConfirmNewPassword] = useState("");
    const dispatch = useDispatch();
    async function handleSubmit() {
        try {
            if (new_password !== confirm_new_password) {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Password does not match",
                        type: "error",
                    },
                });
                return;
            }
            const res = await api.updatePassword({
                password,
                new_password,
                confirm_new_password,
            });
            if (res && res.data && res.data.status === "ok") {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Password changed",
                        type: "success",
                    },
                });
                setNewPassword("");
                setConfirmNewPassword("");
                setPassword("");
                window.location = "/auth/login";
                handleToggle();
            }
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response.data.status,
                    type: "error",
                },
            });
        }
    }
    return (
        <>
            <InputPassword
                label="Old Password"
                placeholder="Old Password"
                value={password}
                onChange={setPassword}
            />
            <InputPassword
                label="New Password"
                placeholder="New Password"
                value={new_password}
                onChange={setNewPassword}
            />
            <InputPassword
                label="Confirm New Password"
                placeholder="Confirm New Password"
                value={confirm_new_password}
                onChange={setConfirmNewPassword}
            />
            <div className="text-center">
                <button
                    onClick={() =>
                        handleSubmit({
                            password,
                            new_password,
                            confirm_new_password,
                        })
                    }
                    className="btn btn--medium"
                >
                    Submit
                </button>
            </div>
        </>
    );
}

export default ChangePassword;
