import React, { useEffect, useState } from "react";
import { Input, MarkdownEditor, Checkbox, InputFile } from "../../components";
import { api } from "../../services";
import { ALERT, GET_PLANS, SEARCH } from "../../store";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { Toggle } from "../../";
import PlanChallenges from "../../../app/pages/Plans/PlanChallenges";
import { DeleteOutline } from "@material-ui/icons";

function AddPlan({ plan = {}, type = "add" }) {
  const [name, setname] = useState(plan.name || "");
  const [description, setdescription] = useState(plan.description || "");
  const [price, setPrice] = useState(plan.price || 0);
  const [days, setDays] = useState(plan.days || 0);
  const [enabled, setEnabled] = useState(plan.enabled || false);
  const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState("");
  const [image_url, setImageUrl] = useState(plan?.image || null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: `${type} Plan`,
        setting: false,
        icon: true,
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "plans", icon: false, setting: false },
      });
  }, [type, dispatch]);
  async function handleSubmit() {
    const planData = {
      name,
      description,
      price: parseFloat(price),
      enabled,
      days: parseInt(days),
      image: image_url,
    };
    setLoading(true);
    try {
      let message = "added";
      if (type === "update") {
        const _plan = { id: plan.id, ...planData };
        await api.updatePlan(_plan);
        message = "updated";
      } else {
        await api.addPlan(planData);
      }
      history.goBack();
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Plan ${message} successfully`,
          type: "success",
        },
      });
      let res = await api.getPlans();
      let data = res.data.plans;
      dispatch({
        type: GET_PLANS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }
  async function upload() {
    if (!uploadImage) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    try {
      setLoading(true);
      const res = await api.uploadImage(uploadImage);
      setImageUrl(res?.data?.file_url);
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function deleteThePlan() {
    try {
      await api.deletePlan(plan.id);
      let res = await api.getPlans();
      let data = res.data.plans;
      dispatch({
        type: GET_PLANS,
        data,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Plan deleted",
          type: "error",
        },
      });
      history.goBack();
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err?.response?.data?.status || "Failed to delete Plan",
          type: "error",
        },
      });
    }
  }

  return (
    <Switch>
      <Route path={`/dashboard/plans/${type}/addChallenge`}>
        <Toggle>
          <PlanChallenges planChallengId={plan.id} />
        </Toggle>
      </Route>
      <Route exact path={`/dashboard/plans/${type}`}>
        <div className="m_p-2">
          <Input
            label="PLAN NAME*"
            placeholder="Enter Plan Name"
            value={name}
            onChange={setname}
          />
          {image_url ? (
            <>
              <label className="m_d-flex align-items-center justify-content-between mb-1">
                <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
                  PLAN IMAGE
                </h6>
              </label>
              <div className="d-flex align-items-end">
                <img
                  src={image_url}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px",
                  }}
                  alt="plan"
                />
                <div
                  className="icon icon__primary ml-1"
                  onClick={() => setImageUrl(null)}
                >
                  <DeleteOutline />
                </div>
              </div>
            </>
          ) : (
            <InputFile
              onChange={setUploadImage}
              file={uploadImage}
              id="media_library"
              width="15rem"
              show={false}
              className="mt-1"
              loading={loading}
              onClick={loading ? null : upload}
              btn={loading ? "Uploading" : "Upload file"}
              value={uploadImage}
              n={30}
              // width="30rem"
            />
          )}
          <div className="m_event_editor m_mb-1" style={{ marginTop: "-1rem" }}>
            <label className="m_d-flex align-items-center justify-content-between mt-2 mb-1">
              <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
                PLAN DESCRIPTION
              </h6>
            </label>
            <MarkdownEditor
              height={200}
              value={description}
              onChange={setdescription}
            />
          </div>
          <div className="d-flex" style={{ gap: "1rem" }}>
            <Input
              label="PLAN PRICE*"
              type="number"
              placeholder="Enter Plan Days"
              value={price}
              onChange={setPrice}
            />
            <Input
              label="PLAN DAYS*"
              type="number"
              placeholder="Enter Plan Days"
              value={days}
              onChange={setDays}
            />
          </div>
          <div className="d-flex">
            <Checkbox
              label="Enabled"
              id="PlanEnabled"
              checked={enabled}
              onChange={() => setEnabled(!enabled)}
            />
          </div>

          <div className="m_text-center m_mt-1 m_mb-1">
            <button
              onClick={!loading ? handleSubmit : null}
              className={`m_btn  ${
                loading ? "m_tab-btn" : "m_btn m_btn--medium"
              }`}
            >
              {loading ? "Submitting" : "Submit"}
            </button>
          </div>

          {type === "update" && (
            <div className="m_text-center m_mt-2">
              <button
                onClick={() =>
                  history.push(`/dashboard/plans/${type}/addChallenge`)
                }
                className="m_btn m_btn--medium"
              >
                Add Challenge
              </button>
            </div>
          )}
          {type === "update" && (
            <div className="m_text-center m_mt-2">
              <button
                onClick={deleteThePlan}
                className="m_btn m_btn--secondary"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </Route>
    </Switch>
  );
}

export default AddPlan;
