import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import headers from "../../utils/headers";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TeamEdit from "./TeamEdit";
import { useHistory } from "react-router-dom";
import {
    Toggle,
    PaginationComponent,
    GET_TEAMS,
    SEARCH,
    api,
    LOADING,
    ALERT,
    SmLoading,
} from "../../";
import orderBy from "lodash/orderBy";
const TableBody = ({ paginateItems }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);
    const [team, setTeam] = useState({});
    const handleToggle = () => setToggle(!toggle);
    const [id, setID] = useState("");
    async function handleEdit(teamname) {
        dispatch({
            type: LOADING,
            data: true,
        });
        try {
            let res = await api.getAdminTeam(teamname);
            setTeam(res.data);
            res = await api.getTeams();
            dispatch({
                type: GET_TEAMS,
                data: res.data.teams,
            });
            handleToggle();
        } catch (error) {
        } finally {
            dispatch({
                type: LOADING,
                data: false,
            });
        }
    }
    async function handleDelete(teamname) {
        dispatch({
            type: LOADING,
            data: true,
        });
        try {
            await api.deleteTeam(teamname);
            const res = await api.getTeams();
            dispatch({
                type: GET_TEAMS,
                data: res.data.teams,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `'${teamname}' deleted successfully`,
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `'${teamname}' can't be deleted`,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
                data: false,
            });
        }
    }
    const filtered = orderBy(
        paginateItems,
        [(s) => s.team_name.toLowerCase()],
        ["asc"]
    );
    return (
        <>
            {toggle && (
                <Toggle handleToggle={handleToggle}>
                    <TeamEdit handleToggle={handleToggle} team={team} />
                </Toggle>
            )}
            <div className="table_data">
                {filtered &&
                    filtered.map((team, index) => (
                        <div
                            key={index}
                            onClick={() => setID(team.id)}
                            className={`table_data_item ${
                                id === team.id ? "white-space" : ""
                            }`}
                        >
                            <div className="flex-1">
                                <span
                                    onClick={() =>
                                        history.push(
                                            `/dashboard/teamstats/${team.team_name}`
                                        )
                                    }
                                    className="pointer"
                                >
                                    {team.team_name}{" "}
                                </span>
                            </div>
                            <div className="d-flex flex-1 justify-content-center">
                                <div
                                    onClick={() => {
                                        handleEdit(team.team_name);
                                    }}
                                    className="icon__primary"
                                >
                                    <CreateOutlinedIcon />
                                </div>
                                <div
                                    onClick={() => handleDelete(team.team_name)}
                                    className="icon__primary"
                                >
                                    <DeleteOutlinedIcon />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
function Teams() {
    const dispatch = useDispatch();
    const teams = useSelector((state) => state.teams);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const res = await api.getTeams();
                dispatch({
                    type: GET_TEAMS,
                    data: res.data.teams,
                });
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "search teams",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch]);

    return (
        <div className="teams_container">
            {loading ? (
                <SmLoading />
            ) : (
                <PaginationComponent
                    heading="TEAMS"
                    headers={headers.teams}
                    items={teams}
                    pageSize={18}
                    TableBody={TableBody}
                />
            )}
        </div>
    );
}

export default Teams;
