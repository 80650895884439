import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Select, SmLoading } from "../../components";
import { api } from "../../services";
import { ALERT, SEARCH } from "../../store";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

function PlanChallenges({ planChallengId, toggle_id }) {
  const [challenges, setChallenges] = useState([]);
  const [challenge, setChallenge] = useState(null);
  const [planChallenges, setPlanChallenges] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        let res = await api.getAdminChallenges();
        const challenges = res?.data?.challenges;
        if (challenges?.length) {
          const _data = challenges.map(({ id, name }) => {
            return {
              id,
              category_name: name,
              category_icon: "no-icon",
            };
          });
          setChallenges(_data);
        }
        res = await api.getPlanChallenges(planChallengId);
        const data = res?.data?.plan_challenges;
        if (data?.length) {
          const _data = [];
          data.forEach((d) => {
            const challenge = challenges.find((c) => c.id === d.challenge_id);
            if (challenge) {
              _data.push({ name: challenge.name, ...d });
            }
          });
          setPlanChallenges(_data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [planChallengId]);

  useEffect(() => {
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "Add challenge in plan",
        setting: false,
        icon: true,
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "Update Plan" },
      });
  }, [dispatch]);

  async function addChallenge() {
    if (!challenge) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please select a challenge",
          type: "error",
        },
      });
      return;
    }
    const { id } = challenges.find((c) => c.category_name === challenge);
    const payload = {
      challenge_id: id,
      plan_id: planChallengId,
    };
    try {
      await api.addChallengeInPlan(payload);
      const res = await api.getPlanChallenges(planChallengId);
      const data = res?.data?.plan_challenges;
      if (data?.length) {
        const _data = [];
        data.forEach((d) => {
          const challenge = challenges.find((c) => c.id === d.challenge_id);
          if (challenge) {
            _data.push({ name: challenge.category_name, ...d });
          }
        });
        setPlanChallenges(_data);
      } else {
        setPlanChallenges([]);
      }
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Challenges added",
          type: "success",
        },
      });
      setChallenge(null);
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Failed to add challenge",
          type: "error",
        },
      });
    }
  }

  async function handleDelete(id) {
    try {
      await api.deletePlanChallenges(id);
      const res = await api.getPlanChallenges(planChallengId);
      const data = res?.data?.plan_challenges;
      if (data?.length) {
        const _data = [];
        data.forEach((d) => {
          const challenge = challenges.find((c) => c.id === d.challenge_id);
          if (challenge) {
            _data.push({ name: challenge.category_name, ...d });
          }
        });
        setPlanChallenges(_data);
      } else {
        setPlanChallenges([]);
      }
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Challenges delete",
          type: "success",
        },
      });
      setChallenge(null);
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Failed to delete challenge",
          type: "error",
        },
      });
    }
  }
  return (
    <>
      <div className="mt-1 mb-1">
        <Select
          className="mt-1"
          label="Select Challenge to add in plan"
          options={challenges}
          placeholder="Select Challenge"
          onChange={setChallenge}
          value={challenge}
          toggle_id={toggle_id}
        />
        <div className="center">
          <button className="btn btn--medium" onClick={addChallenge}>
            Add Challenge
          </button>
        </div>
      </div>

      <h4>Plan Challenges</h4>
      <div className="m_plan_challenges">
        {!loading &&
          planChallenges?.length &&
          planChallenges.map((planChallenge) => (
            <div key={planChallenge.id} className="m_plan_challenges_item">
              <h5> {planChallenge.name}</h5>
              <div
                className="icon icon__primary"
                onClick={() => handleDelete(planChallenge.id)}
              >
                <DeleteOutlinedIcon />
              </div>
            </div>
          ))}
        {!loading && !planChallenges?.length && (
          <h5 className="text-center mt-1">No Challenge in this plan</h5>
        )}
      </div>
      {loading && (
        <div
          className="center"
          style={{ position: "relative", height: "35px" }}
        >
          <SmLoading />
        </div>
      )}
    </>
  );
}

export default PlanChallenges;
