import React, { useState, useEffect } from "react";

import {
    GET_CATEGORIES,
    api,
    SearchIcons,
    Input,
    getIcon,
    ALERT,
    SEARCH,
    Toggle,
} from "../../";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Switch, Route } from "react-router-dom";
function NewCategory({ type }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                newCategory: "categories",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: `${type} Challenge` },
            });
    }, [dispatch, type]);
    const [category_name, setcategory_name] = useState("");
    const [category_name_r, setcategory_name_r] = useState("");
    const [category_icon, setIcon] = useState("");
    const [category_icon_r, setIcon_r] = useState("");
    const [category, setCategory] = useState("");
    const categories = useSelector((state) => state.categories);
    const handleCategory = async () => {
        try {
            await api.addCategory({ category_name, category_icon });
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            setcategory_name("");
            setIcon("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category_name} added`,
                    type: "success",
                },
            });
            var back = pathname.split("/").includes("detail") ? 2 : 1;
            history.go(-back);
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
        }
    };
    const handleUpdateCategory = async () => {
        try {
            await api.updateCategory({
                id: category.id,
                category_name: category_name_r,
                category_icon: category_icon_r,
            });
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category.category_name} updated`,
                    type: "success",
                },
            });
            history.goBack();
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        }
    };
    const deleteCategory = async () => {
        try {
            await api.deleteCategory(category.id);
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            setCategory("");
            setcategory_name_r("");
            setIcon_r("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category.category_name} deleted`,
                    type: "success",
                },
            });
            history.goBack();
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response ? err.response.data.status : "",
                    type: "error",
                },
            });
        }
    };
    function setRow(c) {
        setCategory(c);
        setcategory_name_r(c.category_name);
        setIcon_r(c.category_icon);
    }
    return (
        <Switch>
            <Route
                path={`/dashboard/challenges/${type}/newcategory/detail/add`}
            >
                <Toggle>
                    <div className="d-flex justify-content-center category_big">
                        <div className="icon mt-1">{getIcon("public")}</div>
                    </div>
                    <div className="mt-2">
                        <Input
                            label="Category Name"
                            placeholder="Name"
                            value={category_name}
                            onChange={setcategory_name}
                        />
                        <SearchIcons icon={category_icon} setIcon={setIcon} />
                        <button
                            onClick={handleCategory}
                            className="m_btn m_btn--medium"
                        >
                            Add
                        </button>
                    </div>
                </Toggle>
            </Route>
            <Route path={`/dashboard/challenges/${type}/newcategory/add`}>
                <Toggle>
                    <div className="d-flex justify-content-center category_big">
                        <div className="icon mt-1">{getIcon("public")}</div>
                    </div>
                    <div className="mt-2">
                        <Input
                            label="Category Name"
                            placeholder="Name"
                            value={category_name}
                            onChange={setcategory_name}
                        />
                        <SearchIcons icon={category_icon} setIcon={setIcon} />
                        <button
                            onClick={handleCategory}
                            className="m_btn m_btn--medium"
                        >
                            Add
                        </button>
                    </div>
                </Toggle>
            </Route>
            <Route path={`/dashboard/challenges/${type}/newcategory/detail`}>
                <Toggle>
                    <div className="d-flex justify-content-center m_category_big">
                        <div className="m_icon mt-1">
                            {getIcon(category_icon_r)}
                        </div>
                    </div>
                    <div className="mt-2">
                        <Input
                            placeholder="Name"
                            value={category_name_r}
                            onChange={setcategory_name_r}
                        />
                        <SearchIcons
                            icon={category_icon_r}
                            setIcon={setIcon_r}
                        />
                        <button
                            onClick={handleUpdateCategory}
                            className="m_btn m_btn--medium mt-1"
                        >
                            Update
                        </button>
                        <button
                            onClick={deleteCategory}
                            className="m_btn m_btn--secondary mt-1"
                        >
                            Delete
                        </button>
                    </div>
                </Toggle>
            </Route>
            <Route exact path={`/dashboard/challenges/${type}/newcategory`}>
                <div className="m_table_data">
                    {categories &&
                        categories.map((c, index) => (
                            <div key={index} className="m_table_data_item">
                                <div className="m_icon mr-1">
                                    {getIcon(c.category_icon)}
                                </div>
                                <div className="m_flex-1">
                                    {c.category_name}
                                </div>
                                <div
                                    style={{ flex: 0.1 }}
                                    onClick={() => {
                                        setRow(c);
                                        history.push(
                                            `/dashboard/challenges/${type}/newcategory/detail`
                                        );
                                    }}
                                    className="m_icon m_icon_light m_d-flex m_justify-content-end"
                                >
                                    <ArrowForwardIosOutlinedIcon />
                                </div>
                            </div>
                        ))}
                </div>
            </Route>
        </Switch>
    );
}
export default NewCategory;
