import React from "react";

function TermsCondition() {
  return (
    <div className="markdown_content_container privacy_terms">
      <div className="markdown_content blur_bg">
        <h2>Terms and Conditions of Use</h2>
        <h5>
          These customer Terms and Conditions apply to all the users of our
          services on the website. Please read these Customer Terms and
          Conditions carefully because they govern each Customer’s access to and
          use of our Services.
        </h5>
        <h3>1. DEFINITIONS</h3>
        <h5>
          The terms “We” / “Us” / “Our”/”Company” individually and collectively
          refer to “Pentest Garage “ and the terms “Visitor” ”User” refer to the
          users.
        </h5>
        <h3>2.TERMS AGREEMENT</h3>
        <h5>
          By using these services Customers are implied to be bound by these
          Customer Terms and Conditions.
        </h5>
        <h5 className="mt-1">
          If you don’t understand or any of the Terms and Condition stay
          ambiguous to your understanding please contact us before using the
          Services
        </h5>
        <h5 className="mt-1">
          You should not be using any of our Services until you agree to all of
          the Customer Terms and Conditions as well as the General Terms and
          Conditions (the “Agreement”).
        </h5>
        <h3>3.INTELLECTUAL PROPERTY OWNERSHIP RIGHTS</h3>
        <h5>
          Except where otherwise indicated, all logos, products, signs,
          headings, labels, titles, signatures, numerals, forms, or any
          variation thereof appearing in this site are either owned by or used
          under license by, the company and/or its associated entities featured
          on this Website.
        </h5>
        <h5 className="mt-1">
          The user needs to understand that the use of these properties or
          content on this site, except those provided authorization to use
          through the site, is strictly prohibited.
        </h5>
        <h5 className="mt-1">
          Without the written permission of the respective organization or
          agency, you may not sell or alter the content of this Website, or
          reproduce, view, publicly perform, distribute, or otherwise use the
          materials in any manner for any public or commercial purpose.
        </h5>
        <h3>4.ACCEPTABLE WEBSITE USE</h3>
        <h5>
          Visitors are prohibited from breaching or threatening to violate the
          website’s security, which includes, but is not limited to,
        </h5>
        <h5 className="mt-1">
          (1) Accessing data not intended for the user or logging into a server
          or account that the user is not authorized to access,
        </h5>
        <h5 className="mt-1">
          (2) Attempting to probe, scan, or test the vulnerability of a system
          or network, or to circumvent security or authentication measures
          without proper authorization, or
        </h5>
        <h5 className="mt-1">
          (2) Attempting to probe, scan, or test the vulnerability of a system
          or network, or to circumvent security or authentication measures
          without proper authorization, or
        </h5>
        <h5 className="mt-1">
          (3) aiming to obstruct service to any user, host, or network,
          including, without limitation, by submitting a virus or “Trojan horse”
          to the Website, overloading, “flooding,” “mail bombing,” or
          “crashing,” or
        </h5>
        <h5 className="mt-1">
          (4) Sending unsolicited electronic mail, including product or service
          promotion and/or ads System or network security violations can result
          in civil or criminal liability.
        </h5>
        <h5 className="mt-1">
          (5)Your account will be suspended if you post or engage in any content
          that is not related to Cybersecurity or post any malicious links or
          foul invective.
        </h5>
        <h5 className="mt-2">
          The company and/or its affiliated agencies may have the right to
          prosecute incidents they suspect of containing such violations, as
          well as to engage and collaborate with law enforcement officials in
          investigating consumers who are engaged in such violations.
        </h5>
        <h3>INDEMNITY</h3>
        <h5>
          The Customer agrees to indemnify and hold harmless the Company, its
          officers, directors, staff, and agents without waiver against and
          against all charges, acts, or requests, and/or obligations and/or
          losses and/or damages arising from or resulting from their use of
          redteam360.com or their violation of the terms.
        </h5>
        <h3>LIABILITY</h3>
        <h5>
          The user accepts that the Company and its group of companies,
          directors, officers, or employees will not be responsible for any
          actual, indirect, incidental, exceptional, consequential, or exemplary
          damages arising from the use or failure to use the service, or for the
          expense of procuring replacement products or services or resulting
          from any goods, records, or information obtained through the service.
        </h5>
        <h3> CONTACT US</h3>
        <h5 className="mt-1">
          If you have any questions concerning the Terms and Conditions please
          contact RedTeam Hacker Academy
        </h5>
        <h5 className="mt-1">RedTeam Hacker Academy</h5>
        <h5>
          C.M Mathew Brothers Arcade, Fourth Floor, Kannur Rd, above CSB Bank,
        </h5>
        <h5>West Nadakkave, Chakkorathukulam,</h5>

        <h5>Kozhikode, Kerala 673011</h5>

        <h5 className="mt-2">
          Thank you for reading our Terms. We hope you would enjoy the services
          provided in our website.
        </h5>
      </div>
    </div>
  );
}

export default TermsCondition;
