import { adminSideBar, userSideBar } from "../../utils/sidebar";
import SideBarItem from "./SideBarItem";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import { useSelector } from "react-redux";
import GameClock from "../GameClock";

function SideBar() {
  let [app, sidebarCategories, hide, config, notifications] = useSelector(
    (state) => [
      state.app,
      state.sidebarCategories,
      state.hide,
      state.config,
      state.notifications,
    ]
  );
  let adminSideBarItem = adminSideBar;
  let userSideBarItem = userSideBar;

  if (!config.scoreboard_enabled) {
    adminSideBarItem = adminSideBar.filter(
      (c) => c.category_name !== "Scoreboard"
    );
  }
  if (!config.plan_mode) {
    adminSideBarItem = adminSideBar.filter((c) => c.category_name !== "Plans");
  }
  if (!config.team_mode) {
    adminSideBarItem = adminSideBarItem.filter(
      (c) => c.category_name !== "Teams"
    );
  }
  if (!app.isAdmin) {
    userSideBar[0].count = parseInt(notifications ? notifications.length : 0);
  }
  if (!config.scoreboard_enabled) {
    userSideBarItem = userSideBar.filter(
      (c) => c.category_name !== "Scoreboard"
    );
  }
  function calculateMaxHeight(hide, game_clock) {
    if (hide) {
      return "calc(100vh - 6.4rem)";
    }
    return `calc(100vh - ${game_clock ? "15.5rem" : "6.5rem"})`;
  }
  return (
    <div className={`sidebar-container ${!hide ? "expanded" : ""}`}>
      <div
        style={{
          maxHeight: calculateMaxHeight(hide, config.game_clock),
          overflowY: "auto",
        }}
      >
        <SideBarItem
          heading="DASHBOARD"
          Icon={SpeedOutlinedIcon}
          items={app.isAdmin ? adminSideBarItem : userSideBarItem}
          hide={hide}
        />
        <SideBarItem
          heading="CHALLENGES"
          Icon={LocalOfferOutlinedIcon}
          items={sidebarCategories}
          hide={hide}
        />
      </div>
      {config.game_clock && <GameClock hide={hide} />}
    </div>
  );
}

export default SideBar;
