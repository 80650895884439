import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import PauseOutlinedIcon from "@material-ui/icons/PauseOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import Challenge from "./Challenge";
import Info from "./Info";
import AddDeployment from "./AddDeployment";
import {
  SIDEBAR_CATEGORIES,
  headers,
  Toggle,
  api,
  PaginationComponent,
  build_categories,
  filterChallenges,
  GET_CHALLENGES,
  SEARCH,
  GET_DEPLOYMENTS,
  LOADING,
  ALERT,
  SmLoading,
} from "../../";
import orderBy from "lodash/orderBy";
const TableBody = ({ paginateItems }) => {
  const dispatch = useDispatch();
  const [categories, deployments] = useSelector((state) => [
    state.categories,
    state.deployments,
  ]);

  const [loading, setLoading] = useState(null);
  const [info, setInfo] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [id, setID] = useState(null);
  const [cid, setCID] = useState(null);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => setToggle(!toggle);

  function handleDeployment(id) {
    setChallenge(null);
    setInfo(null);
    setID(id);
    handleToggle();
  }

  function handleSearchInfo(id) {
    const info = deployments.find((d) => d.cid === id);
    if (info) {
      setChallenge(null);
      setID(null);
      setInfo(info);
      handleToggle();
    }
  }
  async function startChallenge(id) {
    setLoading(false);
    try {
      await api.startChallenge(id);
      const res = await api.getChallengesDeployments();
      dispatch({
        type: GET_DEPLOYMENTS,
        data: res.data.challenge_deployments,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Deployment started`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(null);
    }
  }
  async function stopChallenge(id) {
    setLoading(false);
    try {
      await api.stopChallenge(id);
      const res = await api.getChallengesDeployments();
      dispatch({
        type: GET_DEPLOYMENTS,
        data: res.data.challenge_deployments,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Deployment stopped`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(null);
    }
  }
  async function deleteChallengeDeployment(id) {
    dispatch({
      type: LOADING,
    });
    try {
      await api.deleteChallengeDeployment(id);

      const res = await api.getChallengesDeployments();
      dispatch({
        type: GET_DEPLOYMENTS,
        data: res.data.challenge_deployments,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Deployment deleted`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }

  async function handleDelete(id) {
    dispatch({
      type: LOADING,
    });
    try {
      await api.deleteChallenge(id);
      const res = await api.getAdminChallenges();
      dispatch({
        type: GET_CHALLENGES,
        data: res.data.challenges,
      });
      const sideBarCategories = build_categories(
        res.data.challenges,
        categories
      );
      dispatch({
        type: SIDEBAR_CATEGORIES,
        data: sideBarCategories,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Challenge deleted`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }

  async function handleChallenge(id) {
    dispatch({
      type: LOADING,
    });
    try {
      const res = await api.getAdminChallenge(id);
      setInfo(null);
      setID(null);
      setChallenge(res.data);
      handleToggle();
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Deployment can't be deleted`,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  return (
    <>
      {toggle && !challenge && (
        <Toggle handleToggle={handleToggle}>
          {info && <Info info={info} />}
          {id && <AddDeployment id={id} handleToggle={handleToggle} />}
        </Toggle>
      )}
      {toggle && challenge && (
        <Toggle classes="challengePanel" handleToggle={handleToggle}>
          <Challenge
            toggle_id={"challengePanel"}
            type="update"
            challenge={challenge}
            handleToggle={handleToggle}
          />
        </Toggle>
      )}
      <div className="table_data">
        {paginateItems &&
          paginateItems.map((c, index) => (
            <div
              key={index}
              onClick={() => setCID(c.id)}
              className={`table_data_item ${cid === c.id ? "white-space" : ""}`}
            >
              <div style={{ flex: 0.5 }}>{c.name}</div>
              <div
                style={{
                  color: loading === c.id ? "var(--blue)" : c.status.color,
                  opacity: 0.8,
                  flex: 0.2,
                }}
              >
                {loading === c.id ? (
                  <CachedOutlinedIcon className={loading ? "spin" : ""} />
                ) : (
                  <c.status.icon />
                )}
              </div>
              <div className="flex-1">{c.category}</div>
              <div style={{ flex: 0.3 }}>{c.points}</div>
              <div style={{ flex: 0.3 }}>{c.difficulty}</div>
              <div
                style={{ flex: 0.4 }}
                className="d-flex justify-content-center flex-1"
              >
                {c.status.color !== "var(--text)" ? (
                  <>
                    {c.status.color === "var(--red)" ? (
                      <div
                        onClick={() => startChallenge(c.id)}
                        className="icon__primary"
                      >
                        <PlayArrowOutlinedIcon />
                      </div>
                    ) : (
                      <div
                        onClick={() => stopChallenge(c.id)}
                        className="icon__primary"
                      >
                        <PauseOutlinedIcon />
                      </div>
                    )}
                    <div
                      onClick={() => deleteChallengeDeployment(c.id)}
                      className="icon__primary"
                    >
                      <DeleteOutlinedIcon />
                    </div>
                    <div
                      onClick={() => handleSearchInfo(c.id)}
                      className="icon__primary"
                    >
                      <InfoOutlinedIcon />
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => handleDeployment(c.id)}
                    className="icon__primary"
                  >
                    <AddIcon />
                  </div>
                )}
              </div>
              <div
                style={{ flex: 0.3 }}
                className="d-flex justify-content-center flex-1"
              >
                <div
                  onClick={() => handleChallenge(c.id)}
                  className="icon__primary"
                >
                  <CreateOutlinedIcon />
                </div>
                <div
                  onClick={() => handleDelete(c.id)}
                  className="icon__primary"
                >
                  <DeleteOutlinedIcon />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

function AdminChallenges() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [challenges, deployments, search] = useSelector((state) => [
    state.challenges,
    state.deployments,
    state.search,
  ]);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => setToggle(!toggle);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        let res = await api.getAdminChallenges();
        let data = res.data.challenges;
        dispatch({
          type: GET_CHALLENGES,
          data,
        });

        res = await api.getChallengesDeployments();
        data = res.data.challenge_deployments;
        dispatch({
          type: GET_DEPLOYMENTS,
          data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "search challenge",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);

  var filteredChallenges = [];

  if (challenges) {
    filteredChallenges = filterChallenges(search, challenges, deployments);
    filteredChallenges = orderBy(
      filteredChallenges,
      [(s) => s.name.toLowerCase()],
      ["asc"]
    );
  }

  return (
    <div className="challenges">
      {toggle && (
        <Toggle classes="challengePanel" handleToggle={handleToggle}>
          <Challenge toggle_id={"challengePanel"} handleToggle={handleToggle} />
        </Toggle>
      )}

      {loading ? (
        <SmLoading />
      ) : (
        <PaginationComponent
          heading="CHALLENGES"
          headers={headers.challenges}
          items={filteredChallenges}
          pageSize={18}
          TableBody={TableBody}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
}

export default AdminChallenges;
