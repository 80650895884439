const _ = require("lodash");
function date_time(unix) {
    var date = new Date(unix * 1000).toString();
    date = date.split(" ");
    return `${date[2]} ${date[1]} ${date[3]} ${date[4]}`;
}
export default function searchData(search, array) {
    var matched = [];
    if (!search) return array;
    array.forEach((obj) => {
        for (var key in obj) {
            if (key === "id") continue;
            if (!obj.hasOwnProperty(key) || !obj[key]) continue;
            var value = obj[key];
            if (key.toLocaleLowerCase() === "time") {
                value = date_time(obj[key]);
            }
            if (
                value
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase().trim()) !== -1
            ) {
                matched.push(obj);
            }
        }
    });
    return _.uniqWith(matched, _.isEqual);
}
