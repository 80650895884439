import { createGlobalStyle } from "styled-components";
function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(", ");
        return `rgba(${rgb}, ${opacity})`;
    }
    return "#fff";
}
const GlobalStyles = createGlobalStyle`
    :root {
        --dark: ${({ theme }) => theme.dark};
        --light: ${({ theme }) => theme.light};
        --medium: ${({ theme }) => theme.medium};
        --accent: ${({ theme }) => theme.accent};
        --secondary: ${({ theme }) => theme.secondary};
        --text_color: ${({ theme }) => theme.text_color};
        --text_color_5: ${({ theme }) => hexToRgbA(theme.text_color, 0.5)};
        --green: ${({ theme }) => theme.green};
        --blue: ${({ theme }) => theme.blue};
        --rgba-medium_7: ${({ theme }) => hexToRgbA(theme.dark, 0.5)};
        --button_light: ${({ theme }) => hexToRgbA(theme.light, 0.5)};
        --dark-selected: ${({ theme }) => hexToRgbA(theme.dark, 0.7)};
    }
  }`;

export default GlobalStyles;
