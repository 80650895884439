import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LOADING, api, Input, InputPassword, ALERT } from "../../";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
function Register({ endpoint, site_key }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  if (endpoint !== "setup")
    if (!sessionStorage.getItem("setup")) {
      history.push("/auth/setup");
    }
  useEffect(() => {
    async function fetch() {
      dispatch({
        type: LOADING,
      });

      try {
        const res = await api.getStatus();
        if (res.data.status === "ok") {
          history.push("/auth/login");
          sessionStorage.setItem("setup");
        }
      } catch (err) {
      } finally {
        dispatch({
          type: LOADING,
        });
      }
    }
    if (endpoint === "setup") {
      fetch();
    }
  }, [endpoint, dispatch, history]);
  async function handleRegister() {
    if (
      username === "" ||
      password === "" ||
      confirm_password === "" ||
      email === "" ||
      confirm_email === ""
    ) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `${
            username === ""
              ? "Username"
              : "" || email === ""
              ? "Email"
              : "" || confirm_email === ""
              ? "Confirm email"
              : "" || password === ""
              ? "Password"
              : "" || confirm_password === ""
              ? "Confirm password"
              : ""
          } is required`,
          type: "error",
        },
      });
      return;
    }
    if (email !== confirm_email) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Email address does not match",
          type: "error",
        },
      });
      return;
    }
    if (password !== confirm_password) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Password does not match",
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: LOADING,
    });
    try {
      var data = {
        username,
        password,
        confirm_password,
        email,
        confirm_email,
      };
      if (site_key && endpoint === "register") {
        const g_recaptcha_response = await executeRecaptcha("register");
        data = { ...data, g_recaptcha_response };
      }
      const res = await api[endpoint](data);
      if (res.status === 200) {
        window.location = "/auth/login";
        if (endpoint === "setup") sessionStorage.setItem("setup", "true");
      }
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response && err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }

  function handleUsername(username) {
    const len = username.length - 1;
    if (!username.match(/^[0-9a-zA-Z_ ]+$/) && username[len]) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `' ${username[len]} ' not allowed`,
          type: "error",
        },
      });
    } else {
      setUserName(username);
    }
  }
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleRegister();
    }
  }
  return (
    <>
      <div className="m_login-separater">
        <span>Register an account</span>
        <hr />
      </div>
      <Input
        label="USERNAME"
        placeholder="username"
        value={username}
        onChange={handleUsername}
        onKeyDown={handleKeyDown}
      />
      <Input
        label="EMAIL"
        type="email"
        placeholder="email"
        value={email}
        onChange={setEmail}
        onKeyDown={handleKeyDown}
      />
      <Input
        label="Confirm Email"
        type="email"
        placeholder="confirm email"
        value={confirm_email}
        onChange={setConfirmEmail}
        onKeyDown={handleKeyDown}
      />
      <InputPassword
        label="PASSWORD"
        placeholder="password"
        value={password}
        onChange={setPassword}
        onKeyDown={handleKeyDown}
      />
      <InputPassword
        label="CONFIRM PASWORD"
        placeholder="confirm password"
        value={confirm_password}
        onChange={setConfirmPassword}
        onKeyDown={handleKeyDown}
      />

      <div className="m_text-center">
        <button
          onClick={handleRegister}
          className="m_btn m_btn--medium m_mv-1"
          onKeyDown={handleKeyDown}
        >
          {endpoint === "register" ? "Register" : "Setup"}
        </button>
      </div>
      <hr />
      <div className="center">
        {sessionStorage.getItem("setup") && (
          <h5>
            Already have an account?
            <strong>
              <Link to="/auth/login">&nbsp;Login</Link>
            </strong>
          </h5>
        )}
      </div>
    </>
  );
}

export default Register;
