import React from "react";
import MDEditor from "@uiw/react-md-editor";

export default function MarkdownEditor({ value, onChange }) {
    return (
        <MDEditor
            textareaProps={{
                placeholder: "Please enter Markdown text",
            }}
            value={value}
            onChange={onChange} 
            toolbarHeight={50}
        />
    );
}
