import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Routes from "./../../utils/Routes";

function Challenges() {
    const sidebarCategories = useSelector((state) => state.sidebarCategories);
    if (sidebarCategories && sidebarCategories.length > 0) {
        return (
            <Redirect
                to={`${Routes.challenge}/${sidebarCategories[0].category_name}`}
            />
        );
    } else return null;
}

export default Challenges;
