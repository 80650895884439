import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import {
    api,
    GET_USERS_LOGS,
    TableHeader,
    searchData,
    headers,
    date_time,
} from "../../";

import { useHistory } from "react-router";

function AdminLogs({ search }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const userLogs = useSelector((state) => state.userLogs);
    const [id, setID] = useState("");

    const filteredUsers = searchData(search, userLogs);
    useEffect(() => {
        async function fetch() {
            try {
                const res = await api.getAdminLogs();
                let logs = res.data.user_logs;
                logs = orderBy(logs, ["time"], ["desc"]);
                dispatch({
                    type: GET_USERS_LOGS,
                    data: logs,
                });
            } finally {
            }
        }
        fetch();
    }, [dispatch]);
    return (
        <>
            <TableHeader headers={headers.adminLogs} />
            <div className="table_data">
                {filteredUsers &&
                    filteredUsers.map((u, index) => (
                        <div
                            key={index}
                            onClick={() => setID(u.id)}
                            className={`table_data_item ${
                                id === u.id ? "white-space" : ""
                            }`}
                        >
                            <div className="flex-1">{date_time(u.time)}</div>
                            <div className="flex-1">
                                <span
                                    className="pointer"
                                    onClick={() =>
                                        history.push(
                                            `/dashboard/profile/${u.user}`
                                        )
                                    }
                                >
                                    {u.user}
                                </span>
                            </div>

                            <div className="flex-1">{u.log_message}</div>

                            <div style={{ flex: 1.7 }}>{u.ip_address}</div>
                        </div>
                    ))}
            </div>
        </>
    );
}

export default AdminLogs;
