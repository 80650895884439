import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    GET_ANNOUNCEMENTS,
    SEARCH,
    api,
    searchData,
    date_time,
    SmLoading,
    NOTIFICATIONS,
} from "../../";
import orderBy from "lodash/orderBy";

function Announcements() {
    const dispatch = useDispatch();
    const [announcements, search] = useSelector((state) => [
        state.announcements,
        state.search,
    ]);
    const [loading, setLoading] = useState(false);
    const sortedAnnouncements = orderBy(
        announcements,
        ["announcement_time"],
        ["desc"]
    );
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                dispatch({
                    type: NOTIFICATIONS,
                    data: [],
                });
                let res = await api.getAnnouncement();
                if (res.data.announcements)
                    dispatch({
                        type: GET_ANNOUNCEMENTS,
                        data: res.data.announcements,
                    });
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "announcement",
                icon: true,
            },
        });

        return () => {
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
        };
    }, [dispatch]);

    var filtered = [];
    if (sortedAnnouncements)
        filtered = searchData(search.value, sortedAnnouncements);

    return loading ? (
        <SmLoading />
    ) : (
        <div className="m_announcement_data">
            {filtered && filtered.length === 0 && (
                <div className="m_card__container text-center">
                    <h4>No Announcement</h4>
                </div>
            )}
            {filtered &&
                filtered.map((a, index) => (
                    <div key={index} className="m_card__container">
                        <h5 className="m_mb-2">{a.title}</h5>
                        <h4 style={{ fontSize: "1.2rem" }}>{a.message}</h4>
                        <h6 className="m_announcement_date">
                            {date_time(a.announcement_time)}
                        </h6>
                    </div>
                ))}
        </div>
    );
}

export default Announcements;
