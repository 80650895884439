import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    GET_ANNOUNCEMENTS,
    SEARCH,
    Pagination,
    paginate,
    api,
    searchData,
    date_time,
    SmLoading,
    NOTIFICATIONS,
} from "../../";
import orderBy from "lodash/orderBy";
const pageSize = 6;
function Announcements() {
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const [announcements, search] = useSelector((state) => [
        state.announcements,
        state.search,
    ]);
    const [loading, setLoading] = useState(false);
    const sortedAnnouncements = orderBy(
        announcements,
        ["announcement_time"],
        ["desc"]
    );
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                dispatch({
                    type: NOTIFICATIONS,
                    data: [],
                });
                let res = await api.getAnnouncement();
                if (res.data.announcements)
                    dispatch({
                        type: GET_ANNOUNCEMENTS,
                        data: res.data.announcements,
                    });
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "search announcement",
            },
        });

        return () => {
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
        };
    }, [dispatch]);

    let paginateItems = paginate(sortedAnnouncements, currentPage, pageSize);
    const totalCount = announcements.length;
    function handlePageChange(page, pages, action = "default") {
        const newPage = currentPage + page - (action === "jump" && currentPage);
        if (newPage > 0 && newPage <= pages) setCurrentPage(newPage);
    }
    var filtered = [];
    if (paginateItems) filtered = searchData(search.value, paginateItems);

    return (
        <>
            <div className="announcement_data">
                {filtered && filtered.length === 0 && (
                    <div className="text-center">
                        <h1>No Announcement</h1>
                    </div>
                )}
                {filtered &&
                    filtered.map((a, index) => (
                        <div
                            key={index}
                            className="card__container announcements_card"
                        >
                            <h2 className="mb-2">{a.title}</h2>
                            <h3>{a.message}</h3>
                            <h4 className="announcement_date">
                                {date_time(a.announcement_time)}
                            </h4>
                        </div>
                    ))}
            </div>

            {loading ? (
                <SmLoading />
            ) : (
                <Pagination
                    itemsCount={totalCount}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    property="title"
                />
            )}
        </>
    );
}

export default Announcements;
