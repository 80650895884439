import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, GET_USERS_LOGS, searchData, Tooltips } from "../..";

import { useHistory } from "react-router";

function SubscriptionLogs({ search }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userLogs = useSelector((state) => state.userLogs);
  const [id, setID] = useState("");

  const filteredUsers = searchData(search, userLogs);
  useEffect(() => {
    async function fetch() {
      try {
        const res = await api.getSubscriptions();
        let logs = res.data.subscriptions;
        dispatch({
          type: GET_USERS_LOGS,
          data: logs,
        });
      } finally {
      }
    }
    fetch();
  }, [dispatch]);

  return (
    <div className="m_table_data">
      {filteredUsers &&
        filteredUsers.map((u, index) => (
          <div
            key={index}
            onClick={() => setID(u.id)}
            className={`m_table_data_item ${id === u.id ? "white-space" : ""}`}
          >
            <div className="m_log_row">
              <span
                className="truncate text-left m_flex-1"
                onClick={() => history.push(`/dashboard/profile/${u.username}`)}
              >
                {u.username}
              </span>
              <Tooltips
                title={u.plan_name}
                className="truncate text-right m_flex-1"
              >
                {u.plan_name}
              </Tooltips>
            </div>
            <div className="m_log_row">
              <div className="m_flex-1">
                <h6>{u.order_id}</h6>
              </div>
              <Tooltips
                title={u.payment_status}
                className="truncate m_flex-1 text-right"
                style={{
                  fontSize: "1rem",
                  color: "var(--text_color_5)",
                }}
              >
                {u.payment_status}
              </Tooltips>
            </div>
          </div>
        ))}
    </div>
  );
}

export default SubscriptionLogs;
