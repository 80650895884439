import React from "react";

function RefundAndCancellationPolicy() {
  return (
    <div
      className="markdown_content_container privacy_terms"
      style={{ minHeight: "100vh" }}
    >
      <div className="markdown_content blur_bg">
        <h2>Refund and Cancellation Policy</h2>
        <h5 className="mt-1">
          Our focus is complete customer satisfaction. In the event, if you are
          displeased with the services provided, we will refund back the money,
          provided the reasons are genuine and proved after investigation.
          Please read the fine prints of each deal before buying it, it provides
          all the details about the services or the product you purchase.
        </h5>
        <h5 className="mt-1">
          In case of dissatisfaction with our services, clients have the liberty
          to cancel their projects and request a refund from us. Our Policy for
          the cancellation and refund will be as follows:
        </h5>
        <h3>Cancellation Policy</h3>
        <h5 className="mt-1">For Cancellations please contact us.</h5>
        <h5 className="mt-1">
          Requests received later than 3 business days prior to the end of the
          current service period will be treated as cancellation of services for
          the next service period.
        </h5>
        <h3>Refund Policy</h3>
        <h5 className="mt-1">
          We will try our best to create suitable design concepts for our
          clients.
        </h5>
        <h5 className="mt-1">
          In case any client is not completely satisfied with our services we
          can provide a refund under the following terms
        </h5>
        <h5 style={{ marginLeft: 20 }}>
          &#x2022; Refund shall be provided within 3 days of purchase.
        </h5>
        <h5 style={{ marginLeft: 20 }}>
          &#x2022; Refund shall be cancelled if such refund is requested by
          candidates who have reached their third module.
        </h5>
        <h5 className="mt-2">
          If paid by credit card, refunds will be issued to the original credit
          card provided at the time of purchase and in case of payment gateway
          name payments refund will be made to the same account.
        </h5>
      </div>
    </div>
  );
}

export default RefundAndCancellationPolicy;
