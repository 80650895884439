import CloseIcon from "@material-ui/icons/Close";
import React from "react";
function Toggle({ children, handleToggle, classes, show = true }) {
    return (
        <div className={`toggle ${classes ? classes : ""}`}>
            <div className="toggle-container">
                <div className="toggle__card animate__fadeInUp" id={classes}>
                    {show && (
                        <div className="close">
                            <div onClick={handleToggle} className="icon">
                                <CloseIcon />
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Toggle;
