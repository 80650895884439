import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useState } from "react";
import { ALERT } from "../../";
import { useDispatch } from "react-redux";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
function TeamProfile({
    rank,
    points,
    challenges_solved,
    team_name,
    team_members,
    team,
    total_challenges,
    show_rank,
    handleToggle,
}) {
    const dispatch = useDispatch(0);
    var c = 0;
    var t = 0;
    if (challenges_solved) {
        c = challenges_solved;
    }
    if (total_challenges) {
        t = total_challenges;
    }
    const perChallenges = `${c <= 9 ? "0" + c : c}/${t <= 9 ? "0" + t : t}`;
    const handleCopy = () => {
        navigator.clipboard.writeText(team.teamtoken);
        dispatch({
            type: ALERT,
            data: {
                open: true,
                message: "Copied",
                type: "success",
            },
        });
    };
    const [hide, setHide] = useState(true);
    return (
        <div className="card__container mb-2">
            <div className="responsive_profile">
                <div className="d-flex">
                    <div
                        style={{ lineHeight: "3rem" }}
                        className="d-flex flex-column"
                    >
                        <div className="d-flex align-items-center">
                            <h2>{team_name}</h2>
                            <div
                                onClick={handleToggle}
                                className="icon icon__primary ml-1"
                            >
                                <CreateOutlinedIcon />
                            </div>
                        </div>
                        <h4>Team members: {team_members} </h4>
                        <div className="d-flex align-items-center">
                            {team.teamname === team_name && (
                                <>
                                    <h4 onClick={() => setHide(!hide)}>
                                        Team Token:{" "}
                                        {hide
                                            ? "*********************************************"
                                            : team.teamtoken}
                                    </h4>
                                    <div
                                        onClick={handleCopy}
                                        className="icon icon__primary ml-1"
                                    >
                                        <FileCopyOutlinedIcon />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    {show_rank && (
                        <div className="statistics">
                            <h1>
                                <strong>
                                    #{rank <= 9 ? "0" + rank : rank || "00"}
                                </strong>
                            </h1>
                            <div style={{ justifySelf: "flex-end" }}>
                                <h4>Rank</h4>
                            </div>
                        </div>
                    )}
                    <div className="statistics ml-2">
                        <h1>
                            <strong>
                                {points <= 9 ? "0" + points : points || "00"}
                            </strong>
                        </h1>
                        <div style={{ justifySelf: "flex-end" }}>
                            <h4>Points</h4>
                        </div>
                    </div>
                    <div className="statistics ml-2">
                        <h1>
                            <strong>{perChallenges}</strong>
                        </h1>
                        <div style={{ justifySelf: "flex-end" }}>
                            <h4>Challenges Solved</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamProfile;
