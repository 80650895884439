import { useState, useEffect } from "react";
import hard from "../../assets/hard.png";
import medium from "../../assets/medium.png";
import easy from "../../assets/easy.png";
import ChallengeDetail from "./ChallengeDetail";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LockOutlined from "@material-ui/icons/LockOutlined";
import {
  api,
  Toggle,
  Pagination,
  paginate,
  searchData,
  SEARCH,
  SELECTED_CATEGORY,
  ToolTip,
  SmLoading,
} from "../../";
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";
import orderBy from "lodash/orderBy";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Congratulation from "./Congratulation";
import ErrorFlag from "./ErrorFlag";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Locked from "./Locked";
const pageSize = 8;
function SelectedChallenges({ site_key }) {
  const dispatch = useDispatch();
  var { category } = useParams();
  const [challenge, setChallenge] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState("");
  const [solve, setSolve] = useState("");
  const [loading, setLoading] = useState(true);
  const [ID, setID] = useState("");
  const [locked, setLocked] = useState(false);
  const [app, user_challenges, solves, search, config] = useSelector(
    (state) => [
      state.app,
      state.user_challenges,
      state.solves,
      state.search,
      state.config,
    ]
  );
  useEffect(() => {
    setCurrentPage(1);
    dispatch({
      type: SELECTED_CATEGORY,
      data: category,
    });
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "search challenge",
      },
    });

    return () => {
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
    };
  }, [dispatch, category, user_challenges]);

  const filteredChallenges = user_challenges.filter(
    (c) => c.category.trim() === category.trim()
  );
  var searchChallenges = searchData(search.value, filteredChallenges);

  let paginateChallenges = paginate(searchChallenges, currentPage, pageSize);
  const totalCount = filteredChallenges.length;
  function handlePageChange(page, pages, action = "default") {
    const newPage = currentPage + page - (action === "jump" && currentPage);
    if (newPage > 0 && newPage <= pages) setCurrentPage(newPage);
  }
  var filtered = orderBy(
    paginateChallenges,
    [(s) => s.name.toLowerCase()],
    ["asc"]
  );

  const handleChallenge = async (id) => {
    setLoading(true);
    setID(id);
    try {
      let res = await api.solve(id);
      const solves = res.data.all_solves;
      if (res.status === 200) {
        setSolve(solves);
      }
      res = await api.getChallenge(id);
      setChallenge(res.data);
    } catch (err) {
    } finally {
      setLoading(false);
      setID("");
    }
  };
  const handleToggle = () => {
    setToggle("");
  };
  function isContainerChallengeId(id) {
    return solves.find((s) => s.cid === id);
  }
  function isContainerUser(id) {
    if (app && app.profile)
      return solves.find(
        (s) => s.cid === id && s.user === app.profile.username
      );
    return false;
  }
  function getDefault(difficulty) {
    if (difficulty.toLowerCase() === "hard") {
      return hard;
    } else if (difficulty.toLowerCase() === "medium") {
      return medium;
    } else {
      return easy;
    }
  }

  function handleLockedChallenge(c) {
    if (c.locked) {
      return setLocked(true);
    }
    handleChallenge(c.id);
  }
  function handleLockedToggle() {
    setLocked(!locked);
  }
  return (
    <>
      {toggle === "congratulation" && (
        <Congratulation handleToggle={handleToggle} />
      )}
      {locked && <Locked handleToggle={handleLockedToggle} />}
      {toggle && toggle !== "congratulation" && (
        <ErrorFlag handleToggle={handleToggle} toggle={toggle} />
      )}
      {challenge && (
        <Toggle handleToggle={() => setChallenge("")} classes="challenge_page_">
          <GoogleReCaptchaProvider reCaptchaKey={site_key}>
            <ChallengeDetail
              site_key={site_key}
              setToggle={setToggle}
              handleMainToggle={() => setChallenge("")}
              challenge={challenge}
              solve={solve}
            />
          </GoogleReCaptchaProvider>
        </Toggle>
      )}

      <div className="challenge_page">
        {filtered.length === 0 ? (
          <div className="text-center">
            <h5>
              <strong>No Challenge</strong>
            </h5>
          </div>
        ) : (
          filtered &&
          filtered.map((c) => (
            <div
              key={c.id}
              onClick={() => handleLockedChallenge(c)}
              className="card__container"
            >
              <div className="card__primary">
                <div className="d-flex">
                  <div className="card_img">
                    <img
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = getDefault(c.difficulty);
                      }}
                      className="challenge__img mr-1"
                      src={c.challenge_logo || getDefault(c.difficulty)}
                      alt=""
                    />
                  </div>
                  <div className="flex-1">
                    <ToolTip n={35} item={c.name} toggle_id="challenge_page_">
                      <div className="card__heading mb-1">{c.name}</div>
                    </ToolTip>
                    <div className="card_p card_p_lg">
                      <button
                        style={{
                          backgroundColor:
                            c.difficulty.toLowerCase() === "easy"
                              ? "var(--blue)"
                              : c.difficulty.toLowerCase() === "medium"
                              ? "var(--yellow)"
                              : "var(--red)",
                        }}
                        className="btn  mr-1"
                      >
                        {c.difficulty}
                      </button>
                      <div className="icon">
                        <OfflineBoltOutlinedIcon
                          style={{
                            color: "var(--yellow)",
                            marginRight: "0.5rem",
                          }}
                        />
                      </div>
                      {c.points}
                    </div>
                    {loading && c.id === ID && (
                      <div className="challenge_sm_loading">
                        <SmLoading />
                      </div>
                    )}
                    {c.locked && config.plan_mode && (
                      <div className="locked_challenge">
                        <LockOutlined />
                      </div>
                    )}
                    {solves && isContainerChallengeId(c.id) && (
                      <div className="tick icon">
                        <CheckCircleOutlineIcon
                          style={{
                            color: `var(--${
                              isContainerUser(c.id) ? "green" : "text_color_5"
                            })`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Pagination
        itemsCount={totalCount}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </>
  );
}

export default SelectedChallenges;
