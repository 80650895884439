import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  headers,
  Toggle,
  api,
  PaginationComponent,
  SEARCH,
  SmLoading,
  GET_PLANS,
} from "../../";
import AddPlan from "./AddPlan";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Route, Switch, useHistory } from "react-router-dom";
const TableBody = ({ paginateItems, setPlan }) => {
  const [cid, setCID] = useState(null);
  const history = useHistory();
  function handlePlan(plan) {
    setPlan(plan);
    history.push(`/dashboard/plans/update`);
  }
  return (
    <div className="m_table_data">
      {paginateItems &&
        paginateItems.map((plan, index) => (
          <div
            key={index}
            onClick={() => setCID(plan.id)}
            className={`m_table_data_item ${
              cid === plan.id ? "white-space" : ""
            }`}
          >
            <div className="m_flex-1">{plan.name}</div>{" "}
            <div className="m_flex-1">${plan.price}</div>
            <div className="m_flex-1">{plan.days}</div>
            <div
              style={{ flex: 0.1 }}
              onClick={() => handlePlan(plan)}
              className="m_icon m_icon_light m_d-flex m_justify-content-end"
            >
              <ArrowForwardIosOutlinedIcon />
            </div>
          </div>
        ))}
    </div>
  );
};

function Plans() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [plans] = useSelector((state) => [state.plans]);
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const handleToggle = () => setToggle(!toggle);

  useEffect(() => {
    async function fetchPlans() {
      setLoading(true);
      try {
        let res = await api.getPlans();
        let data = res.data.plans;
        dispatch({
          type: GET_PLANS,
          data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchPlans();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "plans",
        setting: false,
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);

  return (
    <div className="m_plans">
      {toggle && (
        <Toggle classes="plansPanel" handleToggle={handleToggle}>
          <AddPlan toggle_id={"plansPanel"} handleToggle={handleToggle} />
        </Toggle>
      )}
      {loading ? (
        <SmLoading />
      ) : (
        <Switch>
          <Route path="/dashboard/plans/update">
            <AddPlan type="update" plan={plan} />
          </Route>
          <Route path="/dashboard/plans/add">
            <AddPlan />
          </Route>
          <Route exact path="/dashboard/plans">
            <PaginationComponent
              heading="PLANS"
              headers={headers.plans}
              items={plans}
              TableBody={(items) => (
                <TableBody paginateItems={items} setPlan={setPlan} />
              )}
              handleToggle={handleToggle}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default Plans;
