import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";

import Login from "./Login";
import Register from "./Register";
// import default_event from "../../assets/default-event.png";
import { LOADING, GET_CONFIG, api, Routes } from "../../";
import logo from "./logo.png";

// import { Navbar } from "../../";

import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import RecaptchWrapper from "../../components/RecaptchWrapper";

function Auth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [site_key, setSiteKey] = useState("");
  //   const [config] = useSelector((state) => [state.config, state.loading]);
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          var res = await api.getSiteKey();
          setSiteKey(res.data.site_key);
        } catch (err) {}
        try {
          res = await api.getStatus();
          if (res.data.status !== "ok") {
            history.push("/auth/setup");
          } else {
            sessionStorage.setItem("setup", "true");
          }
        } catch (err) {}
        res = await api.getConfig();
        dispatch({
          type: GET_CONFIG,
          data: res.data.config,
        });
        await api.getUserStatus();
        history.push(Routes.challenge);
      } catch (err) {
        dispatch({
          type: LOADING,
        });
      }
    }

    fetch();
  }, [dispatch, history]);

  if (site_key) {
    return (
      <RecaptchWrapper site_key={site_key}>
        {/* <Navbar /> */}
        <div className="container center">
          {/* <div className="text-center">
          <img
            className="login_logo"
            src={config.event_logo || default_event}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_event;
            }}
          />
        </div> */}
          <div className="auth__container mb-2 pentest-auth-container">
            <div className="auth">
              <div className="auth__inputs">
                <div className="center mt-1">
                  <img
                    src={logo}
                    style={{ width: "90%", objectFit: "contain" }}
                    alt="logo"
                  />
                </div>
                <Switch>
                  <Route path="/auth/login">
                    <Login site_key={site_key} />
                  </Route>
                  <Route path="/auth/forgotpassword">
                    <ForgotPassword site_key={site_key} />
                  </Route>
                  <Route path="/auth/resetpassword/:userid/:secret_token">
                    <ResetPassword site_key={site_key} />
                  </Route>
                  <Route path="/auth/register">
                    <Register endpoint="register" site_key={site_key} />
                  </Route>
                  <Route path="/auth/setup">
                    <Register endpoint="setup" />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </RecaptchWrapper>
    );
  }
  return (
    <>
      {/* <Navbar /> */}
      <div className="container center">
        {/* <div className="text-center">
          <img
            className="login_logo"
            src={config.event_logo || default_event}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_event;
            }}
          />
        </div> */}
        <div className="auth__container mb-2 pentest-auth-container">
          <div className="auth">
            <div className="auth__inputs">
              <div className="center mt-1">
                <img
                  src={logo}
                  style={{ width: "90%", objectFit: "contain" }}
                  alt="logo"
                />
              </div>
              <Switch>
                <Route path="/auth/login">
                  <Login site_key={site_key} />
                </Route>
                <Route path="/auth/forgotpassword">
                  <ForgotPassword site_key={site_key} />
                </Route>
                <Route path="/auth/resetpassword/:userid/:secret_token">
                  <ResetPassword site_key={site_key} />
                </Route>
                <Route path="/auth/register">
                  <Register endpoint="register" site_key={site_key} />
                </Route>
                <Route path="/auth/setup">
                  <Register endpoint="setup" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
