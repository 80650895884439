import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SETTING, LOADING, api, Input, InputPassword, ALERT } from "../../";
import { useHistory } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import key from "./key.png";
// import { Switch } from "@material-ui/core";
// import styled from "styled-components";
function Login({ site_key }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  async function handleLogin() {
    if (username === "" || password === "") {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `${
            username === ""
              ? "Username"
              : "" || password === ""
              ? "Password"
              : ""
          } is required`,
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: LOADING,
    });
    try {
      var data = {
        username,
        password,
      };
      if (site_key) {
        const g_recaptcha_response = await executeRecaptcha("login");
        data = { ...data, g_recaptcha_response };
      }
      let res = await api.login(data);
      if (res.status === 200) {
        history.push(`/dashboard/categories`);
      }
    } catch (error) {
      const err = error.response && error.response.data.status;
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  useEffect(() => {
    const newApp = {};
    newApp.dashboard = true;
    dispatch({
      type: SETTING,
      data: newApp,
    });
  }, [dispatch]);
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <>
      <div className="m_login-separater">
        <span>Login</span>
        <hr />
      </div>
      <Input
        label="email / username"
        placeholder="email / username"
        value={username}
        onChange={setUserName}
        onKeyDown={handleKeyDown}
      />
      <InputPassword
        label="password"
        value={password}
        onChange={setPassword}
        placeholder="password"
        onKeyDown={handleKeyDown}
      />
      <div className="d-flex justify-content-end align-items-center">
        {/* <h5
          className="mb-1 d-flex justify-content-between align-items-center"
          style={{ gap: "7px" }}
        >
          <IOSSwitch defaultChecked color="default" />
          <strong>
            <strong>Remember Me</strong>
          </strong>
        </h5> */}
        <h5 className="mb-1 d-flex justify-content-between align-items-center">
          <img src={key} style={{ width: "30px", height: "30px" }} alt="key" />
          <strong>
            <Link to="/auth/forgotpassword">Forgot password?</Link>
          </strong>
        </h5>
      </div>
      <button onClick={handleLogin} className="m_btn m_btn--medium mv-1">
        Login
      </button>
      <hr />
      <div className="center">
        <h5>
          Don't have an account?
          <strong>
            <Link to="/auth/register">&nbsp;Sign up</Link>
          </strong>
        </h5>
      </div>
      {/* <div className="m_text-center">
                <button
                    onClick={handleLogin}
                    className="m_btn m_btn--medium mv-1"
                >
                    Login
                </button>
                <h5 className="mt-1 mb-1">
                    <strong>
                        <Link to="/auth/forgotpassword">Forgot password?</Link>
                    </strong>
                </h5>
                <h5>
                    Not a member?
                    <strong>
                        <Link to="/auth/register">&nbsp;Register</Link>
                    </strong>
                </h5>
            </div> */}
    </>
  );
}

export default Login;

// const IOSSwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 32,
//   height: 16,
//   padding: 0,
//   border: "1px solid var(--text_color)",
//   borderRadius: 30,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor:
//           theme?.palette?.mode === "dark" ? "#2ECA45" : "var(--light)",
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme?.palette?.mode === "light"
//           ? theme?.palette?.grey[100]
//           : theme?.palette?.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme?.palette?.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 10,
//     height: 10,
//   },
//   "& .MuiSwitch-track": {
//     opacity: 1,
//     transition: theme?.transitions?.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));
