import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    api,
    InputFile,
    GET_IMAGE_FILES,
    GET_CHALLENGE_FILES,
    LOADING,
} from "../../";

function NewFileUpload({ endpoint, handleToggle }) {
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    async function uploadFile() {
        dispatch({
            type: LOADING,
            data: true,
        });
        try {
            let res = await api[endpoint](file);
            if (res && res.status === 200) {
                api.getImageFiles().then((res) => {
                    dispatch({
                        type: GET_IMAGE_FILES,
                        data: res.data.files,
                    });
                });
                res = await api.getChallengeFiles();
                dispatch({
                    type: GET_CHALLENGE_FILES,
                    data: res.data.files,
                });
                handleToggle();
            }
        } catch (err) {
        } finally {
            dispatch({
                type: LOADING,
                data: true,
            });
        }
    }
    return (
        <InputFile
            label="FILE UPLOAD"
            file={file}
            onChange={setFile}
            onClick={uploadFile}
            btn="Upload"
        />
    );
}

export default NewFileUpload;
