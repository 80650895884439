import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { TableHeading } from "../../components";

function MyPlan({ userStat }) {
  const config = useSelector((state) => state.config);
  if (!userStat.plan || !config.plan_mode) return null;
  return (
    <div className="card__container mb-2">
      <TableHeading heading={"My Plans"} />
      <div className="responsive_profile mt-1">
        <div className="d-flex flex-column">
          <h2>
            <strong>Current Plan </strong>: {userStat.plan}
          </h2>
          {userStat.subscribed_since && (
            <h3 style={{ marginTop: "8px" }}>
              <strong>Subscribed Since : </strong>
              {moment(userStat.subscribed_since * 1000).format("DD/MM/YYYY")}
            </h3>
          )}
          {userStat.subscribed_until && (
            <h3 style={{ marginTop: "5px" }}>
              <strong>Subscribed Until : </strong>
              <span>
                {moment(userStat.subscribed_until * 1000).format("DD/MM/YYYY")}
              </span>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyPlan;
