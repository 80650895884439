import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {
  headers,
  Toggle,
  api,
  PaginationComponent,
  SEARCH,
  ALERT,
  SmLoading,
  GET_PLANS,
} from "../../";
import AddPlan from "./AddPlan";
import PlanChallenges from "./PlanChallenges";
import { OutlinedFlag } from "@material-ui/icons";
const TableBody = ({ paginateItems }) => {
  const dispatch = useDispatch();

  const [cid, setCID] = useState(null);
  const [plan, setPlan] = useState(null);
  const [deletePlan, setDeletePlan] = useState(null);
  const [planChallengId, setPlanChallengId] = useState(null);

  async function handleInfo(e, id) {
    e.stopPropagation();
    setPlanChallengId(id);
  }

  async function deleteThePlan() {
    try {
      await api.deletePlan(deletePlan.id);
      setDeletePlan(null);
      let res = await api.getPlans();
      let data = res.data.plans;
      dispatch({
        type: GET_PLANS,
        data,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Plan deleted",
          type: "error",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err?.response?.data?.status || "Failed to delete Plan",
          type: "error",
        },
      });
    }
  }

  return (
    <>
      {deletePlan && (
        <Toggle handleToggle={() => setDeletePlan(null)}>
          <div className="mt-2">
            <h4>Are you sure you want to delete `{deletePlan.name}` ??</h4>
            <div className="d-flex justify-content-end mt-2">
              <button
                className="btn btn--medium"
                onClick={() => setDeletePlan(null)}
              >
                Cancel
              </button>
              <button
                className="btn btn--medium ml-2 danger"
                onClick={deleteThePlan}
              >
                Delete
              </button>
            </div>
          </div>
        </Toggle>
      )}
      {plan && (
        <Toggle classes="plansPanel" handleToggle={() => setPlan(null)}>
          <AddPlan
            toggle_id={"plansPanel"}
            plan={plan}
            type="update"
            handleToggle={() => setPlan(null)}
          />
        </Toggle>
      )}

      {planChallengId && (
        <Toggle
          classes="planChallenges"
          handleToggle={() => setPlanChallengId(null)}
        >
          <PlanChallenges
            toggle_id={"planChallenges"}
            planChallengId={planChallengId}
            handleToggle={() => setPlanChallengId(null)}
          />
        </Toggle>
      )}

      <div className="table_data">
        {paginateItems &&
          paginateItems.map((plan, index) => (
            <div
              key={index}
              onClick={() => setCID(plan.id)}
              className={`table_data_item ${
                cid === plan.id ? "white-space" : ""
              }`}
            >
              <div style={{ flex: 0.5 }}>{plan.name}</div>
              <div className="flex-1">{plan.description}</div>
              <div style={{ flex: 0.3 }}>${plan.price}</div>
              <div style={{ flex: 0.3 }}>
                {plan.enabled ? "Enabled" : "Disabled"}
              </div>
              <div style={{ flex: 0.3 }}>{plan.days}</div>

              <div
                style={{
                  flex: 0.3,
                }}
                className="d-flex justify-content-center flex-1"
              >
                <div
                  onClick={(e) => handleInfo(e, plan.id)}
                  className="icon__primary"
                >
                  <OutlinedFlag />
                </div>

                <div onClick={() => setPlan(plan)} className="icon__primary">
                  <CreateOutlinedIcon />
                </div>
                <div
                  onClick={() => setDeletePlan(plan)}
                  className="icon__primary"
                >
                  <DeleteOutlinedIcon />
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

function Plans() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [plans] = useSelector((state) => [state.plans]);

  const [loading, setLoading] = useState(false);
  const handleToggle = () => setToggle(!toggle);

  useEffect(() => {
    async function fetchPlans() {
      setLoading(true);
      try {
        let res = await api.getPlans();
        let data = res.data.plans;
        dispatch({
          type: GET_PLANS,
          data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchPlans();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "search plans",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);

  return (
    <div className="plans">
      {toggle && (
        <Toggle classes="plansPanel" handleToggle={handleToggle}>
          <AddPlan toggle_id={"plansPanel"} handleToggle={handleToggle} />
        </Toggle>
      )}
      {loading ? (
        <SmLoading />
      ) : (
        <PaginationComponent
          heading="PLANS"
          headers={headers.plans}
          items={plans}
          pageSize={18}
          TableBody={TableBody}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
}

export default Plans;
