import React from "react";

import { useHistory } from "react-router";
import not_found from "../assets/404.png";
function NoMatch({ height }) {
    const history = useHistory();
    return (
        <div
            className="m_not-found_container"
            style={{ height: height + 54 + 64 }}
        >
            <div className="m_not_found_img">
                <img src={not_found} alt="" />
            </div>
            <div className="m_text">
                <h2>404</h2>
                <h3>Why am I here?</h3>
                <h4>
                    It seems like the page you’re trying to reach is either
                    unavailable or under construction.
                </h4>
                <div
                    onClick={() => history.push("/")}
                    className="m_btn m_not_found_btn mt-2"
                >
                    Go Home
                </div>
            </div>
        </div>
    );
}

export default NoMatch;
