import { NavLink } from "react-router-dom";
import NavItem from "./NavItem";
export default function Nav({ nav, hide, className, onClick, toggle_id }) {
    if (nav.endpoint) {
        return (
            <NavLink to={`${nav.endpoint}`} activeClassName="selected">
                <NavItem
                    nav={nav}
                    hide={hide}
                    className={className}
                    toggle_id={toggle_id}
                />
            </NavLink>
        );
    } else {
        return (
            <div onClick={onClick}>
                <NavItem
                    nav={nav}
                    hide={hide}
                    className={className}
                    toggle_id={toggle_id}
                />
            </div>
        );
    }
}
