import React from "react";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { Loading } from "../../components";
import default_logo from "../../assets/default-company.png";
import default_event from "../../assets/default-event.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import { Routes } from "../../utils";
import RecaptchWrapper from "../../components/RecaptchWrapper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ALERT } from "../../store";
function Plan({ plan, site_key }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  async function subscribePlan() {
    try {
      let payload = { plan_id: plan.id };
      if (site_key) {
        const g_recaptcha_response = await executeRecaptcha("subscribe_plan");
        payload = { ...payload, g_recaptcha_response };
      }
      const res = await api.subscribePlan(payload);
      const options = res.data.payment_options;
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      if (err?.response?.status === 401) {
        history.push(Routes.login);
        return;
      }
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err?.message,
          type: "error",
        },
      });
    }
  }
  return (
    <div className="plan_card">
      <div className="plan_header">
        <h3>{plan.name}</h3>
        {plan.image && <img src={plan.image} alt="plan" />}
        <h4>
          <span style={{ fontSize: "40px" }}>${plan.price}</span> /{" "}
          <span>{plan.days}</span>{" "}
          <span style={{ fontSize: "10px", opacity: 0.7 }}>days</span>
        </h4>
      </div>
      <div className="plan_details">
        <div className="plan_description">
          <h4>
            <MDEditor.Markdown source={plan.description} />
          </h4>
        </div>
        <div className="center">
          <button className="btn btn--medium mt-1" onClick={subscribePlan}>
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
}
function AllPlans({ site_key }) {
  const history = useHistory();
  const [planLoading, setPlanLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  let [config, loading] = useSelector((state) => [state.config, state.loading]);
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  useEffect(() => {
    async function getUserPlans() {
      try {
        setPlanLoading(true);
        const res = await api.getPlansWithoutUser();
        const data = res?.data?.plans;
        if (data?.length) {
          setPlans(data);
        } else {
          history.replace(Routes.notfound);
        }
      } catch (error) {
        history.replace(Routes.notfound);
      } finally {
        setPlanLoading(false);
      }
    }
    getUserPlans();
  }, [history]);
  if (loading || planLoading) return <Loading />;

  return (
    <div
      className="event_homepage"
      style={{ height: "100%", overflow: "scroll" }}
    >
      <div className="navbar">
        <div onClick={() => history.push("/")} className="navbar_logo">
          <div className="nav_logo">
            <img
              src={config.company_logo || default_logo}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = default_logo;
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="center"
        style={{ padding: "40px", flexDirection: "column" }}
      >
        <div className="text-center">
          <img
            className="login_logo"
            src={config.event_logo || default_event}
            alt=""
            style={{ marginBottom: "4rem" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_event;
            }}
          />
        </div>
        <div className="plans_card_container">
          {!planLoading && plans?.length && (
            <div className="flex-column" style={{ width: "100%" }}>
              <div className="center">
                <h2>Choose your plan</h2>
              </div>
              <div className="center flex-column" style={{ margin: "30px 0" }}>
                <h1>Enhance Your Daily PTG</h1>
                <h1>Experience with premium plans</h1>
              </div>
              <div className="plans_container">
                {plans.map((plan) => (
                  <Plan key={plan.id} plan={plan} site_key={site_key} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SubscribePlans(props) {
  const [site_key, setSiteKey] = useState("");
  useEffect(() => {
    async function getSiteKey() {
      try {
        let res = await api.getSiteKey();
        setSiteKey(res.data.site_key);
      } catch (err) {}
    }
    getSiteKey();
  }, []);

  if (site_key) {
    return (
      <RecaptchWrapper site_key={site_key}>
        <AllPlans site_key={site_key} />
      </RecaptchWrapper>
    );
  }
  return <AllPlans />;
}

export default SubscribePlans;
