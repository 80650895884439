import React from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { gravatar } from "../../";
import { useHistory } from "react-router";
function Profile({
  showEdit,
  profile,
  points,
  challenges_solved,
  total_challenges,
}) {
  const history = useHistory();
  var c = 0;
  var t = 0;
  if (challenges_solved) {
    c = challenges_solved;
  }
  if (total_challenges) {
    t = total_challenges;
  }
  const perChallenges = `${c <= 9 ? "0" + c : c}/${t <= 9 ? "0" + t : t}`;
  return (
    <div className="card__container mb-2">
      <div className="responsive_profile">
        <div className="d-flex">
          <div className="setting_profile_avatar">
            <img
              style={{ width: "11rem", height: "11rem" }}
              src={gravatar(profile.gravatar_hash)}
              alt=""
            />

            {showEdit && (
              <div
                onClick={() => window.open("https://en.gravatar.com/")}
                className="icon icon__primary"
              >
                <EditOutlinedIcon />
              </div>
            )}
          </div>
          <div className="setting_profile ml-1">
            <h2>{profile.username}</h2>
            {profile.teamname !== "NA" && (
              <h4
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push(`/dashboard/teamstats/${profile.teamname}`)
                }
              >
                {profile.teamname}
              </h4>
            )}
            <h4>{profile.email}</h4>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="statistics">
            <h1>
              <strong>{points <= 9 ? "0" + points : points || "00"}</strong>
            </h1>
            <div style={{ justifySelf: "flex-end" }}>
              <h4>Points</h4>
            </div>
          </div>
          <div className="statistics ml-2">
            <h1>
              <strong>{perChallenges}</strong>
            </h1>
            <div style={{ justifySelf: "flex-end" }}>
              <h4>Challenges Solved</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
