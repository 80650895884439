import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import Input from "./Input";
function ColorPickInput({ onChange, value, label }) {
    const [togglePicker, setTogglePicker] = useState("");
    const [color, setColor] = useState(value);
    const [rgb, setRgb] = useState(hexToRgb(value));
    function handlePicker() {
        setTogglePicker(!togglePicker);
    }
    function hexToRgb() {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
            c = color.substring(1).split("");
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = "0x" + c.join("");
            const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255];
            return rgb;
        }
    }
    const handleChange = (color) => {
        setColor(color);
        setRgb(hexToRgb(color));
    };
    function handleConfirm() {
        onChange(color);
        handlePicker();
    }
    return (
        <>
            <div className="m_color-picker-container">
                <Input
                    label={label}
                    placeholder="Choose Color"
                    value={value}
                    onChange={handleChange}
                    name="accent_color"
                >
                    <div
                        onClick={handlePicker}
                        style={{ background: value }}
                        className="m_color-display"
                    />
                </Input>
            </div>
            {togglePicker && (
                <div className="m_toggle">
                    <div className="m_toggle-container">
                        <div className="m_toggle_card animate__fadeInUp">
                            <div className="m_color-picker">
                                <div
                                    style={{ background: color }}
                                    className="m_preview"
                                >
                                    <h3>Preview</h3>
                                </div>
                                <HexColorPicker
                                    color={color}
                                    onChange={handleChange}
                                />
                                <div className="m_mt-1">
                                    <div className="m_d-flex">
                                        <h4 className="m_mr-1">HEX</h4>
                                        <Input
                                            placeholder="Choose Color"
                                            value={color}
                                            onChange={handleChange}
                                            name="accent_color"
                                        />
                                    </div>
                                    <div className="m_d-flex">
                                        <h4 className="m_mr-1">RGB</h4>
                                        <Input
                                            value={rgb && rgb[0]}
                                            name="accent_color"
                                        />
                                        <Input
                                            value={rgb && rgb[1]}
                                            name="accent_color"
                                        />
                                        <Input
                                            value={rgb && rgb[2]}
                                            name="accent_color"
                                        />
                                    </div>
                                </div>

                                <div className="m_d-flex m_justify-content-between">
                                    <button
                                        onClick={handlePicker}
                                        style={{ width: "10rem" }}
                                        className="m_btn m_btn--medium"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleConfirm}
                                        style={{ width: "10rem" }}
                                        className="m_btn m_btn--medium"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ColorPickInput;
