import { Switch, Route } from "react-router-dom";
import CreateTeam from "./CreateTeam";
import JoinTeam from "./JoinTeam";
import CreateJoinTeamBtn from "./CreateJoinTeamBtn";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Routes, api } from "../../";
import default_logo from "../../assets/default-company.png";
import default_event from "../../assets/default-event.png";
function Team() {
    const history = useHistory();
    const config = useSelector((state) => state.config);
    const handleLogout = () => {
        api.logout().then((res) => {
            if (res.status) {
                window.location = Routes.login;
                sessionStorage.clear();
            }
        });
    };
    return (
        <>
            <div className="navbar">
                <div className="navbar_logo">
                    <div
                        onClick={() => history.goBack()}
                        className="icon icon_4"
                    >
                        <ChevronLeftIcon />
                    </div>
                    <div className="nav_logo">
                        <img
                            src={config.company_logo || default_logo}
                            alt=""
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = default_logo;
                            }}
                        />
                    </div>
                </div>
                <div className="nav_util">
                    <button
                        onClick={handleLogout}
                        className="btn btn--medium ml-2"
                    >
                        Logout
                    </button>
                </div>
            </div>
            <div className="container">
                <div className="text-center">
                    <img
                        className="login_logo"
                        src={config.event_logo || default_event}
                        alt=""
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = default_event;
                        }}
                    />
                </div>
                <Switch>
                    <Route
                        exact
                        path={Routes.team}
                        component={CreateJoinTeamBtn}
                    />
                    <Route path={Routes.joinTeam} component={JoinTeam} />
                    <Route path={Routes.createTeam} component={CreateTeam} />
                </Switch>
            </div>
        </>
    );
}

export default Team;
