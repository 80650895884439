import React from "react";
import Nav from "../sidebar/Nav";
function SideBarItem({ items, hide }) {
    return (
        <>
            {items.map((item, index) => (
                <Nav key={index} nav={item} hide={hide} />
            ))}
        </>
    );
}
export default SideBarItem;
