import React from "react";

function ContactUs() {
  return (
    <div
      className="markdown_content_container privacy_terms"
      style={{ minHeight: "100vh" }}
    >
      <div className="markdown_content blur_bg">
        <h2>Contact Us</h2>
        <h5 className="mt-1">
          <strong>Mail</strong>: ptg@redteamacademy.com
        </h5>
        <h5 className="mt-1">
          <strong>Number:</strong> +91 8590983250
        </h5>
        <h5 className="mt-1">
          <strong>Address:</strong> C.M Mathew Brothers Arcade, Fourth Floor,
          Kannur Rd, above CSB Bank, West Nadakkave, Chakkorathukulam,
          Kozhikode, Kerala 673011
        </h5>
      </div>
    </div>
  );
}

export default ContactUs;
