import React, { useState } from "react";
import { Input, MarkdownEditor, Checkbox, InputFile } from "../../components";
import { api } from "../../services";
import { ALERT, GET_PLANS } from "../../store";
import { useDispatch } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";

function AddPlan({ plan = {}, handleToggle, type }) {
  const [name, setname] = useState(plan.name || "");
  const [description, setdescription] = useState(plan.description || "");
  const [price, setPrice] = useState(plan.price || 0);
  const [days, setDays] = useState(plan.days || 0);
  const [enabled, setEnabled] = useState(plan.enabled || false);
  const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState("");
  const [image_url, setImageUrl] = useState(plan?.image || null);
  const dispatch = useDispatch();
  async function handleSubmit() {
    const planData = {
      name,
      description,
      price: parseFloat(price),
      enabled,
      days: parseInt(days),
      image: image_url,
    };
    setLoading(true);
    try {
      let message = "added";
      if (type === "update") {
        const _plan = { id: plan.id, ...planData };
        await api.updatePlan(_plan);
        message = "updated";
      } else {
        await api.addPlan(planData);
      }
      handleToggle();
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Plan ${message} successfully`,
          type: "success",
        },
      });
      let res = await api.getPlans();
      let data = res.data.plans;
      dispatch({
        type: GET_PLANS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }
  async function upload() {
    if (!uploadImage) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    try {
      setLoading(true);
      const res = await api.uploadImage(uploadImage);
      setImageUrl(res?.data?.file_url);
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Input
        label="PLAN NAME*"
        placeholder="Enter Plan Name"
        value={name}
        onChange={setname}
      />
      {image_url ? (
        <>
          <label className="m_d-flex align-items-center justify-content-between mb-1">
            <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
              PLAN IMAGE
            </h6>
          </label>
          <div
            style={{ width: "100px", height: "100px" }}
            className="d-flex plan_image"
          >
            <img
              src={image_url}
              alt="plan"
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
            <div
              className="icon icon__primary"
              onClick={() => setImageUrl(null)}
            >
              <DeleteOutline />
            </div>
          </div>
        </>
      ) : (
        <InputFile
          onChange={setUploadImage}
          file={uploadImage}
          id="media_library"
          width="15rem"
          show={false}
          className="mt-1"
          loading={loading}
          onClick={loading ? null : upload}
          btn={loading ? "Uploading" : "Upload file"}
          value={uploadImage}
          n={30}
          // width="30rem"
        />
      )}
      <div className="m_event_editor m_mb-1" style={{ marginTop: "-1rem" }}>
        <label className="m_d-flex align-items-center justify-content-between mt-2 mb-1">
          <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
            PLAN DESCRIPTION
          </h6>
        </label>
        <MarkdownEditor
          height={200}
          value={description}
          onChange={setdescription}
        />
      </div>
      <div className="d-flex" style={{ gap: "1rem" }}>
        <Input
          label="PLAN PRICE*"
          type="number"
          placeholder="Enter Plan Days"
          value={price}
          onChange={setPrice}
        />
        <Input
          label="PLAN DAYS*"
          type="number"
          placeholder="Enter Plan Days"
          value={days}
          onChange={setDays}
        />
      </div>
      <div className="d-flex">
        <Checkbox
          label="Enabled"
          id="PlanEnabled"
          checked={enabled}
          onChange={() => setEnabled(!enabled)}
        />
      </div>

      <div className="d-flex justify-content-center mt-1">
        <button
          onClick={!loading ? handleSubmit : null}
          style={{ width: "11rem" }}
          className={`btn  ${loading ? "tab-btn" : "btn btn--medium"}`}
        >
          {loading ? "Submitting" : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default AddPlan;
