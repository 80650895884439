import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
    GET_ANNOUNCEMENTS,
    api,
    TextArea,
    Input,
    LOADING,
    ALERT,
    SEARCH,
} from "../../";
function AddAnnouncements() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "add announcement",
                icon: true,
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "announcements" },
            });
    }, [dispatch]);
    async function handleSubmit() {
        dispatch({
            type: LOADING,
        });
        try {
            await api.addAnnouncements({ title, message });
            const res = await api.getAnnouncement();
            dispatch({
                type: GET_ANNOUNCEMENTS,
                data: res.data.announcements,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Announcement created",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response && err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            history.goBack();
            dispatch({
                type: LOADING,
            });
        }
    }

    return (
        <>
            <Input
                label="title"
                placeholder="Title"
                value={title}
                onChange={setTitle}
            />
            <TextArea
                className="m_announcement_textarea"
                label="message"
                placeholder="Message"
                value={message}
                onChange={setMessage}
            />

            <div className="m_text-center">
                <button onClick={handleSubmit} className="m_btn m_btn--medium">
                    Submit
                </button>
            </div>
        </>
    );
}

export default AddAnnouncements;
