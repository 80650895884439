import { useState, useEffect } from "react";
import Search from "./Search";
import NavLogo from "./NavLogo";
import { useSelector } from "react-redux";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useLocation, useHistory } from "react-router-dom";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { ImportExportOutlined } from "@material-ui/icons";
import { api } from "../services";

function Navbar() {
  const [app, search] = useSelector((state) => [state.app, state.search]);
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    setShow(false);
  }, [pathname]);
  async function handleDownloadLogs() {
    const name = pathname.split("/").pop();
    try {
      const res = await api.exportLogs(name);
      var data = new Blob([JSON.stringify(res.data)], {
        type: "application/json",
      });
      var url = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `${name}.json`);
      tempLink.click();
    } catch (error) {}
  }
  return (
    <nav className="m_navbar">
      <NavLogo app={app} />
      {show ? (
        <Search search={search} handleClose={() => setShow(false)} />
      ) : (
        <h4 className="m_main_heading">
          {search.placeholder || search.newCategory}
        </h4>
      )}
      <div className="m_nav_util">
        <div className="m_user_container">
          {!show &&
            (search.placeholder === "announcements" ||
              search.placeholder === "challenges" ||
              search.placeholder === "users" ||
              search.newCategory === "Files" ||
              search.newCategory === "categories" ||
              search.placeholder === "plans") && (
              <div
                onClick={() => history.push(`${pathname}/add`)}
                className="m_icon"
                style={{ marginRight: "0.5" }}
              >
                <AddOutlinedIcon />
              </div>
            )}
          {((search?.showImport && pathname.includes("users")) ||
            pathname.includes("logs")) && (
            <div onClick={handleDownloadLogs} className="m_icon">
              <ImportExportOutlined />
            </div>
          )}
          {!search.icon && !show && search.placeholder && (
            <div onClick={() => setShow(!show)} className="m_icon">
              <SearchOutlinedIcon />
            </div>
          )}
          {search.setting && (
            <div
              onClick={() => history.push(`${pathname}/info`)}
              className="m_icon"
            >
              <SettingsOutlinedIcon />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
