export function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(", ");
        return `rgba(${rgb}, ${opacity})`;
    }
    return "#fff";
}

export function getUnit(day) {
    if (day < 1) {
        return "hour";
    } else if (day <= 7) {
        return "day";
    } else if (day <= 30) {
        return "week";
    } else if (day < 365) {
        return "month";
    } else {
        return "year";
    }
}
