import React from "react";
function TableHeading({ heading }) {
    return (
        <div className="m_table__heading">
            <h6 className="m_spaced">{heading}</h6>
        </div>
    );
}

export default TableHeading;
