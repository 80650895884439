import groupBy from "lodash/groupBy";
import find from "lodash/find";
import Routes from "./Routes";
import orderBy from "lodash/orderBy";
const build_categories = (challenges, categories) => {
    var result = groupBy(challenges, "category");
    const sidebarCategories = [];
    Object.entries(result).forEach(([key, value]) => {
        let category_icon = null;
        const category = find(categories, ["category_name", key]);
        if (category) {
            category_icon = category.category_icon;
        }
        sidebarCategories.push({
            category_name: key,
            count: value.length,
            category_icon,
            endpoint: `${Routes.challenge}/${key}`,
        });
    });
    return orderBy(
        sidebarCategories,
        [(s) => s.category_name.toLowerCase()],
        ["asc"]
    );
};

export default build_categories;
