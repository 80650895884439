import { themes } from "../utils";
import {
  ADD_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  LOADING,
  GET_USERS,
  GET_CHALLENGES,
  GET_USER_CHALLENGES,
  GET_CHALLENGE_FILES,
  GET_RANKS,
  GET_CHALLENGE_LOGS,
  GET_USERS_LOGS,
  GET_TEAMS,
  GET_IMAGE_FILES,
  SETTING,
  MENU,
  GET_CATEGORIES,
  SIDEBAR_CATEGORIES,
  SEARCH,
  GET_CONFIG,
  GET_DEPLOYMENTS,
  THEME,
  GET_SOLVES,
  TOGGLE_DATA,
  NOTIFICATIONS,
  SELECTED_CATEGORY,
  ALERT,
  GET_PLANS,
} from "./actionsTypes";

let initial_state = {
  toggleData: "",
  theme: themes["Ambient Black"],
  app: {
    menu: false,
    profile: null,
    isAdmin: false,
    loginPage: false,
    dashboard: false,
  },
  selected_category: "",
  loading: false,
  users: [],
  announcements: [],
  user_challenges: [],
  challenges: [],
  solves: [],
  categories: [],
  sidebarCategories: [],
  ranks: [],
  userLogs: [],
  challengeLogs: [],
  teams: [],
  imageFiles: [],
  challengeFiles: [],
  deployments: [],
  hide: false,
  search: {
    value: "",
    placeholder: "",
  },
  config: {},
  notifications: [],
  alertMessage: {
    open: false,
    message: "Hello",
    type: "error",
  },
  site_key: "",
  plans: [],
};

function reducerFunction(state = initial_state, action) {
  switch (action.type) {
    case ALERT:
      return {
        ...state,
        alertMessage: action.data,
      };
    case NOTIFICATIONS:
      return {
        ...state,
        notifications: action.data,
      };
    case TOGGLE_DATA:
      return {
        ...state,
        toggleData: action.data,
      };
    case GET_SOLVES:
      return {
        ...state,
        solves: action.data,
      };
    case THEME:
      return {
        ...state,
        theme: themes[action.data.theme_name],
      };

    case GET_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.data,
      };
    case GET_CONFIG:
      return {
        ...state,
        config: action.data,
      };
    case MENU:
      return {
        ...state,
        hide: !state.hide,
      };
    case SEARCH:
      return {
        ...state,
        search: action.data,
      };
    case SETTING:
      return {
        ...state,
        app: { ...state.app, ...action.data },
      };

    case LOADING:
      return {
        ...state,
        loading: !state.loading,
      };

    case GET_USERS:
      return {
        ...state,
        users: action.data,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.data,
      };
    case SIDEBAR_CATEGORIES:
      return {
        ...state,
        sidebarCategories: action.data,
      };
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.data,
      };
    case GET_USER_CHALLENGES:
      return {
        ...state,
        user_challenges: action.data,
      };
    case GET_CHALLENGES:
      return {
        ...state,
        challenges: action.data,
      };
    case SELECTED_CATEGORY:
      return {
        ...state,
        selected_category: action.data,
      };
    case GET_CHALLENGE_FILES:
      return {
        ...state,
        challengeFiles: action.data,
      };
    case GET_IMAGE_FILES:
      return {
        ...state,
        imageFiles: action.data,
      };
    case GET_USERS_LOGS:
      return {
        ...state,
        userLogs: action.data,
      };
    case GET_CHALLENGE_LOGS:
      return {
        ...state,
        challengeLogs: action.data,
      };
    case GET_RANKS:
      return {
        ...state,
        ranks: action.data,
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: action.data,
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [...state.announcements, action.data],
      };
    case GET_PLANS:
      return {
        ...state,
        plans: action.data,
      };

    default:
      return state;
  }
}

export default reducerFunction;
