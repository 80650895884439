import React, { useState } from "react";
import { Input, api } from "../../";
import { useHistory } from "react-router";
import { ALERT } from "../../";
import { useDispatch } from "react-redux";
function JoinTeam() {
    const [teamToken, setTeamToken] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const handleJoinTeam = () => {
        if (!teamToken) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please enter team token",
                    type: "error",
                },
            });
            return;
        }
        api.joinTeam({ team_token: teamToken })
            .then((res) => {
                history.push("/dashboard/challenge");
            })
            .catch((err) => {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: err.response.data.status,
                        type: "error",
                    },
                });
            });
    };

    function handleKeyDown(e) {
        if (e.key === "Enter") {
            handleJoinTeam();
        }
    }
    return (
        <div className="auth__container">
            <div className="auth card__container">
                <div className="card__primary auth__inputs">
                    <Input
                        label="TEAM TOKEN"
                        placeholder="Team Token"
                        value={teamToken}
                        onChange={setTeamToken}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="text-center">
                        <button
                            onClick={handleJoinTeam}
                            className="btn btn--medium"
                        >
                            Join Team
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinTeam;
