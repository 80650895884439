import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CONFIG,
  THEME,
  country_codes,
  api,
  Select,
  ColorPickInput,
  Checkbox,
  MarkdownEditor,
  InputFile,
  MediaLibrary,
  MuiDateTimePicker,
  Input,
  ALERT,
  themes,
  SEARCH,
} from "../../";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router";

function Event() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "Event",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "dashboard" },
      });
  }, [dispatch]);
  const config = useSelector((state) => state.config);
  const [event_name, setevent_name] = useState(config.event_name || "");
  const [event_about, setevent_about] = useState(config.event_about || "");
  const [event_logo, setevent_logo] = useState(config.event_logo || "");
  const [company_logo, setcompany_logo] = useState(config.company_logo || "");
  const [country, setcountry] = useState(config.country || "");
  const [text_color, settext_color] = useState(config.text_color || "");
  const [theme_dark, settheme_dark] = useState(config.theme_dark || "");
  const [theme_light, settheme_light] = useState(config.theme_light || "");
  const [theme_medium, settheme_medium] = useState(config.theme_medium || "");
  const [accent_color, setaccent_color] = useState(config.accent_color || "");
  const [start_time, setstart_time] = useState(config.start_time || "");
  const [end_time, setend_time] = useState(config.end_time || "");
  const [game_clock, setgame_clock] = useState(config.game_clock || false);
  const [verify_users, setverify_users] = useState(
    config.verify_users || false
  );
  const [scoreboard_enabled, setscoreboard_enabled] = useState(
    config.scoreboard_enabled || false
  );
  const [plan_mode, setPlanMode] = useState(config.plan_mode || false);
  const [team_mode, setteam_mode] = useState(config.team_mode || false);
  const [theme_name, settheme_name] = useState(
    config.theme_name || "Ambient Black"
  );
  const [loading, setLoading] = useState(false);
  const [uploadFiles1, setUploadFile1] = useState("");
  const [uploadFiles2, setUploadFile2] = useState("");
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [max_team_members, setmax_team_members] = useState(
    config.max_team_members || ""
  );
  async function handleSubmit() {
    if (team_mode) {
      if (max_team_members === "") {
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: "Maximum Users is required",
            type: "error",
          },
        });
        return;
      }
    }
    var _text_color = text_color;
    if (theme_name !== "Custom") {
      _text_color = themes[theme_name].text_color;
    }
    var config = {
      event_name,
      event_logo,
      text_color: _text_color,
      accent_color,
      theme_dark,
      theme_light,
      theme_medium,
      start_time,
      end_time,
      event_about,
      scoreboard_enabled,
      theme_name,
      team_mode,
      company_logo,
      game_clock,
      country,
      plan_mode,
      verify_users,
    };
    if (team_mode) {
      config = {
        ...config,
        max_team_members: parseInt(max_team_members),
      };
    }
    try {
      setLoading(true);
      await api.updateConfig(config);
      const res = await api.getConfig();
      dispatch({
        type: GET_CONFIG,
        data: res.data.config,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Event updated",
          type: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  }
  function handleDateTimeChange(value, setFunc) {
    const date = new Date(value).getTime() / 1000;
    setFunc(date);
  }

  const handleChangeTheme = (themename) => {
    dispatch({
      type: THEME,
      data: { ...config, theme_name: themename },
    });
    dispatch({
      type: GET_CONFIG,
      data: { ...config, theme_name: themename },
    });
    settheme_name(themename);
  };
  const handleShowLive = (data) => {
    dispatch({
      type: GET_CONFIG,
      data,
    });
  };
  async function uploadLogo1() {
    if (!uploadFiles1) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setloading1(true);
    try {
      const res = await api.uploadImage(uploadFiles1);
      setevent_logo(res.data.file_url);
      setUploadFile1("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setloading1(false);
    }
  }
  async function uploadLogo2() {
    if (!uploadFiles2) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setloading2(true);
    try {
      const res = await api.uploadImage(uploadFiles2);
      setcompany_logo(res.data.file_url);
      setUploadFile2("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setloading2(false);
    }
  }

  const themes_options = [
    { category_name: "Ambient Black", category_icon: "no-icon" },
    { category_name: "Charcoal Black", category_icon: "no-icon" },
    { category_name: "Chocolate Brown", category_icon: "no-icon" },
    { category_name: "Classic White", category_icon: "no-icon" },
    { category_name: "Dracula", category_icon: "no-icon" },
    { category_name: "Frozen Blue", category_icon: "no-icon" },
    { category_name: "Military Green", category_icon: "no-icon" },
    { category_name: "Pacific Blue", category_icon: "no-icon" },
    { category_name: "Silicone Green", category_icon: "no-icon" },
    { category_name: "Slate Grey", category_icon: "no-icon" },
    {
      category_name: "Custom",
      category_icon: "no-icon",
      handler: () => {
        history.push("/dashboard/event/customtheme");
        handleChangeTheme("Custom");
      },
    },
  ];
  const countries = country_codes.map(({ name }) => {
    return {
      category_name: name,
      category_icon: "no-icon",
    };
  });

  return (
    <Switch>
      <Route path="/dashboard/event">
        <Route path="/dashboard/event/customtheme">
          <div className="m_d-flex m_flex-1 m_flex-column m_toggle__card">
            <ColorPickInput
              onChange={(color) => {
                handleShowLive({
                  ...config,
                  text_color: color,
                });
                settext_color(color);
              }}
              value={text_color}
              label="Text"
            />
            <ColorPickInput
              onChange={(color) => {
                handleShowLive({
                  ...config,
                  theme_light: color,
                });
                settheme_light(color);
              }}
              value={theme_light}
              label="Light"
            />

            <ColorPickInput
              onChange={(color) => {
                handleShowLive({
                  ...config,
                  theme_medium: color,
                });
                settheme_medium(color);
              }}
              value={theme_medium}
              label="medium"
            />
            <ColorPickInput
              onChange={(color) => {
                handleShowLive({
                  ...config,
                  theme_dark: color,
                });
                settheme_dark(color);
              }}
              value={theme_dark}
              label="Dark"
            />
            <div className="m_d-flex m_justify-content-center m_mt-2">
              <button
                onClick={
                  !loading
                    ? async () => {
                        await handleSubmit();
                        history.goBack();
                      }
                    : null
                }
                className={`m_btn  ${
                  loading ? "m_tab-btn" : "m_btn m_btn--medium"
                }`}
              >
                {loading ? "Submitting" : "Submit"}
              </button>
            </div>
          </div>
        </Route>
        <Route path="/dashboard/event/event/medialibrary">
          <MediaLibrary
            type="event"
            route="event"
            onInsert={(logo) =>
              setevent_about(
                `${event_about} ![Image](${encodeURI(logo.trim())})`
              )
            }
          />
        </Route>
        <Route exact path="/dashboard/event">
          <div className="m_table m_event">
            <InputFile
              label="Event Logo"
              file={uploadFiles1}
              onChange={setUploadFile1}
              onClick={loading1 ? null : uploadLogo1}
              btn={`${loading1 ? "Uploading" : "Upload file"} `}
              id="event_event_logo"
              value={event_logo.split("-").pop()}
              loading={loading1}
              tag="(720 × 360)"
            />

            <InputFile
              label="Company Logo"
              file={uploadFiles2}
              onChange={setUploadFile2}
              onClick={loading2 ? null : uploadLogo2}
              btn={`${loading2 ? "Uploading" : "Upload file"} `}
              id="event_company_logo"
              loading={loading2}
              value={company_logo.split("-").pop()}
              tag="(360 × 80)"
            />
            <Select
              options={themes_options}
              placeholder="Enter Theme Colour"
              label="Theme Colour"
              onChange={handleChangeTheme}
              value={theme_name}
            />
            <div className="m_accent_color">
              <ColorPickInput
                onChange={(color) => setaccent_color(color)}
                value={accent_color}
                label="Accent Colour"
              />
            </div>
            <div className="m_d-flex m_flex-column">
              <MuiDateTimePicker
                onChange={(e) => handleDateTimeChange(e, setstart_time)}
                value={start_time}
                id1="m_date-1"
                id2="m_time-1"
                label="START TIME"
              />
              <div className="m_mt-1">
                <MuiDateTimePicker
                  onChange={(e) => handleDateTimeChange(e, setend_time)}
                  value={end_time}
                  id1="m_date-2"
                  id2="m_time-2"
                  label="END TIME"
                />
              </div>
            </div>
            <Select
              className="m_mt-1"
              options={countries}
              placeholder="Select Country"
              label="Country"
              onChange={setcountry}
              value={country}
            />
            <div className="m_d-flex m_justify-content-between">
              <div className="m_flex-1">
                <Checkbox
                  label="Scoreboard"
                  id="scoreboard_enabled"
                  checked={scoreboard_enabled}
                  onChange={() => setscoreboard_enabled(!scoreboard_enabled)}
                />
              </div>
              <div className="m_flex-1">
                <Checkbox
                  label="Team Mode"
                  id="team_mode"
                  checked={team_mode}
                  onChange={() => setteam_mode(!team_mode)}
                />
              </div>
            </div>
            {team_mode && (
              <Input
                className="mt-1"
                label="Maximum Users"
                placeholder="Enter 0 for unlimited users"
                value={max_team_members}
                onChange={setmax_team_members}
                type="number"
              />
            )}
            <div className="m_d-flex mt-1 m_justify-content-between">
              <div className="m_flex-1">
                <Checkbox
                  label="Verify Users"
                  id="verify_users"
                  checked={verify_users}
                  onChange={() => setverify_users(!verify_users)}
                />
              </div>

              <div className="m_flex-1">
                <Checkbox
                  label="Game Clock"
                  id="game_clock"
                  checked={game_clock}
                  onChange={() => setgame_clock(!game_clock)}
                />
              </div>
            </div>

            <div className="m_flex-1 mt-1">
              <Checkbox
                label="Plan Mode"
                id="plan_mode"
                checked={plan_mode}
                onChange={() => setPlanMode(!plan_mode)}
              />
            </div>

            <Input
              className="m_mt-1"
              label="event name"
              value={event_name}
              onChange={setevent_name}
            />
            <div className="m_event_editor">
              <label className="m_d-flex align-items-center justify-content-between">
                <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
                  EVENT DESCRIPTION
                </h6>
                <div
                  onClick={() =>
                    history.push("/dashboard/event/event/medialibrary")
                  }
                  className="m_media_libray_markdown"
                >
                  <div className="m_icon m_icon__sm">
                    <AddPhotoAlternateOutlinedIcon />
                  </div>
                </div>
              </label>

              <MarkdownEditor value={event_about} onChange={setevent_about} />
            </div>

            <div className="m_d-flex m_justify-content-center m_mt-4">
              <button
                onClick={!loading ? handleSubmit : null}
                className={`m_btn  ${
                  loading ? "m_tab-btn" : "m_btn m_btn--medium"
                }`}
              >
                {loading ? "Updating" : "Update"}
              </button>
            </div>
          </div>
        </Route>
      </Route>
    </Switch>
  );
}

export default Event;
