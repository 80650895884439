import React from "react";

import { useHistory } from "react-router";
import not_found from "../assets/404.png";
function NoMatch() {
    const history = useHistory();
    return (
        <div className="not-found_container">
            <div className="flex-1">
                <div className="text">
                    <h1>404</h1>
                    <h2>Why am i here?</h2>
                    <h3>
                        It seems like the page you’re trying to reach is either
                        unavailable or under construction.
                    </h3>
                    <div
                        onClick={() => history.push("/")}
                        className="btn not_found_btn"
                    >
                        Go Home
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <img src={not_found} alt="" />
            </div>
        </div>
    );
}

export default NoMatch;
