import React from "react";
import { TableHeading } from "../../";
function Info({ info }) {
    return (
        <div className="info">
            <div style={{ flexDirection: "column", flex: 1 }}>
                <TableHeading heading="docker image" />
                <h3>{info.docker_image}</h3>
                <TableHeading heading="status" />
                <h3>{info.status}</h3>
            </div>
            <div style={{ flexDirection: "column", flex: 1 }}>
                <TableHeading heading="container port" />
                <h3>{info.container_port}</h3>
                <TableHeading heading="endpoint" />
                <h3>{info.endpoint}</h3>
            </div>
        </div>
    );
}

export default Info;
