export const TOGGLE = "toggle";
export const LOADING = "loading";
export const TOGGLE_DATA = "toggle_data";
export const ADD_ANNOUNCEMENT = "add_announcement";
export const GET_CHALLENGES = "/admin/challenges";
export const GET_USER_CHALLENGES = "/user/challenges";
export const GET_SOLVES = "/user/usersolves";
export const GET_USERS = "/admin/users";
export const GET_ANNOUNCEMENTS = "/user/announcements";
export const GET_RANKS = "/scoreboard";
export const GET_USERS_LOGS = "get_users_logs";
export const GET_CHALLENGE_LOGS = "get_challenge_logs";
export const GET_TEAMS = "/admin/teams";
export const GET_IMAGE_FILES = "/admin/imagefiles";
export const GET_CHALLENGE_FILES = "/admin/challengefiles";
export const SETTING = "setting";
export const MENU = "menu";
export const SIDEBAR_CATEGORIES = "sidebar_categories";
export const GET_CATEGORIES = "/user/categories";
export const SEARCH = "show_search";
export const GET_DEPLOYMENTS = "/admin/challengedeployments";
export const ADD_CATEGORY = "add_category";
export const THEME = "theme";
export const NOTIFICATIONS = "notifications";
export const GET_CONFIG = "/config";
export const SELECTED_CATEGORY = "selected_category";
export const ALERT = "alert";
export const GET_PLANS = "/admin/plans";
