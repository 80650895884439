import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChallengeInfo from "./ChallengeInfo";
import UserGraph from "./UserGraph";
import Profile from "./Profile";
import ChangeProfile from "./ChangeProfile";
import { api, ALERT, SmLoading, SEARCH } from "../../";
import { useParams, useHistory } from "react-router";
import { Switch, Route } from "react-router-dom";
import ChangeUsername from "./modals/ChangeUsername";
import ChangePassword from "./modals/ChangePassword";
import MyPlan from "./MyPlan";
function Settings() {
  const { username } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userStat, setUserState] = useState("");
  const [app, config] = useSelector((state) => [state.app, state.config]);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState("");
  useEffect(() => {
    async function fetch() {
      setLoading(true);
      var res;
      try {
        try {
          await api.getAdminStatus();
          res = await api.getAdminUserState(username);
        } catch (err) {
          res = await api.getUserState(username);
        }
        setUserState(res.data);
      } catch (err) {
        dispatch({
          type: ALERT,
          data: {
            open: true,
            message: "User not found",
            type: "error",
          },
        });
        history.goBack();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetch();
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "profile",
        icon: true,
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch, history, username]);
  const handleLogout = () => {
    api.logout().then((res) => {
      if (res.status) {
        window.location = "/auth/login";
        sessionStorage.clear();
      }
    });
  };
  async function handleLeaveAccount() {
    try {
      await api.leaveTeam();
      window.location = "/team";
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Team leave successfully",
          type: "success",
        },
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Team leave successfully.",
          type: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    }
    setConfirm("");
  }
  async function handleDeleteAccount() {
    try {
      await api.deleteAccount();
      window.location = "/auth/login";
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Account deleted successfully.",
          type: "success",
        },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: error.response && error.response.data.status,
          type: "error",
        },
      });
    }
    setConfirm("");
  }
  return (
    <div className="m_settings">
      {typeof confirm === "function" && (
        <div className="m_toggle">
          <div className="m_toggle-container">
            <div
              className="m_toggle_card animate__fadeInUp flex-column"
              style={{ background: "var(--dark)" }}
            >
              <div className="text-center">
                <h3>Are you sure?</h3>
              </div>
              <div className="d-flex justify-content-evenly mt-2">
                <div
                  className="m_btn m_btn--medium"
                  onClick={() => setConfirm("")}
                >
                  Cancel
                </div>
                <div className="m_btn m_btn--medium ml-2" onClick={confirm}>
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading || !userStat ? (
        <SmLoading />
      ) : (
        <Switch>
          <Route path="/dashboard/profile/:username">
            <Route path="/dashboard/profile/:username/changeusername">
              <ChangeUsername />
            </Route>
            <Route path="/dashboard/profile/:username/changepassword">
              <ChangePassword />
            </Route>

            <Route exact path="/dashboard/profile/:username">
              <Profile
                profile={userStat}
                points={userStat.points}
                challenges_solved={userStat.solved_challenges}
                total_challenges={userStat.total_challenges}
                showEdit={
                  app && app.profile && app.profile.username === username
                }
              />
              <MyPlan userStat={userStat} />
              <UserGraph
                data={userStat.graph_points}
                heading="graph"
                label={username}
              />
              <ChallengeInfo
                categorical_challenges={userStat.categorical_challenges}
                categorical_solves={userStat.categorical_solves}
                solves={userStat.solves}
                points={userStat.points}
                challenges_solved={userStat.solved_challenges}
                total_challenges={userStat.total_challenges}
              />

              {app && app.profile && app.profile.username === username && (
                <>
                  <ChangeProfile profile={userStat} />
                  <button
                    onClick={handleLogout}
                    className="m_btn m_btn--medium"
                  >
                    Logout
                  </button>
                  {config.team_mode && (
                    <button
                      onClick={() => setConfirm(() => handleLeaveAccount)}
                      className="m_btn m_btn--medium danger mt-1"
                    >
                      Leave Team
                    </button>
                  )}
                  <button
                    onClick={() => setConfirm(() => handleDeleteAccount)}
                    className="m_btn m_btn--medium danger mt-1"
                  >
                    Delete Account
                  </button>
                </>
              )}
            </Route>
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default Settings;
