import Routes from "./Routes";
export const adminSideBar = [
  {
    category_name: "Users",
    category_icon: "PersonOutlineOutlined",
    endpoint: `${Routes.adminUsers}`,
  },
  {
    category_name: "Teams",
    category_icon: "PeopleAltOutlined",
    endpoint: `${Routes.adminTeams}`,
  },
  {
    category_name: "Challenges",
    category_icon: "OutlinedFlag",
    endpoint: `${Routes.adminChallenges}`,
  },
  {
    category_name: "Plans",
    category_icon: "LocalAtm",
    endpoint: `${Routes.plans}`,
  },
  {
    category_name: "Announcements",
    category_icon: "NotificationsNoneOutlined",
    endpoint: `${Routes.adminAnnouncements}`,
  },
  {
    category_name: "Statistics",
    category_icon: "TrendingUpOutlined",
    endpoint: `${Routes.adminStatistics}`,
  },
  {
    category_name: "Scoreboard",
    category_icon: "AssessmentOutlined",
    endpoint: `${Routes.scoreboard}`,
  },

  {
    category_name: "Logs",
    category_icon: "ListAltOutlined",
    endpoint: `${Routes.adminLogs}`,
  },
  {
    category_name: "Event",
    category_icon: "SportsEsportsOutlined",
    endpoint: `${Routes.adminEvent}`,
  },
];
export const userSideBar = [
  {
    category_name: "Announcements",
    category_icon: "NotificationsNoneOutlined",
    endpoint: `${Routes.announcements}`,
  },
  {
    category_name: "Scoreboard",
    category_icon: "AssessmentOutlined",
    endpoint: `${Routes.scoreboard}`,
  },
];
