import React from "react";

function TableHeader({ headers }) {
    return (
        <div className="m_table_header">
            {headers.map((header, index) => (
                <div key={index} className="m_flex-1">
                    <h3>{header}</h3>
                </div>
            ))}
        </div>
    );
}

export default TableHeader;
