import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const ele = document.getElementsByClassName("m_container");
        if (ele && ele[0]) {
            ele[0].scrollTop = 0;
        }
    }, [pathname]);

    return null;
}
