import { Line } from "react-chartjs-2";

import { useSelector } from "react-redux";
import "chartjs-adapter-moment";
import { hexToRgbA, getUnit } from "../../utils";

const colors = [
    "gold",
    "dodgerblue",
    "green",
    "orange",
    "red",
    "blue",
    "white",
    "pink",
    "dodgerblue",
    "gray",
    "brown",
];

function Graph({ graphData }) {
    const data = graphData;
    const config = useSelector((state) => state.config);
    var sets = [];
    const day =
        (new Date() - new Date(config.start_time * 1000)) /
        (24 * 60 * 60 * 1000);

    if (data && data.length > 0) {
        data.map((d, index) => {
            const gData = [];
            if (d.graph_points) {
                gData.push({
                    x: new Date(config.start_time * 1000),
                    y: 0,
                });
                d.graph_points.forEach((e) => {
                    gData.push({
                        x: new Date(e.time * 1000),
                        y: parseInt(e.points),
                    });
                });
            }
            sets.push({
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderWidth: 2,
                lineTension: 0,
                pointHoverBorderColor: "var(--medium)",
                pointHoverBorderWidth: 4,
                label: d.team_name,
                data: gData,
                tooltip: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        label: (context) => {
                            var label = context.dataset.label || "";
                            label += ` (${context.raw.y})`;
                            return label;
                        },
                    },
                },
            });
            return 0;
        });
    }
    if (sets.length === 0) {
        if (sets.length === 0) {
            sets.push({
                backgroundColor: "#FF9900",
                borderColor: "#FF9900",
                borderWidth: 5,
                lineTension: 0.4,
                pointHoverBorderColor: "#FF9900",
                pointBorderWidth: 10,
                data: null,
            });
        }
    }
    return (
        <div className="m_graph" style={{ padding: "0.5rem" }}>
            <Line
                options={{
                    animation: false,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: {
                            type: "time",
                            ticks: {
                                color: config.text_color,
                                fontSize: 18,
                                stepSize: 1,
                                beginAtZero: true,
                            },
                            time: {
                                unit: `${getUnit(day)}`,
                                unitStepSize: 1,
                                displayFormats: {
                                    day: "DD MMM",
                                },
                                tooltipFormat: "DD MMM YYYY HH:mm:ss",
                            },
                            grid: {
                                drawBorder: false,
                                color: function (context) {
                                    if (context.tick.value > 0) {
                                        return hexToRgbA(
                                            config.text_color,
                                            0.2
                                        );
                                    } else if (context.tick.value < 0) {
                                        return "red";
                                    }

                                    return "#000000";
                                },
                            },
                        },
                        yAxes: {
                            ticks: {
                                color: config.text_color,
                                fontSize: 18,
                                stepSize: 1,
                                beginAtZero: true,
                            },
                            grid: {
                                drawBorder: false,
                                color: function (context) {
                                    if (context.tick.value >= 0) {
                                        return hexToRgbA(
                                            config.text_color,
                                            0.2
                                        );
                                    } else if (context.tick.value < 0) {
                                        return "blue";
                                    }
                                    return "#000000";
                                },
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: sets.length > 1 ? true : false,
                            labels: {
                                color: config.text_color,
                                font: {
                                    family: "Roboto, sans-serif",
                                },
                            },
                        },
                    },
                }}
                data={{
                    datasets: sets,
                }}
            />
        </div>
    );
}

export default Graph;
