import { useState } from "react";

import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";

import { useSelector } from "react-redux";
import { Nav, api, Routes, gravatar } from "../";
export default function User() {
  const [show, setShow] = useState(false);
  const app = useSelector((state) => state.app);
  const profile = app.profile;
  const handleLogout = () => {
    api.logout().then((res) => {
      if (res.status) {
        window.location = Routes.login;
        sessionStorage.clear();
      }
    });
  };

  return (
    <div className="user_container">
      <div className="user">
        <div className="username">{profile.username}</div>
        <div onClick={() => setShow(!show)} className="avatar">
          <img src={gravatar(profile.gravatar_hash)} alt="" />
        </div>
        <div onClick={() => setShow(!show)} className="icon">
          {show ? (
            <KeyboardArrowUpOutlinedIcon />
          ) : (
            <KeyboardArrowDownOutlinedIcon />
          )}
        </div>
        {show && (
          <div onMouseLeave={() => setShow(!show)} className="dropdown">
            <Nav
              nav={{
                category_name: "Profile",
                category_icon: "AccountCircleOutlined",
                endpoint: `/dashboard/profile/${profile.username}`,
              }}
            />

            <Nav
              nav={{
                category_name: "Logout",
                category_icon: "PowerSettingsNewOutlined",
              }}
              onClick={handleLogout}
            />
          </div>
        )}
      </div>
    </div>
  );
}
