import React from "react";
function Input({
    className,
    onChange,
    children,
    type = "text",
    label = null,
    label__side = null,
    onKeyDown,
    ...rest
}) {
    return (
        <div className={`input-group-container ${className}`}>
            {label || label === "" ? (
                <label className="label d-flex">
                    <h6 className="spaced">{label.split("*")[0]}</h6>
                    {label.slice(-1) === "*" && (
                        <h6 style={{ color: "var(--red)" }}>
                            {label.slice(-1)}
                        </h6>
                    )}
                </label>
            ) : null}
            <div className="input-group-icon-container">
                {label__side || label__side === "" ? (
                    <label className="event_label_side">
                        <h3>{label__side}</h3>
                    </label>
                ) : null}
                <div className="input-group-icon">
                    <div className="input-group">
                        <input
                            className="input"
                            {...rest}
                            type={type}
                            onChange={(e) => onChange(e.target.value)}
                            onKeyDown={onKeyDown}
                        />
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Input;
