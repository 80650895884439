import React from "react";
import { TableHeading, gravatar } from "../../";
import { useHistory } from "react-router";
function TeamUsers({ members }) {
    const history = useHistory();
    return (
        <div className="m_card__container mb-1 mt-1">
            <TableHeading heading="team members" />
            <div className="team_members">
                {members &&
                    members.map((user, index) => (
                        <div
                            className="m_team_members_card"
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                history.push(
                                    `/dashboard/profile/${user.username}`
                                )
                            }
                        >
                            <img
                                className="m_team_members_img"
                                src={gravatar(user.gravatar_hash)}
                                alt=""
                            />
                            <h4> {user.username}</h4>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default TeamUsers;
