import React from "react";
const ToolTip = ({ children, text, ...rest }) => {
    const [show, setShow] = React.useState(false);

    return (
        <div className="tooltip-container">
            <div className={show ? "tooltip-box visible" : "tooltip-box"}>
                {text}
                <span className="tooltip-arrow" />
            </div>
            <div onClick={() => setShow(!show)} {...rest}>
                {children}
            </div>
        </div>
    );
};
export default ToolTip;
