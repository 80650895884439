import React from "react";
import { Toggle } from "../../";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
function Locked({ handleToggle }) {
  const history = useHistory();
  return (
    <Toggle style={{ zIndex: 100 }} handleToggle={handleToggle}>
      <div className="d-flex align-items-center flex-column">
        <div
          style={{
            background: "var(--dark)",
            borderRadius: "10px",
            padding: "5px",
            marginBottom: "10px",
          }}
        >
          <LockOutlined style={{ fontSize: "100px", color: "var(--yellow)" }} />
        </div>
        <h2 style={{ marginBottom: "10px" }}>Challenged Locked</h2>
        <h3>Please subscribe to the plan for access these challenges</h3>
        <button
          onClick={() => history.push("/subscribePlan")}
          className="btn btn--medium"
          style={{ margin: "2rem 0" }}
        >
          Subscribe Now
        </button>
      </div>
    </Toggle>
  );
}

export default Locked;
