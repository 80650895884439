import React, { useState, useEffect } from "react";
import Input from "./Input";
import * as Icons from "@material-ui/icons";
const icons = Object.keys(Icons);

function SearchIcons({ icon, setIcon }) {
    const [search, setSearch] = useState("");
    useEffect(() => {
        setSearch(icon);
    }, [icon]);
    const [iconsList, setIconsList] = useState([]);
    const handleSearch = (value) => {
        if (value === "") setIcon("");
        if (value !== "notFound") setSearch(value);
        const filterIcons = icons.filter((icon) =>
            icon.toLowerCase().includes(value)
        );
        setIconsList(filterIcons);
    };
    async function handleIcon(icon) {
        setIcon(icon);
        setSearch("");
        handleSearch("notFound");
    }

    return (
        <div className="search-icons">
            <Input
                onChange={handleSearch}
                value={search || icon}
                placeholder="Search Icon"
            >
                {search && (
                    <div className="all_icons">
                        {iconsList.map((icon, index) => {
                            let Icon = Icons[icon];
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleIcon(icon)}
                                    style={{
                                        color: "var(--text_color)",
                                        margin: "10px",
                                    }}
                                >
                                    <Icon style={{ fontSize: "4rem" }} />
                                </div>
                            );
                        })}
                    </div>
                )}
            </Input>
        </div>
    );
}

export default SearchIcons;
