import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GET_USERS, LOADING, api, InputPassword, Input, ALERT } from "../../";

function AddUser({ handleToggle }) {
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  const handleSubmit = async () => {
    const userData = {
      username,
      email,
      password,
      confirm_password,
      confirm_email,
    };
    try {
      dispatch({
        type: LOADING,
        data: true,
      });
      await api.addUser(userData);
      const res = await api.getUsers();
      dispatch({
        type: GET_USERS,
        data: res.data.users,
      });
      handleToggle();
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "User added",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
        data: true,
      });
    }
  };
  function handleUsername(username) {
    const len = username.length - 1;
    if (!username.match(/^[0-9a-zA-Z_ ]+$/) && username[len]) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `' ${username[len]} ' not allowed`,
          type: "error",
        },
      });
    } else {
      setUserName(username);
    }
  }
  return (
    <>
      <Input
        label="USERNAME"
        placeholder="username"
        value={username}
        onChange={handleUsername}
      />
      <Input
        label="EMAIL"
        type="email"
        placeholder="email"
        value={email}
        onChange={setEmail}
      />
      <Input
        label="Confirm Email"
        type="email"
        placeholder="confirm email"
        value={confirm_email}
        onChange={setConfirmEmail}
      />
      <InputPassword
        label="PASSWORD"
        placeholder="password"
        value={password}
        onChange={setPassword}
      />
      <InputPassword
        label="CONFIRM PASWORD"
        placeholder="confirm password"
        value={confirm_password}
        onChange={setConfirmPassword}
      />
      <div className="text-center">
        <button onClick={handleSubmit} className="btn btn--medium">
          Submit
        </button>
      </div>
    </>
  );
}

export default AddUser;
