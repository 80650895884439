import { Link } from "react-router-dom";
import User from "./User";
import Search from "./Search";
import NavLogo from "./NavLogo";
import { useSelector } from "react-redux";
function Navbar() {
    const app = useSelector((state) => state.app);
    return (
        <nav className="navbar">
            <NavLogo app={app} />
            <Search />
            <div className="nav_util">
                {app.profile && <User />}
                {!app.profile && sessionStorage.getItem("setup") && (
                    <div className="d-flex">
                        <Link to="/auth/login">
                            <button className="btn btn--medium">Login</button>
                        </Link>
                        <Link to="/auth/register">
                            <button className="btn btn--medium ml-2">
                                Register
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
