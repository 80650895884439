import { useEffect } from "react";
import { getIcon } from "./../utils/getIcon";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminSideBar, userSideBar } from "../utils/sidebar";
import { SEARCH, searchData } from "../";
function DashboardItems() {
  const dispatch = useDispatch();
  const [app, config] = useSelector((state) => [state.app, state.config]);
  let adminSideBarItem = adminSideBar;
  let userSideBarItem = userSideBar;

  if (!config.scoreboard_enabled) {
    adminSideBarItem = adminSideBar.filter(
      (c) => c.category_name !== "Scoreboard"
    );
  }
  if (!config.team_mode) {
    adminSideBarItem = adminSideBarItem.filter(
      (c) => c.category_name !== "Teams"
    );
  }

  if (!config.plan_mode) {
    adminSideBarItem = adminSideBarItem.filter(
      (c) => c.category_name !== "Plans"
    );
  }

  if (!config.scoreboard_enabled) {
    userSideBarItem = userSideBar.filter(
      (c) => c.category_name !== "Scoreboard"
    );
  }

  useEffect(() => {
    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: "dashboard",
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "" },
      });
  }, [dispatch]);
  const items = app.isAdmin ? adminSideBarItem : userSideBarItem;

  const search = useSelector((state) => state.search);
  var searchItem = items;
  if (search && search.value) {
    searchItem = searchData(search.value, items);
  }
  return (
    <div className="main_items">
      {searchItem.map((item, index) => (
        <Link key={index} to={item.endpoint}>
          <div className="m_category_item">
            <div className="m_icon m_icon__primary">
              {getIcon(item.category_icon)}
            </div>
            <h3>{item.category_name}</h3>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default DashboardItems;
