import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../";
function CreateJoinTeamBtn() {
    return (
        <div className="create-join_team">
            <Link to={Routes.joinTeam}>
                <button className="btn btn--medium btn--lg">
                    <h1>Join Team</h1>
                </button>
            </Link>
            <Link to={Routes.createTeam}>
                <button className="btn btn--medium btn--lg">
                    <h1>Create Team</h1>
                </button>
            </Link>
        </div>
    );
}

export default CreateJoinTeamBtn;
