import React from "react";
import CountUp from "react-countup";
function StatisticsCard({ text = 0, label }) {
    return (
        <div className="m_statistics-card">
            <div className="m_statistics">
                <CountUp
                    className="m_statistics__item m_statistics__text"
                    start={0}
                    end={parseInt(text)}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                />
            </div>
            <div className="m_statistics__item m_statistics__label">
                {label}
            </div>
        </div>
    );
}

export default StatisticsCard;
