import ChallengeLogs from "./ChallengeLogs";
import UserLogs from "./UserLogs";
import { useState } from "react";
import {
  ImportExportOutlined,
  LocalAtm,
  OutlinedFlag,
  PersonOutlineOutlined,
} from "@material-ui/icons";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import AdminLogs from "./AdminLogs";
import SubscriptionLogs from "./SubscriptionLogs";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { Input } from "../../";
import { api } from "../../services";

function Logs() {
  const [toggle, setToggle] = useState("userlogs");
  const [search, setSearch] = useState("");
  const style = {
    border: "1px solid var(--text_color_5)",
  };
  async function handleDownloadLogs() {
    try {
      const res = await api.exportLogs(toggle);
      var data = new Blob([JSON.stringify(res.data)], {
        type: "application/json",
      });
      var url = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `${toggle}.json`);
      tempLink.click();
    } catch (error) {}
  }
  return (
    <div className="logs">
      <div className="table">
        <div className="table__heading">
          <h5 style={{ flex: 0.1 }} className="spaced">
            {toggle}
          </h5>
          <div className="logs_search">
            <div onClick={handleDownloadLogs} className="icon icon__primary">
              <ImportExportOutlined />
            </div>
            <div
              style={toggle === "userlogs" ? style : null}
              onClick={() => setToggle("userlogs")}
              className="icon icon__primary"
            >
              <PersonOutlineOutlined />
            </div>
            <div
              style={toggle === "challengelogs" ? style : null}
              onClick={() => setToggle("challengelogs")}
              className="icon icon__primary"
            >
              <OutlinedFlag />
            </div>

            <div
              style={toggle === "adminlogs" ? style : null}
              onClick={() => setToggle("adminlogs")}
              className="icon icon__primary"
            >
              <VerifiedUserOutlinedIcon />
            </div>
            <div
              style={toggle === "subscriptionlogs" ? style : null}
              onClick={() => setToggle("subscriptionlogs")}
              className="icon icon__primary"
            >
              <LocalAtm />
            </div>
            <div className="search">
              <div className="icon-search">
                <SearchOutlinedIcon />
              </div>
              <Input
                placeholder={`Search ${toggle.split("logs")[0]} logs`}
                value={search}
                onChange={setSearch}
              />
            </div>
          </div>
        </div>
        {toggle === "userlogs" && <UserLogs search={search} />}
        {toggle === "challengelogs" && <ChallengeLogs search={search} />}
        {toggle === "adminlogs" && <AdminLogs search={search} />}
        {toggle === "subscriptionlogs" && <SubscriptionLogs search={search} />}
      </div>
    </div>
  );
}

export default Logs;
