import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    api,
    Select,
    InputFile,
    GET_USER_CHALLENGES,
    Toggle,
    Input,
    Checkbox,
    SIDEBAR_CATEGORIES,
    build_categories,
    MediaLibrary,
    ALERT,
} from "../../";
import NewCategory from "./NewCategory";
import MarkdownEditor from "./../../components/MarkdownEditor";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";

const difficulty_options = [
    {
        category_name: "Easy",
        category_icon: "no-icon",
    },
    {
        category_name: "Medium",
        category_icon: "no-icon",
    },
    {
        category_name: "Hard",
        category_icon: "no-icon",
    },
];
function Challenge({ handleToggle, toggle_id, type = "new", challenge = {} }) {
    const [name, setname] = useState(challenge.name || "");
    const [challenge_logo, setchallenge_logo] = useState(
        challenge.challenge_logo || ""
    );
    const [description, setdescription] = useState(challenge.description || "");
    const [category, setcategory] = useState(challenge.category || "");
    const [points, setpoints] = useState(challenge.points || "");
    const [difficulty, setdifficulty] = useState(challenge.difficulty || "");
    const [endpoint, setendpoint] = useState(challenge.endpoint || "");
    const [uploadFiles1, setUploadFile1] = useState("");
    const [uploadFiles2, setUploadFile2] = useState("");
    const [file, setfile] = useState(challenge.file || "");
    const [flag, setflag] = useState(challenge.flag || "");
    const [loading2, setLoading2] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [min_points, setmin_points] = useState(challenge.min_points || "");
    const [min_points_threshold, setmin_points_threshold] = useState(
        challenge.min_points_threshold || ""
    );
    const [dynamic, setdynamic] = useState(challenge.dynamic || false);
    const [hidden, sethidden] = useState(challenge.hidden || false);
    const [toggleCategory, setToggleCategory] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories);
    const [media_library, setMediaLibrary] = useState("");
    useEffect(() => {
        if (!dynamic) {
            setmin_points("");
            setmin_points_threshold("");
        }
    }, [dynamic]);
    const handleSubmit = async () => {
        const _points = {
            points: parseInt(points),
            min_points: !isNaN(parseInt(min_points)) ? parseInt(min_points) : 0,
            min_points_threshold: !isNaN(parseInt(min_points_threshold))
                ? parseInt(min_points_threshold)
                : 0,
        };
        const newChallenge = {
            name,
            challenge_logo,
            description,
            category,
            points,
            difficulty,
            endpoint,
            file,
            flag,
            min_points,
            min_points_threshold,
            dynamic,
            hidden,
            ..._points,
        };
        if (
            newChallenge.description === "" ||
            newChallenge.points <= 0 ||
            newChallenge.difficulty === "" ||
            newChallenge.name === "" ||
            newChallenge.category === "" ||
            newChallenge.flag === ""
        ) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${
                        newChallenge.name === ""
                            ? "Challenge name"
                            : "" || newChallenge.description === ""
                            ? "Challenge description"
                            : "" || newChallenge.category === ""
                            ? "Category"
                            : "" || newChallenge.difficulty === ""
                            ? "Difficulty"
                            : "" ||
                              isNaN(newChallenge.points) ||
                              parseInt(newChallenge.points) <= 0
                            ? "Points"
                            : "" || newChallenge.flag === ""
                            ? "Flag"
                            : ""
                    } is a required field`,
                    type: "error",
                },
            });
            return;
        }
        if (
            newChallenge.dynamic &&
            (isNaN(newChallenge.min_points_threshold) ||
                parseInt(newChallenge.min_points_threshold) <= 0 ||
                isNaN(newChallenge.min_points) ||
                parseInt(newChallenge.min_points) <= 0)
        ) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${
                        isNaN(newChallenge.min_points_threshold) ||
                        parseInt(newChallenge.min_points_threshold) <= 0
                            ? "Decay Threshold"
                            : "" ||
                              isNaN(newChallenge.min_points) ||
                              parseInt(newChallenge.min_points) <= 0
                            ? "Minimum Points"
                            : ""
                    }  is a required field`,
                    type: "error",
                },
            });
            return;
        }

        if (
            parseInt(newChallenge.min_points) >= parseInt(newChallenge.points)
        ) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Minimum points should be less than Points",
                    type: "error",
                },
            });
            return;
        }
        try {
            setLoading(true);
            if (type === "update") {
                await api.updateChallenge({
                    id: challenge.id,
                    ...newChallenge,
                });
            } else {
                await api.addChallenge(newChallenge);
            }

            let res = await api.getChallenges();
            const challenges = res.data.challenges;
            if (challenges)
                dispatch({
                    type: GET_USER_CHALLENGES,
                    data: challenges,
                });
            res = await api.getCategories();
            const categories = res.data.categories;
            const sidebar_categories = build_categories(challenges, categories);
            if (sidebar_categories)
                dispatch({
                    type: SIDEBAR_CATEGORIES,
                    data: sidebar_categories,
                });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Challenge ${
                        type === "update" ? "edited" : "created"
                    }`,
                    type: "success",
                },
            });
            handleToggle();
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            setLoading(false);
        }
    };

    async function uploadFile() {
        if (!uploadFiles2) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please choose file",
                    type: "warning",
                },
            });
            return;
        }
        setLoading2(true);
        try {
            const res = await api.uploadFile(uploadFiles2);
            setfile(res.data.file_url);
            setUploadFile2("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File uploaded",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be uploaded",
                    type: "error",
                },
            });
        } finally {
            setLoading2(false);
        }
    }
    async function uploadLogo() {
        if (!uploadFiles1) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please choose file",
                    type: "warning",
                },
            });
            return;
        }
        setLoading1(true);
        try {
            const res = await api.uploadImage(uploadFiles1);
            setchallenge_logo(res.data.file_url);
            setUploadFile1("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File uploaded",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be uploaded",
                    type: "error",
                },
            });
        } finally {
            setLoading1(false);
        }
    }

    const handleToggleCategory = () => setToggleCategory(!toggleCategory);
    var options = [
        {
            category_name: "Manage Categories",
            category_icon: "SettingsOutlined",
            handler: handleToggleCategory,
        },
    ];
    if (categories && categories.length > 0) {
        options = [
            ...categories,
            {
                category_name: "Manage Categories",
                category_icon: "SettingsOutlined",
                handler: handleToggleCategory,
            },
        ];
    }
    function handleChallengeName(name) {
        const len = name.length - 1;
        if (!name.match(/^[0-9a-zA-Z_ ]+$/) && name[len]) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `' ${name[len]} ' not allowed`,
                    type: "error",
                },
            });
        } else {
            setname(name);
        }
    }
    return (
        <>
            {media_library && (
                <Toggle
                    classes="challengePanel"
                    handleToggle={() => setMediaLibrary("")}
                >
                    <MediaLibrary
                        onInsert={(logo) =>
                            setdescription(
                                `${description} ![Image](${encodeURI(
                                    logo.trim()
                                )})`
                            )
                        }
                        type="challenge"
                        handleToggle={() => setMediaLibrary("")}
                    />
                </Toggle>
            )}
            {toggleCategory && (
                <Toggle
                    classes="categories_panel"
                    handleToggle={handleToggleCategory}
                >
                    <NewCategory
                        toggle_id={"categories_panel"}
                        handleToggle={handleToggleCategory}
                    />
                </Toggle>
            )}
            <Input
                label="CHALLENGE NAME*"
                placeholder="Enter Challenge Name"
                value={name}
                onChange={handleChallengeName}
            />
            <div className="event_editor mb-1" style={{ marginTop: "0.5rem" }}>
                <label
                    style={{
                        position: "relative",
                        marginBottom: "0.5rem",
                    }}
                    className="d-flex"
                >
                    <h6 className="spaced mb-1">CHALLENGE DESCRIPTION</h6>
                    <h6 style={{ color: "var(--red)" }}>*</h6>
                    <div
                        style={{
                            position: "absolute",
                            right: "3rem",
                            top: "-3rem",
                        }}
                    >
                        <div
                            onClick={() => setMediaLibrary("event_about")}
                            className="icon media_libray_markdown"
                        >
                            <div className="icon icon__primary">
                                <AddPhotoAlternateOutlinedIcon />
                            </div>
                        </div>
                    </div>
                </label>

                <MarkdownEditor
                    media_library={media_library}
                    height={200}
                    value={description}
                    onChange={setdescription}
                />
            </div>

            <div className="challenge_response_view">
                <Select
                    label="CATEGORY*"
                    options={options}
                    placeholder="Select Category"
                    value={category}
                    onChange={setcategory}
                    toggle_id={toggle_id}
                />
                <Select
                    label="DIFFICULTY*"
                    placeholder="Select difficulty"
                    value={difficulty}
                    options={difficulty_options}
                    onChange={setdifficulty}
                />

                <Input
                    label="ENDPOINT"
                    placeholder="Enter endpoint"
                    value={endpoint}
                    onChange={setendpoint}
                />
                <Input
                    label="points*"
                    type="number"
                    placeholder="Enter points"
                    value={points}
                    onChange={setpoints}
                />

                <Input
                    label={`decay threshold ${dynamic ? "*" : ""}`}
                    placeholder="decay threshold"
                    type="number"
                    value={min_points_threshold}
                    onChange={setmin_points_threshold}
                    disabled={!dynamic}
                />
                <Input
                    label={`minimum points ${dynamic ? "*" : ""}`}
                    type="number"
                    placeholder="minimum points"
                    value={min_points}
                    onChange={setmin_points}
                    disabled={!dynamic}
                />
                <Input
                    label="flag*"
                    placeholder="Enter flag"
                    value={flag}
                    onChange={setflag}
                />
            </div>

            <div className="challenge_response_view">
                <InputFile
                    label="LOGO"
                    file={uploadFiles1}
                    onChange={setUploadFile1}
                    onClick={loading1 ? null : uploadLogo}
                    btn={`${loading1 ? "Uploading" : "Upload file"} `}
                    id="challenge_logo"
                    loading={loading1}
                    value={challenge_logo}
                    tag="(160 × 160)"
                />
                <div className="flex-1 mt-2">
                    <Input
                        placeholder="Or Enter File Url"
                        value={challenge_logo}
                        onChange={setchallenge_logo}
                    />
                </div>
                <InputFile
                    label="File"
                    onClick={loading2 ? null : uploadFile}
                    btn={`${loading2 ? "Uploading" : "Upload file"} `}
                    file={uploadFiles2}
                    onChange={setUploadFile2}
                    id="challenge_file"
                    loading={loading2}
                    value={file}
                />
                <div className="flex-1 mt-2">
                    <Input
                        placeholder="Or Enter File Url"
                        value={file}
                        onChange={setfile}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-evenly">
                <Checkbox
                    label="dynamic"
                    checked={dynamic}
                    onChange={() => setdynamic(!dynamic)}
                    id="challenge_dynamic"
                />
                <Checkbox
                    label="hidden"
                    checked={hidden}
                    onChange={() => sethidden(!hidden)}
                    id="challenge_hidden"
                />
            </div>

            <div className="text-center mt-1">
                <button
                    onClick={!loading ? handleSubmit : null}
                    style={{ width: "11rem" }}
                    className={`btn  ${
                        loading ? "tab-btn" : "btn btn--medium"
                    }`}
                >
                    {loading ? "Submitting" : "Submit"}
                </button>
            </div>
        </>
    );
}

export default Challenge;
