import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  Home,
  Loading,
  NoMatch,
  Dashboard,
  Routes,
  Team,
  Auth,
  GlobalStyles,
  EventPage,
  api,
  GET_CONFIG,
  THEME,
  AlertMessage,
  ErrorPage,
} from "./";
import TermsCondition from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SubscribePlans from "./pages/SubscribePlans/SubscribePlans";
import RefundAndCancellationPolicy from "./components/RefundAndCancellationPolicy";
import ContactUs from "./components/ContactUs";

function DApp() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch() {
      try {
        const res = await api.getStatus();
        if (res.data.status !== "ok") {
          history.push("/auth/setup");
        }
      } catch (err) {}
      try {
        const res = await api.getConfig();
        const config = res.data.config;
        document.title = config.event_name;
        if (res.status === 200) {
          dispatch({
            type: GET_CONFIG,
            data: config,
          });
          dispatch({
            type: THEME,
            data: {
              theme_name: config.theme_name,
            },
          });
        }
      } catch (err) {}
    }
    fetch();
  }, [dispatch, history]);
  let [theme, config, loading, alertMessage] = useSelector((state) => [
    state.theme,
    state.config,
    state.loading,
    state.alertMessage,
  ]);
  var new_theme;
  if (config.theme_name === "Custom") {
    const { text_color, theme_light, theme_medium, theme_dark } = config;
    theme = {
      text_color,
      light: theme_light,
      medium: theme_medium,
      dark: theme_dark,
    };
  }
  if (config) new_theme = { ...theme, accent: config.accent_color };
  return (
    <ThemeProvider theme={new_theme}>
      <GlobalStyles />
      {alertMessage.open && <AlertMessage />}
      {loading && <Loading />}
      <div className="app">
        <Switch>
          <Route exact path={Routes.default} component={Home} />
          <Route path={Routes.event} component={EventPage} />
          {config.plan_mode && (
            <Route path={Routes.subscribePlan} component={SubscribePlans} />
          )}
          <Route path={Routes.team} component={Team} />
          <Route path={Routes.dashboard} component={Dashboard} />
          <Route path={Routes.auth} component={Auth} />
          <Route path={Routes.termsAndCondition} component={TermsCondition} />
          <Route path={Routes.privacyPolicy} component={PrivacyPolicy} />
          <Route
            path={Routes.refundAndCancellationPolicy}
            component={RefundAndCancellationPolicy}
          />
          <Route path={Routes.contactUs} component={ContactUs} />
          <Route path="/error" component={ErrorPage} />
          <Route path={Routes.notfound}>
            <NoMatch />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default withRouter(DApp);
