import React from "react";
var uniqid = require("uniqid");

function ToolTip({ n, children, item, toggle_id, outside }) {
    const id = uniqid();
    function handleMouseMove(e) {
        var x = e.clientX;
        var y = e.clientY;
        const spanText = document.getElementById(id);
        const toggleCard = document.getElementById(toggle_id);
        if (spanText) {
            if (toggleCard) {
                const { top, left } = toggleCard.getBoundingClientRect();
                if (outside) {
                    spanText.style.left = x - left - 100 + "px";
                    spanText.style.top = y - top + 20 + "px";
                } else {
                    spanText.style.left = x - left + 20 + "px";
                    spanText.style.top = y - top + 20 + "px";
                }
            } else {
                spanText.style.left = x + 20 + "px";
                spanText.style.top = y + 20 + "px";
            }
        }
    }
    return (
        <div
            onMouseMove={handleMouseMove}
            className="tooltip_no overflow_text"
            href="http://www.google.com/"
        >
            {children}
            {item.length > n && (
                <span id={id}>
                    <h3>{item}</h3>
                </span>
            )}
        </div>
    );
}

export default ToolTip;
