import TableHeader from "./TableHeader";
import { useSelector } from "react-redux";
import { searchData } from "../utils/";

function PaginationComponent({ TableBody, items, headers }) {
    const search = useSelector((state) => state.search);
    var searchItem = items;
    if (search && search.value) {
        searchItem = searchData(search.value, items);
    }
    return (
        <div className="m_table">
            {headers && <TableHeader headers={headers} />}
            {TableBody(searchItem)}
        </div>
    );
}

export default PaginationComponent;
