import React, { useEffect } from "react";
import {
    TableHeading,
    SEARCH,
    api,
    GET_DEPLOYMENTS,
    ALERT,
    LOADING,
} from "../../";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
function Info({ info, redirect, id }) {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "deployment",
                icon: true,
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: {
                    value: "",
                    placeholder: "update challenge",
                    setting: true,
                    icon: true,
                },
            });
    }, [dispatch]);

    async function startChallenge() {
        dispatch({
            type: LOADING,
        });
        try {
            await api.startChallenge(id);
            const res = await api.getChallengesDeployments();
            dispatch({
                type: GET_DEPLOYMENTS,
                data: res.data.challenge_deployments,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment started`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
            history.goBack();
        }
    }
    async function stopChallenge() {
        dispatch({
            type: LOADING,
        });
        try {
            await api.stopChallenge(id);
            const res = await api.getChallengesDeployments();
            dispatch({
                type: GET_DEPLOYMENTS,
                data: res.data.challenge_deployments,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment stopped`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
            history.goBack();
        }
    }

    async function deleteChallengeDeployment() {
        dispatch({
            type: LOADING,
        });
        try {
            await api.deleteChallengeDeployment(id);

            const res = await api.getChallengesDeployments();
            dispatch({
                type: GET_DEPLOYMENTS,
                data: res.data.challenge_deployments,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment deleted`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
            history.goBack();
        }
    }
    if (!info) return <Redirect to={redirect} />;
    return (
        <div className="m_info">
            <div className="m_d-flex">
                <div className="m_flex-1">
                    <TableHeading heading="docker image" />
                    <h3>{info.docker_image}</h3>
                </div>
                <div className="m_flex-1">
                    <TableHeading heading="container port" />
                    <h3>{info.container_port}</h3>
                </div>
            </div>
            <div className="m_d-flex">
                <div className="m_flex-1">
                    <TableHeading heading="status" />
                    <h3>{info.status}</h3>
                </div>
                <div className="m_flex-1">
                    <TableHeading heading="endpoint" />
                    <h3>{info.endpoint}</h3>
                </div>
            </div>
            <div className="m_text-center mt-2">
                <button
                    onClick={info.status ? startChallenge : stopChallenge}
                    className="m_btn m_btn--medium"
                >
                    {info.status === "stopped" ? "Start" : "Stop"}
                </button>
            </div>
            <div className="m_text-center m_mt-2">
                <button
                    onClick={deleteChallengeDeployment}
                    className="m_btn m_btn--secondary"
                >
                    Delete
                </button>
            </div>
        </div>
    );
}

export default Info;
