import React from "react";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
function Locked({ handleToggle }) {
  const history = useHistory();
  return (
    <div className="m_toggle">
      <div className="m_toggle-container">
        <div
          className="m_toggle_card animate__fadeInUp"
          style={{ background: "var(--dark)" }}
        >
          <div className="d-flex align-items-center flex-column">
            <div
              style={{
                background: "var(--dark)",
                borderRadius: "10px",
                padding: "5px",
                marginBottom: "10px",
              }}
            >
              <LockOutlined
                style={{ fontSize: "100px", color: "var(--yellow)" }}
              />
            </div>
            <h2 style={{ marginBottom: "10px" }}>Challenged Locked</h2>
            <h3 className="text-center">
              Please subscribe to the plan for access these challenges
            </h3>
            <button
              onClick={() => history.push("/subscribePlan")}
              className="m_btn m_btn--medium"
              style={{ margin: "2rem 0" }}
            >
              Subscribe Now
            </button>
            <button onClick={handleToggle} className="m_btn m_btn--medium">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locked;
