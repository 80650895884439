import { useState, useEffect } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import TableHeading from "./TableHeading";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import InputFile from "./InputFile";
import { useSelector, useDispatch } from "react-redux";
import { GET_IMAGE_FILES, api, ALERT, LOADING } from "../";
function MediaLibrary({ onInsert, type, handleToggle }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const files = useSelector((state) => state.imageFiles);
    useEffect(() => {
        async function fetch() {
            dispatch({
                type: LOADING,
            });
            try {
                const res = await api.getMedia(type);
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            } catch (error) {
            } finally {
                dispatch({
                    type: LOADING,
                });
            }
        }
        fetch();
    }, [dispatch, type]);
    const [file, setFile] = useState("");
    const [uploadImage, setUploadImage] = useState("");
    const [loading, setLoading] = useState(false);
    async function upload() {
        if (!uploadImage) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please choose file",
                    type: "warning",
                },
            });
            return;
        }
        setLoading(true);
        try {
            let res = await api.uploadMedia(uploadImage, type);
            if (res && res.status === 200) {
                res = await api.getMedia(type);
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            }
            setUploadImage("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File uploaded",
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be uploaded",
                    type: "error",
                },
            });
        } finally {
            setLoading(false);
        }
    }

    function handleInsert() {
        onInsert(file);
        handleToggle();
    }
    async function deleteImage() {
        try {
            let res = await api.deleteMedia(type, file.split("/").pop());
            if (res && res.status === 200) {
                setFile("");
                res = await api.getMedia(type);
                dispatch({
                    type: GET_IMAGE_FILES,
                    data: res.data.files,
                });
            }
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File deleted",
                    type: "success",
                },
            });
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "File can't be deleted",
                    type: "error",
                },
            });
        }
    }
    function setRow(file) {
        setFile(file.url);
        setId(file.url);
    }
    return (
        <>
            <div className="d-flex">
                <div style={{ flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <TableHeading heading="Files" />
                        <div
                            className="mt-1"
                            style={{ height: "50vh", overflow: "auto" }}
                        >
                            {files &&
                                files.map((file, index) => (
                                    <div
                                        onClick={() => setRow(file)}
                                        key={index}
                                        className={`table_data_item   ${
                                            id === file.url ? "active_row" : ""
                                        }`}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div style={{ padding: "0 1em" }}>
                                            {file.filename.split("-").pop()}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div style={{ flex: 0.7, marginLeft: "0.5rem" }}>
                    <TableHeading heading="Details" />
                    <div className="image_viewer mt-1">
                        {file ? (
                            <img src={file} alt="" />
                        ) : (
                            <DescriptionIcon
                                style={{
                                    fontSize: 100,
                                    color: "var(--text_color)",
                                }}
                            />
                        )}
                    </div>
                    <div className="text-center mt-1">
                        <h3>{file.split("-").pop()}</h3>
                        <div className="d-flex justify-content-center mt-1">
                            {file ? (
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    id={file}
                                    href={file}
                                >
                                    <div
                                        className={`icon icon__primary m-0 ${
                                            !file ? "tab-btn" : ""
                                        }`}
                                    >
                                        <GetAppOutlinedIcon />
                                    </div>
                                </a>
                            ) : (
                                <div
                                    className={`icon icon__primary m-0 ${
                                        !file ? "tab-btn" : ""
                                    }`}
                                >
                                    <GetAppOutlinedIcon />
                                </div>
                            )}
                            <div className="ml-1 mr-1">
                                <button
                                    onClick={file ? handleInsert : null}
                                    className={`btn  ${
                                        !file ? "tab-btn" : "btn btn--medium"
                                    }`}
                                >
                                    Insert
                                </button>
                            </div>
                            <div
                                onClick={file ? deleteImage : null}
                                className={`icon icon__primary m-0 ${
                                    !file ? "tab-btn" : ""
                                }`}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </div>
                        </div>
                    </div>

                    <div className="mt-1">
                        <TableHeading heading="Upload" />
                        <InputFile
                            onChange={setUploadImage}
                            file={uploadImage}
                            id="media_library"
                            width="15rem"
                            show={false}
                            className="mt-1"
                            loading={loading}
                            onClick={loading ? null : upload}
                            btn={loading ? "Uploading" : "Upload file"}
                            value={uploadImage}
                            n={30}
                            // width="30rem"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaLibrary;
