import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { api, GET_CONFIG, LOADING } from "../";
import default_logo from "../assets/default-company.png";
import default_event from "../assets/default-event.png";
import MDEditor from "@uiw/react-md-editor";

import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";
// import DiscordIcon from "./DiscordIcon";

function EventPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const config = useSelector((state) => state.config);
  useEffect(() => {
    async function fetch() {
      try {
        dispatch({
          type: LOADING,
        });
        try {
          await api.getUserStatus();
          setLogin(true);
        } catch (err) {
          setLogin(false);
        }
        const res = await api.getConfig();
        dispatch({
          type: GET_CONFIG,
          data: res.data.config,
        });
      } catch (err) {
      } finally {
        dispatch({
          type: LOADING,
        });
      }
    }
    fetch();
  }, [dispatch]);
  async function handleDashboard() {
    dispatch({
      type: LOADING,
    });
    try {
      await api.getUserStatus();
      history.push("/dashboard/challenge");
    } catch (err) {
      history.push("/auth/login");
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  return (
    <div className="event_homepage">
      <div className="navbar">
        <div onClick={() => history.push("/")} className="navbar_logo">
          <div className="nav_logo">
            <img
              src={config.company_logo || default_logo}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = default_logo;
              }}
            />
          </div>
        </div>
        {login ? (
          <div className="nav_util">
            <button onClick={handleDashboard} className="btn btn--medium">
              Dashboard
            </button>
          </div>
        ) : (
          <div className="nav_util">
            <button
              onClick={() => history.push("/auth/login")}
              className="btn btn--medium"
            >
              Login
            </button>
            <button
              onClick={() => history.push("/auth/register")}
              className="btn btn--medium ml-1"
            >
              Register
            </button>
          </div>
        )}
      </div>
      <div className="event_homepage_container">
        <div className="text-center">
          <img
            className="login_logo"
            src={config.event_logo || default_event}
            alt=""
            style={{ marginBottom: "4rem" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_event;
            }}
          />
        </div>
        <div className="table m-0">
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            {config.event_name}
          </h1>
          <h3>
            <MDEditor.Markdown source={config.event_about} />
          </h3>
        </div>
        <div className="event_footer">
          <div className="text-center">
            <h2>Connect With Us</h2>
          </div>
          <div className="contact_us_links">
            <a
              href="mailto:support@redteamacademy.com"
              rel="noreferrer"
              target="_blank"
            >
              <MailOutlineOutlinedIcon />
            </a>
            <a href="tel:+919562505666" rel="noreferrer" target="_blank">
              <PhoneIcon />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=+919562505666"
              rel="noreferrer"
              target="_blank"
            >
              <WhatsAppIcon />
            </a>
            {/* <a
                            href="https://discord.gg/Fhzd2aGxPQ"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <DiscordIcon />
                        </a> */}
            <a
              href="https://www.facebook.com/redteamacademy/"
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/redteamacademy"
              rel="noreferrer"
              target="_blank"
            >
              <TwitterIcon />
            </a>
            {/* <a
                            href="https://www.youtube.com/channel/UCZ97BDceDGeogmcm9g374JQ"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <YouTubeIcon />
                        </a> */}
            <a
              href="https://www.linkedin.com/company/redteamacademy/"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://www.instagram.com/redteamacademy/"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
          </div>
          <div className="mt-1 center">
            {/* <h5 className="text-color">
              © 2021 NullTrace Pvt. Ltd. | All rights reserved.
            </h5> */}
            <div className="d-flex">
              <Link to="/termsandcondition">
                <h6>{"Terms & conditions"}</h6>
              </Link>
              <Link to="/privacypolicy" className="ml-2">
                <h6> Privacy Policy</h6>
              </Link>
              <Link to="/refund-and-cancellation-policy" className="ml-2">
                <h6>Refund and cancellation policy</h6>
              </Link>
              <Link to="/contact-us" className="ml-2">
                <h6>Contact us</h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPage;
