import { NavLink } from "react-router-dom";
import NavItem from "./NavItem";
export default function Nav({ nav, onClick }) {
    if (nav.endpoint) {
        return (
            <NavLink to={`${nav.endpoint}`}>
                <NavItem nav={nav} />
            </NavLink>
        );
    } else {
        return (
            <div onClick={onClick}>
                <NavItem nav={nav} />
            </div>
        );
    }
}
