import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CachedIcon from "@material-ui/icons/Cached";
import BlockIcon from "@material-ui/icons/Block";
import searchData from "./searchData";
function getStatus(status) {
    switch (status) {
        case "running":
            return {
                color: "var(--green)",
                icon: CheckCircleOutlineIcon,
            };

        case "stopped":
            return {
                color: "var(--red)",
                icon: HighlightOffIcon,
            };
        case "starting":
            return {
                color: "var(--blue)",
                icon: CachedIcon,
            };
        default:
            return {
                color: "var(--text)",
                icon: BlockIcon,
            };
    }
}

export default function filterChallenges(search, challenges, deployments) {
    const challengesWithDeploymentsStatus = challenges.map((c) => {
        if (deployments) {
            const index = deployments.findIndex((d) => d.cid === c.id);
            if (index > -1) {
                c.status = getStatus(deployments[index].status);
            } else {
                c.status = getStatus();
            }
        } else {
            c.status = getStatus();
        }
        return c;
    });
    if (search.value)
        return searchData(search.value, challengesWithDeploymentsStatus);
    return challengesWithDeploymentsStatus;
}
