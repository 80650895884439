import React from "react";
import Nav from "../sidebar/Nav";
function SideBarItem({ items, heading, hide, Icon }) {
    return (
        <>
            <div className="nav_heading_sidebar">
                <div className="icon icon_heading">
                    <Icon style={{ fontSize: "2.6rem" }} />
                </div>
                <div className={`nav_item_heading ${hide ? "hide" : "show"}`}>
                    <h5 className="spaced">{heading}</h5>
                </div>
            </div>
            {items.map((item, index) => (
                <Nav key={index} nav={item} hide={hide} />
            ))}
        </>
    );
}
export default SideBarItem;
