import React, { useState } from "react";

import {
    GET_CATEGORIES,
    api,
    SearchIcons,
    Input,
    TableHeading,
    getIcon,
    ALERT,
    ToolTip,
} from "../../";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
function NewCategory({ toggle_id }) {
    const dispatch = useDispatch();
    const [category_name, setcategory_name] = useState("");
    const [category_name_r, setcategory_name_r] = useState("");
    const [category_icon, setIcon] = useState("");
    const [category_icon_r, setIcon_r] = useState("");
    const [category, setCategory] = useState("");
    const categories = useSelector((state) => state.categories);
    const handleCategory = async () => {
        try {
            await api.addCategory({ category_name, category_icon });
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            setcategory_name("");
            setIcon("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category_name} added`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
        }
    };
    const handleUpdateCategory = async () => {
        try {
            await api.updateCategory({
                id: category.id,
                category_name: category_name_r,
                category_icon: category_icon_r,
            });
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category.category_name} updated`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
        }
    };
    const deleteCategory = async () => {
        try {
            await api.deleteCategory(category.id);
            const res = await api.getCategories();
            dispatch({
                type: GET_CATEGORIES,
                data: res.data.categories,
            });
            setCategory("");
            setcategory_name_r("");
            setIcon_r("");
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `${category.category_name} deleted`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response ? err.response.data.status : "",
                    type: "error",
                },
            });
        } finally {
        }
    };
    function setRow(c) {
        setCategory(c);
        setcategory_name_r(c.category_name);
        setIcon_r(c.category_icon);
    }
    return (
        <div className="d-flex">
            <div
                style={{
                    flex: 0.6,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <TableHeading heading="Categories" />
                <div
                    className="mt-1"
                    style={{ height: "33rem", overflow: "auto" }}
                >
                    {categories &&
                        categories.map((c, index) => (
                            <div
                                onClick={() => setRow(c)}
                                key={index}
                                className={`table_data_item   ${
                                    category.id === c.id ? "active_row" : ""
                                }`}
                                style={{ cursor: "pointer", flex: 1 }}
                            >
                                <div className="icon">
                                    {getIcon(c.category_icon)}
                                </div>
                                <ToolTip
                                    n={20}
                                    item={c.category_name}
                                    smCard={true}
                                    toggle_id={toggle_id}
                                >
                                    <div style={{ padding: "0 1em" }}>
                                        {c.category_name}
                                    </div>
                                </ToolTip>
                            </div>
                        ))}
                </div>
            </div>
            <div className="ml-1 icon_categories" style={{ flex: 0.4 }}>
                <div className="d-flex justify-content-center category_big">
                    <div className="icon mt-1">{getIcon(category_icon_r)}</div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                    <Input
                        placeholder="Name"
                        value={category_name_r}
                        onChange={setcategory_name_r}
                    />
                    <SearchIcons icon={category_icon_r} setIcon={setIcon_r} />
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                            <div
                                onClick={deleteCategory}
                                className="icon icon__primary"
                            >
                                <DeleteOutlinedIcon />
                            </div>
                        </div>
                        <button
                            onClick={handleUpdateCategory}
                            className="btn btn--medium"
                        >
                            Update
                        </button>
                    </div>
                </div>
                <div className="mt-2">
                    <Input
                        label="Add Category"
                        placeholder="Name"
                        value={category_name}
                        onChange={setcategory_name}
                    />
                    <SearchIcons icon={category_icon} setIcon={setIcon} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <button
                            onClick={handleCategory}
                            className="btn btn--medium"
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewCategory;
