import { Switch, Route } from "react-router-dom";
import CreateTeam from "./CreateTeam";
import JoinTeam from "./JoinTeam";
import CreateJoinTeamBtn from "./CreateJoinTeamBtn";
import { useSelector } from "react-redux";
import { Routes } from "../../";
import default_event from "../../assets/default-event.png";
import Navbar from "../../components/Navbar";
function Team({ height }) {
    const config = useSelector((state) => state.config);
    return (
        <>
            <Navbar />
            <div
                className="m_container m_flex-column"
                style={{ height: height + 54, background: "var(--dark)" }}
            >
                <div className="text-center">
                    <img
                        className="m_login_logo"
                        src={config.event_logo || default_event}
                        alt=""
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = default_event;
                        }}
                    />
                </div>
                <Switch>
                    <Route
                        exact
                        path={Routes.team}
                        component={CreateJoinTeamBtn}
                    />
                    <Route path={Routes.joinTeam} component={JoinTeam} />
                    <Route path={Routes.createTeam} component={CreateTeam} />
                </Switch>
            </div>
        </>
    );
}

export default Team;
