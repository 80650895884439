import React from "react";
import { TableHeading, gravatar } from "../../";
import { useHistory } from "react-router";
function TeamUsers({ members }) {
    const history = useHistory();
    return (
        <div className="card__container mb-4">
            <TableHeading heading="team members" />
            <div className="team_members">
                {members &&
                    members.map((user, index) => (
                        <div
                            className="team_members_card"
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                history.push(
                                    `/dashboard/profile/${user.username}`
                                )
                            }
                        >
                            <img
                                className="team_members_img"
                                src={gravatar(user.gravatar_hash)}
                                alt=""
                            />
                            <h3> {user.username}</h3>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default TeamUsers;
