import React, { useState } from "react";

import { useDispatch } from "react-redux";
import {
    GET_ANNOUNCEMENTS,
    api,
    TextArea,
    Input,
    LOADING,
    ALERT,
} from "../../";

function AddAnnouncements({ handleToggle }) {
    const dispatch = useDispatch();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    async function handleSubmit() {
        dispatch({
            type: LOADING,
            data: true,
        });
        try {
            await api.addAnnouncements({ title, message });
            const res = await api.getAnnouncement();
            dispatch({
                type: GET_ANNOUNCEMENTS,
                data: res.data.announcements,
            });
            handleToggle();
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Announcement created",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
                data: true,
            });
        }
    }

    return (
        <>
            <Input
                label="title"
                placeholder="Title"
                value={title}
                onChange={setTitle}
            />
            <TextArea
                className="announcement_textarea"
                label="message"
                placeholder="Message"
                value={message}
                onChange={setMessage}
            />

            <div className="text-center">
                <button onClick={handleSubmit} className="btn btn--medium">
                    Submit
                </button>
            </div>
        </>
    );
}

export default AddAnnouncements;
