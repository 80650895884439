import React from "react";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import * as Icons from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import ToolTip from "../ToolTip";
import { SELECTED_CATEGORY } from "../../store";
import { useLocation } from "react-router-dom";
function NavItem({ nav, hide, className, toggle_id }) {
    let Icon = Icons[nav.category_icon];
    if (!Icon) {
        Icon = PublicOutlinedIcon;
    }
    const dispatch = useDispatch();
    var selected_category = useSelector((state) => state.selected_category);
    const location = useLocation();
    if (!selected_category) {
        selected_category = location.pathname.split("/").pop();
    }
    return (
        <div
            onClick={() => {
                dispatch({
                    type: SELECTED_CATEGORY,
                    data: nav.category_name,
                });
            }}
            className={`nav ${
                nav.category_name.toLowerCase().trim() ===
                selected_category.toLowerCase().trim()
                    ? "selected"
                    : ""
            } ${className ? className : ""}`}
        >
            {nav.category_icon !== "no-icon" && (
                <div className="icon">
                    <Icon />
                </div>
            )}
            {!hide && (
                <ToolTip n={18} item={nav.category_name} toggle_id={toggle_id}>
                    <h3>{nav.category_name}</h3>
                </ToolTip>
            )}
            {!hide &&
                !isNaN(parseInt(nav.count)) &&
                nav.count !== 0 &&
                nav.count > 0 && (
                    <div className="count">
                        <h3>{parseInt(nav.count)}</h3>
                    </div>
                )}
        </div>
    );
}

export default NavItem;
