import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  api,
  Select,
  InputFile,
  Input,
  Checkbox,
  SIDEBAR_CATEGORIES,
  build_categories,
  ALERT,
  SEARCH,
  LOADING,
  GET_CHALLENGES,
  MediaLibrary,
  Toggle,
} from "../../";
import NewCategory from "./NewCategory";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router";
import MarkdownEditor from "./../../components/MarkdownEditor";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import AddDeployment from "./AddDeployment";
import Info from "./Info";
const difficulty_options = [
  {
    category_name: "Easy",
    category_icon: "no-icon",
  },
  {
    category_name: "Medium",
    category_icon: "no-icon",
  },
  {
    category_name: "Hard",
    category_icon: "no-icon",
  },
];
function Challenge({ type = "add", challenge = {} }) {
  const history = useHistory();
  const [name, setname] = useState(challenge.name || "");
  const [challenge_logo, setchallenge_logo] = useState(
    challenge.challenge_logo || ""
  );
  const [description, setdescription] = useState(challenge.description || "");
  const [category, setcategory] = useState(challenge.category || "");
  const [points, setpoints] = useState(challenge.points || "");
  const [difficulty, setdifficulty] = useState(challenge.difficulty || "");
  const [endpoint, setendpoint] = useState(challenge.endpoint || "");
  const [uploadFiles1, setUploadFile1] = useState("");
  const [uploadFiles2, setUploadFile2] = useState("");
  const [file, setfile] = useState(challenge.file || "");
  const [flag, setflag] = useState(challenge.flag || "");
  const [loading2, setLoading2] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [min_points, setmin_points] = useState(challenge.min_points || "");
  const [min_points_threshold, setmin_points_threshold] = useState(
    challenge.min_points_threshold || ""
  );
  const [dynamic, setdynamic] = useState(challenge.dynamic || false);
  const [hidden, sethidden] = useState(challenge.hidden || false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [categories, deployments] = useSelector((state) => [
    state.categories,
    state.deployments,
  ]);

  useEffect(() => {
    if (!dynamic) {
      setmin_points("");
      setmin_points_threshold("");
    }

    dispatch({
      type: SEARCH,
      data: {
        value: "",
        placeholder: `${type} challenge`,
        setting: true,
        icon: true,
      },
    });
    return () =>
      dispatch({
        type: SEARCH,
        data: { value: "", placeholder: "challenges" },
      });
  }, [dynamic, dispatch, type]);
  const handleSubmit = async () => {
    const _points = {
      points: parseInt(points),
      min_points: !isNaN(parseInt(min_points)) ? parseInt(min_points) : 0,
      min_points_threshold: !isNaN(parseInt(min_points_threshold))
        ? parseInt(min_points_threshold)
        : 0,
    };
    const newChallenge = {
      name,
      challenge_logo,
      description,
      category,
      points,
      difficulty,
      endpoint,
      file,
      flag,
      min_points,
      min_points_threshold,
      dynamic,
      hidden,
      ..._points,
    };
    if (
      newChallenge.description === "" ||
      newChallenge.points <= 0 ||
      newChallenge.difficulty === "" ||
      newChallenge.name === "" ||
      newChallenge.category === "" ||
      newChallenge.flag === ""
    ) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `${
            newChallenge.name === ""
              ? "Challenge name"
              : "" || newChallenge.description === ""
              ? "Challenge description"
              : "" || newChallenge.category === ""
              ? "Category"
              : "" || newChallenge.difficulty === ""
              ? "Difficulty"
              : "" ||
                isNaN(newChallenge.points) ||
                parseInt(newChallenge.points) <= 0
              ? "Points"
              : "" || newChallenge.flag === ""
              ? "Flag"
              : ""
          } is a required field`,
          type: "error",
        },
      });
      return;
    }
    if (
      newChallenge.dynamic &&
      (isNaN(newChallenge.min_points_threshold) ||
        parseInt(newChallenge.min_points_threshold) <= 0 ||
        isNaN(newChallenge.min_points) ||
        parseInt(newChallenge.min_points) <= 0)
    ) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `${
            isNaN(newChallenge.min_points_threshold) ||
            parseInt(newChallenge.min_points_threshold) <= 0
              ? "Decay Threshold"
              : "" ||
                isNaN(newChallenge.min_points) ||
                parseInt(newChallenge.min_points) <= 0
              ? "Minimum Points"
              : ""
          }  is a required field`,
          type: "error",
        },
      });
      return;
    }

    if (parseInt(newChallenge.min_points) >= parseInt(newChallenge.points)) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Minimum points should be less than Points",
          type: "error",
        },
      });
      return;
    }
    try {
      setLoading(true);
      if (type === "update") {
        await api.updateChallenge({
          id: challenge.id,
          ...newChallenge,
        });
      } else {
        await api.addChallenge(newChallenge);
      }

      let res = await api.getAdminChallenges();
      const challenges = res.data.challenges;
      if (challenges)
        dispatch({
          type: GET_CHALLENGES,
          data: challenges,
        });
      res = await api.getCategories();
      const categories = res.data.categories;
      const sidebar_categories = build_categories(challenges, categories);
      if (sidebar_categories)
        dispatch({
          type: SIDEBAR_CATEGORIES,
          data: sidebar_categories,
        });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Challenge ${type === "update" ? "edited" : "created"}`,
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      setLoading(false);
      history.goBack();
    }
  };

  async function uploadFile() {
    if (!uploadFiles2) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setLoading2(true);
    try {
      const res = await api.uploadFile(uploadFiles2);
      setfile(res.data.file_url);
      setUploadFile2("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setLoading2(false);
    }
  }
  async function uploadLogo() {
    if (!uploadFiles1) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "Please choose file",
          type: "warning",
        },
      });
      return;
    }
    setLoading1(true);
    try {
      const res = await api.uploadImage(uploadFiles1);
      setchallenge_logo(res.data.file_url);
      setUploadFile1("");
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File uploaded",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: "File can't be uploaded",
          type: "error",
        },
      });
    } finally {
      setLoading1(false);
    }
  }

  const handleToggleCategory = () =>
    history.push(`/dashboard/challenges/${type}/newcategory`);
  var options = [
    {
      category_name: "Manage Categories",
      category_icon: "SettingsOutlined",
      handler: handleToggleCategory,
    },
  ];
  if (categories && categories.length > 0) {
    options = [
      ...categories,
      {
        category_name: "Manage Categories",
        category_icon: "SettingsOutlined",
        handler: handleToggleCategory,
      },
    ];
  }
  async function handleDelete() {
    dispatch({
      type: LOADING,
    });
    try {
      await api.deleteChallenge(challenge.id);
      const res = await api.getAdminChallenges();
      dispatch({
        type: GET_CHALLENGES,
        data: res.data.challenges,
      });
      const sideBarCategories = build_categories(
        res.data.challenges,
        categories
      );
      dispatch({
        type: SIDEBAR_CATEGORIES,
        data: sideBarCategories,
      });
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Challenge deleted`,
          type: "success",
        },
      });
      history.goBack();
    } catch (err) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err.response.data.status,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  function handleChallengeName(name) {
    const len = name.length - 1;
    if (!name.match(/^[0-9a-zA-Z_ ]+$/) && name[len]) {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `' ${name[len]} ' not allowed`,
          type: "error",
        },
      });
    } else {
      setname(name);
    }
  }

  function getInfo() {
    if (deployments) {
      const info = deployments.find((d) => d.cid === challenge.id);
      return info;
    }
    return null;
  }
  return (
    <Switch>
      <Route path={`/dashboard/challenges/${type}`}>
        <Route path={`/dashboard/challenges/${type}/adddeployment`}>
          <Toggle>
            <AddDeployment id={challenge.id} />
          </Toggle>
        </Route>
        <Route path={`/dashboard/challenges/${type}/info`}>
          <Toggle>
            <Info
              info={getInfo()}
              id={challenge.id}
              redirect={`/dashboard/challenges/${type}/adddeployment`}
            />
          </Toggle>
        </Route>
        <Route path={`/dashboard/challenges/${type}/newcategory`}>
          <NewCategory type={type} />
        </Route>
        <Route path={`/dashboard/challenges/${type}/medialibrary`}>
          <MediaLibrary
            type={type}
            route="challenges"
            onInsert={(logo) =>
              setdescription(
                `${description} ![Image](${encodeURI(logo.trim())})`
              )
            }
          />
        </Route>
        <Route exact path={`/dashboard/challenges/${type}`}>
          <div className="m_p-2">
            <Input
              label="CHALLENGE NAME*"
              placeholder="Enter Challenge Name"
              value={name}
              onChange={handleChallengeName}
            />
            <div
              className="m_event_editor m_mb-1"
              style={{ marginTop: "-1rem" }}
            >
              <label className="m_d-flex align-items-center justify-content-between">
                <h6 className="m_spaced" style={{ marginLeft: "0.3rem" }}>
                  EVENT DESCRIPTION
                </h6>
                <div
                  onClick={() =>
                    history.push(`/dashboard/challenges/${type}/medialibrary`)
                  }
                  className="m_media_libray_markdown"
                >
                  <div className="m_icon m_icon__sm">
                    <AddPhotoAlternateOutlinedIcon />
                  </div>
                </div>
              </label>

              <MarkdownEditor
                height={200}
                value={description}
                onChange={setdescription}
              />
            </div>

            <Select
              label="CATEGORY*"
              options={options}
              placeholder="Select Category"
              value={category}
              onChange={setcategory}
            />
            <Select
              label="DIFFICULTY*"
              placeholder="Select difficulty"
              value={difficulty}
              options={difficulty_options}
              onChange={setdifficulty}
            />

            <Input
              label="ENDPOINT"
              placeholder="Enter endpoint"
              value={endpoint}
              onChange={setendpoint}
            />
            <Input
              label="points*"
              type="number"
              placeholder="Enter points"
              value={points}
              onChange={setpoints}
            />

            <Input
              label={`decay threshold ${dynamic ? "*" : ""}`}
              placeholder="decay threshold"
              type="number"
              value={min_points_threshold}
              onChange={setmin_points_threshold}
              disabled={!dynamic}
            />
            <Input
              label={`minimum points ${dynamic ? "*" : ""}`}
              type="number"
              placeholder="minimum points"
              value={min_points}
              onChange={setmin_points}
              disabled={!dynamic}
            />
            <Input
              label="flag*"
              placeholder="Enter flag"
              value={flag}
              onChange={setflag}
            />

            <InputFile
              label="LOGO"
              file={uploadFiles1}
              onChange={setUploadFile1}
              onClick={loading1 ? null : uploadLogo}
              btn={`${loading1 ? "Uploading" : "Upload file"} `}
              id="challenge_logo"
              loading={loading1}
              value={challenge_logo}
              tag="(160 × 160)"
            />
            <div className="m_flex-1">
              <Input
                placeholder="Or Enter File Url"
                value={challenge_logo}
                onChange={setchallenge_logo}
              />
            </div>
            <InputFile
              label="File"
              onClick={loading2 ? null : uploadFile}
              btn={`${loading2 ? "Uploading" : "Upload file"} `}
              file={uploadFiles2}
              onChange={setUploadFile2}
              id="challenge_file"
              loading={loading2}
              value={file}
            />
            <Input
              placeholder="Or Enter File Url"
              value={file}
              onChange={setfile}
            />
            <div className="m_d-flex m_justify-content-evenly m_mt-1 m_mb-2">
              <Checkbox
                label="dynamic"
                checked={dynamic}
                onChange={() => setdynamic(!dynamic)}
                id="challenge_dynamic"
              />
              <Checkbox
                label="hidden"
                checked={hidden}
                onChange={() => sethidden(!hidden)}
                id="challenge_hidden"
              />
            </div>

            <div className="m_text-center m_mt-1 m_mb-1">
              <button
                onClick={!loading ? handleSubmit : null}
                className={`m_btn  ${
                  loading ? "m_tab-btn" : "m_btn m_btn--medium"
                }`}
              >
                {loading ? "Submitting" : "Submit"}
              </button>
            </div>
            {type === "update" && (
              <div className="m_text-center m_mt-2">
                <button
                  onClick={handleDelete}
                  className="m_btn m_btn--secondary"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </Route>
      </Route>
    </Switch>
  );
}

export default Challenge;
