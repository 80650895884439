import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddAnnouncements from "./AddAnnouncement";
import orderBy from "lodash/orderBy";
import { Switch, Route } from "react-router-dom";
import {
    GET_ANNOUNCEMENTS,
    SEARCH,
    PaginationComponent,
    Toggle,
    api,
    headers,
    LOADING,
    ALERT,
    SmLoading,
} from "../../";
const TableBody = ({ paginateItems }) => {
    const dispatch = useDispatch();
    const [id, setID] = useState("");
    async function handleDelete(id) {
        dispatch({
            type: LOADING,
        });
        try {
            await api.deleteAnnouncement(id);
            const res = await api.getAnnouncement();
            dispatch({
                type: GET_ANNOUNCEMENTS,
                data: res.data.announcements,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Announcement deleted",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
        }
    }
    function handleClick(new_id) {
        if (id === new_id) {
            setID("");
        } else {
            setID(new_id);
        }
    }
    return (
        <div className="m_table_data">
            {paginateItems.map((a, index) => (
                <div
                    key={index}
                    onClick={() => handleClick(a.announcement_id)}
                    className="m_table_data_item white-space"
                >
                    <div className="m_log_row flex-column">
                        <div className="m_d-flex">
                            <div
                                className="m_flex-1"
                                style={{ padding: "0.5rem 0" }}
                            >
                                {a.title}
                            </div>
                            <div
                                onClick={() => handleDelete(a.announcement_id)}
                                className="m_icon m_icon_light"
                            >
                                <DeleteOutlinedIcon />
                            </div>
                        </div>
                        {id === a.announcement_id && <div>{a.message}</div>}
                    </div>
                </div>
            ))}
        </div>
    );
};
function AdminAnnouncements() {
    const dispatch = useDispatch();
    const announcements = useSelector((state) => state.announcements);
    const sortedAnnouncements = orderBy(
        announcements,
        ["announcement_time"],
        ["desc"]
    );
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const res = await api.getAnnouncement();
                dispatch({
                    type: GET_ANNOUNCEMENTS,
                    data: res.data.announcements,
                });
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "announcements",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch]);
    return (
        <div className="m_announcement_container">
            {loading ? (
                <SmLoading />
            ) : (
                <Switch>
                    <Route path="/dashboard/announcements/add">
                        <Toggle>
                            <AddAnnouncements />
                        </Toggle>
                    </Route>
                    <Route path="/dashboard/announcements">
                        <PaginationComponent
                            heading="ANNOUNCEMENTS"
                            headers={headers.announcements}
                            items={sortedAnnouncements}
                            TableBody={(items) => (
                                <TableBody paginateItems={items} />
                            )}
                        />
                    </Route>
                </Switch>
            )}
        </div>
    );
}

export default AdminAnnouncements;
