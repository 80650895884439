import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { api, Input, Checkbox, GET_TEAMS, LOADING, ALERT } from "../../";
function TeamEdit({ handleToggle, team }) {
    const dispatch = useDispatch();
    const [team_token, setteam_token] = useState(team.team_token || "");
    const [team_name, setteam_name] = useState(team.team_name || "");
    const [banned, setBanned] = useState(team.banned || false);
    const [hidden, setHidden] = useState(team.hidden || false);
    const handleSubmit = async () => {
        dispatch({
            type: LOADING,
            data: true,
        });
        const teamObj = {
            id: team.id,
            team_name,
            team_token,
            banned,
            hidden,
        };
        try {
            await api.updateTeam(teamObj);
            const res = await api.getTeams();
            dispatch({
                type: GET_TEAMS,
                data: res.data.teams,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Changes applied",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            handleToggle();
            dispatch({
                type: LOADING,
                data: true,
            });
        }
    };

    function handleTeamName(teamname) {
        const len = teamname.length - 1;
        if (!teamname.match(/^[0-9a-zA-Z_ ]+$/) && teamname[len]) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `' ${teamname[len]} ' not allowed`,
                    type: "error",
                },
            });
        } else {
            setteam_name(teamname);
        }
    }

    return (
        <>
            <Input
                label="TEAM NAME"
                placeholder="Enter team name"
                value={team_name}
                onChange={handleTeamName}
            />
            <Input
                label="TEAM TOKEN"
                placeholder="Enter team token"
                value={team_token}
                onChange={setteam_token}
            />
            <div className="d-flex justify-content-evenly mt-1 mb-1">
                <Checkbox
                    checked={hidden}
                    onChange={() => setHidden(!hidden)}
                    label="hidden"
                    id="team_hidden"
                />
                <Checkbox
                    checked={banned}
                    onChange={() => setBanned(!banned)}
                    label="banned"
                    id="team_banned"
                />
            </div>
            <div className="text-center">
                <button onClick={handleSubmit} className="btn btn--medium">
                    Submit
                </button>
            </div>
        </>
    );
}

export default TeamEdit;
