import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SETTING, LOADING, api, Input, ALERT } from "../../";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { KeyboardBackspace } from "@material-ui/icons";

function ForgotPassword({ site_key }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const history = useHistory();
  async function handleLogin() {
    if (username === "") {
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `${username === "" ? "Username" : ""} is required`,
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: LOADING,
    });
    try {
      var data = {
        username,
      };
      if (site_key) {
        const g_recaptcha_response = await executeRecaptcha("forgot_password");
        data = { ...data, g_recaptcha_response };
      }
      await api.forgetpassword(data);
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: `Email sent to ${username}`,
          type: "success",
        },
      });
    } catch (error) {
      const err = error.response && error.response.data.status;
      dispatch({
        type: ALERT,
        data: {
          open: true,
          message: err,
          type: "error",
        },
      });
    } finally {
      dispatch({
        type: LOADING,
      });
    }
  }
  useEffect(() => {
    const newApp = {};
    newApp.dashboard = true;
    dispatch({
      type: SETTING,
      data: newApp,
    });
  }, [dispatch]);
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <>
      <div className="d-flex mt-2 mb-2 flex-column">
        <h2 style={{ marginBottom: "5px" }}>
          <strong>Forgot Password?</strong>
        </h2>
        <h5>Enter your registered email ID to reset the password</h5>
      </div>
      <Input
        label="email / username"
        placeholder="username"
        value={username}
        onChange={setUserName}
        onKeyDown={handleKeyDown}
      />

      <div className="m_text-center">
        <button onClick={handleLogin} className="btn btn--medium mv-1">
          Send
        </button>
        {/* <h5>
          Not a member?
          <strong>
            <Link to="/auth/register">&nbsp;Register</Link>
          </strong>
        </h5> */}
      </div>
      <div className="center mt-2 pointer" onClick={() => history.goBack()}>
        <KeyboardBackspace
          style={{
            fontSize: "30px",
            marginRight: "5px",
          }}
        />
        <h3>Back to Login</h3>
      </div>
    </>
  );
}

export default ForgotPassword;
