import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ALERT } from "../../";
import { useDispatch } from "react-redux";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
function TeamProfile({
    rank,
    points,
    challenges_solved,
    team_name,
    team_members,
    team,
    total_challenges,
    show_rank,
    handleToggle,
}) {
    const dispatch = useDispatch();
    var c = 0;
    var t = 0;
    if (challenges_solved) {
        c = challenges_solved;
    }
    if (total_challenges) {
        t = total_challenges;
    }
    const perChallenges = `${c <= 9 ? "0" + c : c}/${t <= 9 ? "0" + t : t}`;
    const handleCopy = () => {
        navigator.clipboard.writeText(team.teamtoken);
        dispatch({
            type: ALERT,
            data: {
                open: true,
                message: "Copied",
                type: "success",
            },
        });
    };
    return (
        <div className="m_card__container">
            <div className="m_responsive_profile">
                <div className="m_d-flex">
                    <div
                        style={{ lineHeight: "3rem" }}
                        className="d-flex flex-column"
                    >
                        {" "}
                        <div className="d-flex align-items-center">
                            <h2>{team_name}</h2>
                            <div
                                onClick={handleToggle}
                                className="icon icon__primary ml-1"
                            >
                                <CreateOutlinedIcon />
                            </div>
                        </div>
                        <h5>Team members : {team_members} </h5>
                        <div className="d-flex align-items-center">
                            {team.teamname === team_name && (
                                <>
                                    <h5>
                                        Team Token :
                                        ******************************
                                    </h5>
                                    <div
                                        onClick={handleCopy}
                                        className="m_icon m_icon__sm m_ml-1"
                                    >
                                        <FileCopyOutlinedIcon />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="m_d-flex justify-content-between mt-1">
                    {show_rank && (
                        <div className="m_flex-1">
                            <div style={{ justifySelf: "flex-end" }}>
                                <h6 className="m_spaced mb-1">RANK</h6>
                            </div>
                            <h2>
                                <strong>
                                    #{rank <= 9 ? "0" + rank : rank || "00"}
                                </strong>
                            </h2>
                        </div>
                    )}
                    <div className="m_flex-1">
                        <div style={{ justifySelf: "flex-end" }}>
                            <h6 className="m_spaced mb-1">POINTS</h6>
                        </div>
                        <h2>
                            <strong>
                                {points <= 9 ? "0" + points : points || "00"}
                            </strong>
                        </h2>
                    </div>
                    <div className="m_flex-1">
                        <div style={{ justifySelf: "flex-end" }}>
                            <h6
                                className="m_spaced mb-1"
                                style={{
                                    whiteSpace: "nowrap",
                                }}
                            >
                                SOLVES
                            </h6>
                        </div>
                        <h2>
                            <strong>{perChallenges}</strong>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamProfile;
