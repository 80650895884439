import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, GET_USERS_LOGS, TableHeader, searchData, headers } from "../../";
function SubscriptionLogs({ search }) {
  const dispatch = useDispatch();
  const userLogs = useSelector((state) => state.userLogs);
  const [id, setID] = useState("");
  const filteredUsers = searchData(search, userLogs);
  useEffect(() => {
    async function fetch() {
      try {
        const res = await api.getSubscriptions();
        let logs = res.data.subscriptions;
        dispatch({
          type: GET_USERS_LOGS,
          data: logs,
        });
      } finally {
      }
    }
    fetch();
  }, [dispatch]);
  return (
    <>
      <TableHeader headers={headers.subscriptionLogs} />
      <div className="table_data">
        {filteredUsers &&
          filteredUsers.map((u, index) => (
            <div
              key={index}
              onClick={() => setID(u.id)}
              className={`table_data_item ${id === u.id ? "white-space" : ""}`}
            >
              <div className="flex-1">{u.username}</div>
              <div className="flex-1">{u.plan_name}</div>
              <div className="flex-1">{u.order_id}</div>
              <div className="flex-1">{u.payment_id}</div>
              <div style={{ flex: 1.7 }}>{u.payment_status}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default SubscriptionLogs;
