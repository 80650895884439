import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { GET_DEPLOYMENTS, Input, api, LOADING, ALERT, SEARCH } from "../../";
import { useHistory } from "react-router-dom";
function AddDeployment({ id }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [docker_image, setdocker_image] = useState("");
    const [container_port, setcontainer_port] = useState("");
    useEffect(() => {
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "add deployment",
                icon: true,
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: {
                    value: "",
                    placeholder: "update challenge",
                    setting: true,
                    icon: true,
                },
            });
    }, [dispatch, id]);
    const handleAddChallengeDeployment = async () => {
        try {
            dispatch({
                type: LOADING,
            });
            await api.addChallengeDeployment({
                cid: id,
                docker_image,
                container_port: parseInt(container_port),
            });
            const res = await api.getChallengesDeployments();
            dispatch({
                type: GET_DEPLOYMENTS,
                data: res.data.challenge_deployments,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment created`,
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `Deployment failed`,
                    type: "error",
                },
            });
        } finally {
            history.goBack();
            dispatch({
                type: LOADING,
            });
        }
    };
    return (
        <>
            <Input
                label="DOCKER IMAGE"
                placeholder="Docker image"
                value={docker_image}
                onChange={setdocker_image}
            />
            <Input
                label="CONTAINER PORT"
                placeholder="Container port"
                value={container_port}
                onChange={setcontainer_port}
            />

            <div className="m_text-center m_mt-2">
                <button
                    onClick={handleAddChallengeDeployment}
                    className="m_btn m_btn--medium"
                >
                    Submit
                </button>
            </div>
        </>
    );
}

export default AddDeployment;
