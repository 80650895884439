import React from "react";

import { useHistory } from "react-router";
import not_found from "../assets/error.png";
function ErrorPage() {
    const history = useHistory();
    return (
        <div className="not-found_container">
            <div className="flex-1">
                <div className="text">
                    <h2>We’re sorry this happened to you!</h2>
                    <h3>
                        We apologize for the inconvenience caused. We’re working
                        on this issue and will fix it soon.
                    </h3>
                    <div
                        onClick={() => history.push("/")}
                        className="btn btn--medium"
                    >
                        Go Home
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <img src={not_found} alt="" />
            </div>
        </div>
    );
}

export default ErrorPage;
