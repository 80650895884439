import { useState } from "react";
import Input from "./Input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
function InputPassword({ ...rest }) {
    const [type, setType] = useState("password");
    function handleType() {
        if (type === "password") setType("text");
        else {
            setType("password");
        }
    }
    return (
        <Input type={type} {...rest} className="input__icon">
            <div className="input-icon">
                <div onClick={handleType}>
                    {type === "password" ? (
                        <VisibilityIcon />
                    ) : (
                        <VisibilityOffIcon />
                    )}
                </div>
            </div>
        </Input>
    );
}

export default InputPassword;
