import React from "react";
import { TableHeading } from "../../";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useLocation, useHistory } from "react-router";
function ChangeProfile({ profile }) {
    const history = useHistory();
    const { pathname } = useLocation();
    return (
        <div className="m_card__container m_mb-2">
            <TableHeading heading="user settings" />
            <div className="m_settings__user">
                <div className="m_settings_username">
                    <h4>Username</h4>
                    <h4 className="m_ml-2">{profile.username}</h4>
                </div>
                <div
                    onClick={() => history.push(`${pathname}/changeusername`)}
                    className="m_icon m_icon__sm"
                >
                    <EditOutlinedIcon />
                </div>
            </div>
            <div className="m_settings__user">
                <div className="m_settings_username">
                    <h4>Password</h4>
                    <h4 className="m_ml-2">*********</h4>
                </div>
                <div
                    onClick={() => history.push(`${pathname}/changepassword`)}
                    className="m_icon m_icon__sm"
                >
                    <EditOutlinedIcon />
                </div>
            </div>
        </div>
    );
}

export default ChangeProfile;
