import React from "react";
import { ALERT } from "../";
import { useDispatch } from "react-redux";
const max_size = 32 * 1024 * 1024;
function InputFile({
    className,
    label,
    file,
    onChange,
    onClick,
    btn,
    id,
    className2,
    value,
    loading,
    label__side = null,
    show = true,
    tag = "No file chosen",
}) {
    const dispatch = useDispatch();
    return (
        <div className={`input-group-container ${className ? className : ""}`}>
            {(label || label === "") && (
                <label>
                    <h6 className="spaced"> {label}</h6>
                </label>
            )}
            <div className="input-group-icon-container">
                {(label__side || label__side === "") && (
                    <label className="event_label_side">
                        <h3>{label__side}</h3>
                    </label>
                )}
                <div className="input-group-icon event_responsive_file">
                    <div
                        className={`input-group ${
                            className2 ? className2 : ""
                        }`}
                    >
                        <input
                            hidden
                            type="file"
                            id={id}
                            onChange={(e) => {
                                if (e.target.files[0].size > max_size) {
                                    dispatch({
                                        type: ALERT,
                                        data: {
                                            open: true,
                                            message:
                                                "File size cannot exceed 32 MB",
                                            type: "warning",
                                        },
                                    });

                                    return;
                                }
                                onChange(e.target.files[0]);
                            }}
                        />
                        <div className="filenames">
                            {show && (
                                <div className="flex-1">
                                    <h5 className="over_flow">
                                        {value
                                            ? value.split("-").pop()
                                            : "No file uploded"}
                                    </h5>
                                </div>
                            )}
                            <div
                                style={{
                                    textAlign: `${show ? "right" : "left"}`,
                                    flex: 1,
                                    marginLeft: "1rem",
                                }}
                            >
                                <h5>{file ? file.name : tag}</h5>
                            </div>
                        </div>
                        <div className="input-file">
                            <label htmlFor={id} className="btn btn--medium">
                                Choose&nbsp;file
                            </label>
                            <button
                                onClick={onClick}
                                className={`btn ${
                                    loading ? "tab-btn" : "btn btn--medium"
                                }`}
                                style={{
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {btn}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InputFile;
