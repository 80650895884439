import React from "react";

import cup from "../../assets/cup.svg";
function Congratulation({ handleToggle }) {
  return (
    <div className="m_toggle">
      <div className="m_toggle-container">
        <div
          className="m_toggle_card animate__fadeInUp"
          style={{ background: "var(--dark)" }}
        >
          <div className="m_d-flex m_align-items-center m_flex-column">
            <div style={{ width: "11rem" }}>
              <img src={cup} alt="" />
            </div>
            <h2>Congratulations</h2>
            <h3>You've completed this challenge.</h3>
            <button
              onClick={handleToggle}
              className="m_btn m_btn--medium"
              style={{ margin: "2rem 0" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congratulation;
