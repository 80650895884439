import React from "react";
import range from "lodash/range";
function Loading({ height }) {
  const spans = range(1, 20);
  const _height = height ? height + 98 : "100vh";
  return (
    <section className="m_section" style={{ height: _height }}>
      <div className="loader">
        {spans.map((span, index) => (
          <span key={index} style={{ "--i": span }}></span>
        ))}
      </div>
      <div className="loading">
        <p>Loading</p>
      </div>
    </section>
  );
}

export default Loading;
