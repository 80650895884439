const headers = {
  announcements: ["Title", "Message", "Action"],
  users: ["Username", "Team", "Actions"],
  challenges: [
    "Name",
    "",
    "Category",
    "Points",
    "Difficulty",
    "Deployment",
    "Actions",
  ],
  plans: ["Name", "Description", "Price", "Enabled", "Days", "Actions"],
  ranks: ["Rank", "Team", "Points"],
  teams: ["Team Name", "Actions"],
  usersLogs: ["Time", "User", "Message", "IP Address"],
  adminLogs: ["Time", "Admin", "Message", "IP Address"],
  subscriptionLogs: [
    "Username",
    "Plan Name",
    "Order",
    "Payment",
    "Payment Status",
  ],
  challengeLogs: ["Time", "User", "Team", "Challenge", "Message", "IP Adress"],
};
export default headers;
