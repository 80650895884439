import "date-fns";
import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
export default function Mui_DateTimePicker({
    value,
    onChange,
    id1,
    id2,
    label,
}) {
    return (
        <div className="m_flex-1">
            <label
                className="label m_d-flex"
                style={{ marginBottom: "0.5rem" }}
            >
                <h6 className="m_spaced">{label}</h6>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="m_d-flex flex-column">
                    <KeyboardDatePicker
                        id={id1}
                        format="MM/dd/yyyy"
                        value={new Date(value * 1000)}
                        onChange={onChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        keyboardIcon={<EventAvailableIcon />}
                    />
                    <KeyboardTimePicker
                        id={id2}
                        value={new Date(value * 1000)}
                        onChange={onChange}
                        ampm={false}
                        KeyboardButtonProps={{
                            "aria-label": "change time",
                        }}
                        style={{ marginTop: "1rem", flex: 1 }}
                        keyboardIcon={<AccessTimeIcon />}
                    />
                </div>
            </MuiPickersUtilsProvider>
        </div>
    );
}
