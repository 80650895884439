import React from "react";
function Input({
    className,
    onChange,
    children,
    label = null,
    label__side = null,
    value,
    placeholder,
    handler,
    name,
}) {
    return (
        <div className={`input-group-container ${className}`}>
            {(label || label === "") && (
                <label>
                    <h6 className="spaced"> {label}</h6>
                </label>
            )}
            <div className="input-group-icon-container">
                {(label__side || label__side === "") && (
                    <label>
                        <h3>{label__side}</h3>
                    </label>
                )}
                <div className="input-group-icon">
                    <div className="input-group">
                        <textarea
                            name={name}
                            className="input"
                            placeholder={placeholder}
                            value={value}
                            onChange={
                                handler
                                    ? onChange
                                    : (e) => onChange(e.target.value)
                            }
                        />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default Input;
