import React from "react";
import range from "lodash/range";
function Loading() {
    const spans = range(1, 20);
    return (
        <section className="section">
            <div className="loader">
                {spans.map((span, index) => (
                    <span key={index} style={{ "--i": span }}></span>
                ))}
            </div>
            <div className="loading">
                <p>Loading</p>
            </div>
        </section>
    );
}

export default Loading;
