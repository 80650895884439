import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import Input from "./Input";
function ColorPickInput({ onChange, value, label__side, label }) {
    const [togglePicker, setTogglePicker] = useState("");
    const [color, setColor] = useState(value);
    const [rgb, setRgb] = useState(hexToRgb(value));
    function handlePicker() {
        setTogglePicker(!togglePicker);
    }
    function hexToRgb() {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
            c = color.substring(1).split("");
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = "0x" + c.join("");
            const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255];
            return rgb;
        }
    }
    const handleChange = (color) => {
        setColor(color);
        setRgb(hexToRgb(color));
    };
    function handleConfirm() {
        onChange(color);
        handlePicker();
    }
    return (
        <div className="color-picker-container">
            <Input
                label__side={label__side}
                label={label}
                placeholder="Choose Color"
                value={value}
                onChange={handleChange}
                name="accent_color"
            >
                <div
                    onClick={handlePicker}
                    style={{ background: value }}
                    className="color-display"
                />
                {togglePicker && (
                    <div className="color-picker">
                        <div style={{ background: color }} className="preview">
                            <h3>Preview</h3>
                        </div>
                        <HexColorPicker color={color} onChange={handleChange} />
                        <div className="mt-1">
                            <div className="d-flex">
                                <h3 className="mr-1">Hex</h3>
                                <Input
                                    placeholder="Choose Color"
                                    value={color}
                                    onChange={handleChange}
                                    name="accent_color"
                                />
                            </div>
                            <div className="d-flex">
                                <h3 className="mr-1">RGB</h3>
                                <Input
                                    value={rgb && rgb[0]}
                                    name="accent_color"
                                />
                                <Input
                                    value={rgb && rgb[1]}
                                    name="accent_color"
                                />
                                <Input
                                    value={rgb && rgb[2]}
                                    name="accent_color"
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <button
                                onClick={handlePicker}
                                style={{ width: "10rem" }}
                                className="btn btn--medium"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirm}
                                style={{ width: "10rem" }}
                                className="btn btn--medium"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                )}
            </Input>
        </div>
    );
}

export default ColorPickInput;
