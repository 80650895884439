import { useEffect } from "react";
import { Redirect } from "react-router-dom";
function AutoUpload({ upload, url }) {
    useEffect(() => {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = async (e) => {
            var file = e.target.files[0];
            upload(file);
        };
        input.click();
    }, [upload]);
    return <Redirect to={url} />;
}

export default AutoUpload;
