import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddAnnouncements from "./AddAnnouncement";
import orderBy from "lodash/orderBy";
import {
    GET_ANNOUNCEMENTS,
    SEARCH,
    PaginationComponent,
    Toggle,
    api,
    headers,
    LOADING,
    ALERT,
    SmLoading,
} from "../../";
const TableBody = ({ paginateItems }) => {
    const dispatch = useDispatch();
    const [id, setID] = useState("");
    async function handleDelete(id) {
        dispatch({
            type: LOADING,
        });
        try {
            await api.deleteAnnouncement(id);
            const res = await api.getAnnouncement();
            dispatch({
                type: GET_ANNOUNCEMENTS,
                data: res.data.announcements,
            });
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Announcement deleted",
                    type: "success",
                },
            });
        } catch (err) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: err.response.data.status,
                    type: "error",
                },
            });
        } finally {
            dispatch({
                type: LOADING,
            });
        }
    }

    return (
        <div className="table_data">
            {paginateItems.map((a, index) => (
                <div
                    key={index}
                    onClick={() => setID(a.announcement_id)}
                    className={`table_data_item ${
                        id === a.announcement_id ? "white-space" : ""
                    }`}
                >
                    <div style={{ flex: 0.3 }}>{a.title}</div>
                    <div
                        style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                            flex: 1,
                        }}
                    >
                        {a.message}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{ flex: 0.2 }}
                    >
                        <div
                            onClick={() => handleDelete(a.announcement_id)}
                            className="icon__primary"
                        >
                            <DeleteOutlinedIcon />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
function AdminAnnouncements() {
    const dispatch = useDispatch();
    const announcements = useSelector((state) => state.announcements);
    const sortedAnnouncements = orderBy(
        announcements,
        ["announcement_time"],
        ["desc"]
    );
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const res = await api.getAnnouncement();
                dispatch({
                    type: GET_ANNOUNCEMENTS,
                    data: res.data.announcements,
                });
            } catch (err) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
        dispatch({
            type: SEARCH,
            data: {
                value: "",
                placeholder: "search announcement",
            },
        });
        return () =>
            dispatch({
                type: SEARCH,
                data: { value: "", placeholder: "" },
            });
    }, [dispatch]);
    const [toggle, setToggle] = useState(false);
    const handleToggle = () => setToggle(!toggle);

    return (
        <div className="announcement_container">
            {toggle && (
                <Toggle handleToggle={handleToggle}>
                    <AddAnnouncements handleToggle={handleToggle} />
                </Toggle>
            )}

            {loading ? (
                <SmLoading />
            ) : (
                <PaginationComponent
                    heading="ANNOUNCEMENTS"
                    headers={headers.announcements}
                    items={sortedAnnouncements}
                    pageSize={18}
                    TableBody={TableBody}
                    handleToggle={handleToggle}
                />
            )}
        </div>
    );
}

export default AdminAnnouncements;
