import React, { useState } from "react";
import { api, Input, InputPassword, SETTING, ALERT } from "../../../";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
function ChangeUsername({ handleToggle }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const app = useSelector((state) => state.app);
    const history = useHistory();
    const dispatch = useDispatch();
    async function handleSubmit() {
        try {
            const res = await api.updateUsername({ username, password });
            if (res && res.data && res.data.status === "ok") {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: "Username Changed",
                        type: "success",
                    },
                });
                setUsername("");
                setPassword("");
                app.profile.username = username;
                dispatch({
                    type: SETTING,
                    data: app,
                });
                history.push(`/dashboard/profile/${username}`);
                handleToggle();
            }
        } catch (error) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: error.response.data.status,
                    type: "error",
                },
            });
        }
    }
    return (
        <div style={{ marginTop: "20%" }}>
            <Input
                label="new username"
                placeholder="New Username"
                value={username}
                onChange={setUsername}
            />
            <InputPassword
                label="Current Password"
                placeholder="Current Password"
                value={password}
                onChange={setPassword}
            />
            <div className="m_text-center mt-1">
                <button
                    onClick={() =>
                        handleSubmit({
                            username,
                            password,
                        })
                    }
                    className="m_btn m_btn--medium"
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default ChangeUsername;
