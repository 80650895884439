const themes = {
    "Ambient Black": {
        text_color: "#DDDDDD",
        light: "#303030",
        medium: "#151515",
        dark: "#000000",
    },
    "Charcoal Black": {
        text_color: "#FFFFFF",
        light: "#444444",
        medium: "#323232",
        dark: "#202020",
    },
    "Chocolate Brown": {
        text_color: "#FFFFFF",
        light: "#87414C",
        medium: "#591A1E",
        dark: "#2E0F0F",
    },
    "Classic White": {
        text_color: "#000000",
        light: "#FFFFFF",
        medium: "#EEEEEE",
        dark: "#CACACA",
    },
    Dracula: {
        text_color: "#F8F8F8",
        light: "#8666B5",
        medium: "#44475A",
        dark: "#282A36",
    },
    "Frozen Blue": {
        text_color: "#FFFFFF",
        light: "#4B82B2",
        medium: "#1E5280",
        dark: "#043C59",
    },
    "Military Green": {
        text_color: "#FFFFFF",
        light: "#929B7A",
        medium: "#6E7657",
        dark: "#4D5340",
    },
    "Pacific Blue": {
        text_color: "#DDDDDD",
        light: "#323D5B",
        medium: "#222B45",
        dark: "#151B30",
    },
    "Silicone Green": {
        text_color: "#FFFFFF",
        light: "#62949A",
        medium: "#3F7076",
        dark: "#2E4F56",
    },
    "Slate Grey": {
        text_color: "#FFFFFF",
        light: "#656B75",
        medium: "#393E46",
        dark: "#222831",
    },
    Custom: {
        text_color: "#FFFFFF",
        light: "#656B75",
        medium: "#393E46",
        dark: "#222831",
    },
};

export default themes;
