import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
        position: "absolute",
        top: "50%",
        left: "50%",
        color: "red",
        transform: "translate(-50%,-50%)",
    },
}));

export default function CircularIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress style={{ color: "var(--light)" }} />
        </div>
    );
}
