import { useState } from "react";
import { Input, api } from "../../";
import { useHistory } from "react-router";
import { ALERT, ToolTip } from "../../";
import { useDispatch } from "react-redux";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
function CreateTeam() {
    const [teamName, setTeamName] = useState("");
    const [_teamName, set_TeamName] = useState("");
    const [message, setMessage] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const handleCreateTeam = async () => {
        if (!teamName) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: "Please enter team name",
                    type: "error",
                },
            });
            return;
        }
        api.createTeam({ team_name: teamName })
            .then((res) => {
                setMessage(res.data);
                set_TeamName(teamName);
                setTeamName("");
            })
            .catch((err) => {
                dispatch({
                    type: ALERT,
                    data: {
                        open: true,
                        message: err.response.data.status,
                        type: "error",
                    },
                });
            });
    };

    function handleTeamName(teamname) {
        const len = teamname.length - 1;
        if (!teamname.match(/^[0-9a-zA-Z_ ]+$/) && teamname[len]) {
            dispatch({
                type: ALERT,
                data: {
                    open: true,
                    message: `' ${teamname[len]} ' not allowed`,
                    type: "error",
                },
            });
        } else {
            setTeamName(teamname);
        }
    }
    function handleKeyDown(e) {
        if (e.key === "Enter") {
            handleCreateTeam();
        }
    }
    return (
        <div className="auth__container">
            <div className="card__primary auth__inputs">
                <Input
                    label="TEAM NAME"
                    type="text"
                    placeholder="Team Name"
                    value={teamName}
                    onChange={handleTeamName}
                    onKeyDown={handleKeyDown}
                />

                <button
                    onClick={!message ? handleCreateTeam : ""}
                    className={`m_btn  ${
                        message ? "m_tab-btn" : "m_btn m_btn--medium"
                    }`}
                >
                    Create Team
                </button>

                {message && (
                    <>
                        <div className="mt-2 ml-1">
                            <h5>
                                <strong>Team Name : </strong>
                                {_teamName}
                            </h5>
                            <div className="d-flex align-items-center">
                                <h5>
                                    <strong>Team Token : </strong>
                                    {message.team_token}
                                </h5>
                                <ToolTip n={2} item="Copy token">
                                    <div
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                message.team_token
                                            );
                                            dispatch({
                                                type: ALERT,
                                                data: {
                                                    open: true,
                                                    message: "Copied",
                                                    type: "success",
                                                },
                                            });
                                        }}
                                        className="ml-1 m_icon m_icon__sm"
                                    >
                                        <FileCopyOutlinedIcon />
                                    </div>
                                </ToolTip>
                            </div>
                        </div>

                        <button
                            onClick={() =>
                                history.push("/dashboard/categories")
                            }
                            className="m_btn m_btn--medium mt-1"
                        >
                            Dashboard
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default CreateTeam;
