import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    api,
    GET_USERS,
    GET_TEAMS,
    GET_CHALLENGES,
    SmLoading,
    StatisticsCard,
    SEARCH,
} from "../../";

function Statistics() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teams, categories, challenges, users, config] = useSelector(
        (state) => [
            state.teams,
            state.categories,
            state.challenges,
            state.users,
            state.config,
        ]
    );
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                let res = await api.getUsers();
                if (res.data.users)
                    dispatch({
                        type: GET_USERS,
                        data: res.data.users,
                    });
                else
                    dispatch({
                        type: GET_USERS,
                        data: [],
                    });
                if (config.team_mode) {
                    res = await api.getTeams();
                    if (res.data.teams)
                        dispatch({
                            type: GET_TEAMS,
                            data: res.data.teams,
                        });
                    else
                        dispatch({
                            type: GET_TEAMS,
                            data: [],
                        });
                }
                res = await api.getAdminChallenges();
                if (res.data.challenges)
                    dispatch({
                        type: GET_CHALLENGES,
                        data: res.data.challenges,
                    });
                else
                    dispatch({
                        type: GET_CHALLENGES,
                        data: [],
                    });

                dispatch({
                    type: SEARCH,
                    data: {
                        value: "",
                        placeholder: "statistics",
                        icon: true,
                    },
                });
                return () =>
                    dispatch({
                        type: SEARCH,
                        data: { value: "", placeholder: "" },
                    });
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
        fetch();
    }, [dispatch, config.team_mode]);
    return (
        <div className="m_flex-1 flex-column d-flex">
            {loading ? (
                <SmLoading />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {config.team_mode && (
                        <StatisticsCard
                            text={teams ? teams.length : 0}
                            label="teams"
                        />
                    )}
                    <StatisticsCard
                        text={users ? users.length : 0}
                        label="users"
                    />

                    <StatisticsCard
                        text={challenges ? challenges.length : 0}
                        label="challenges"
                    />
                    <StatisticsCard
                        text={categories ? categories.length : 0}
                        label="categories"
                    />
                </div>
            )}
        </div>
    );
}

export default Statistics;
