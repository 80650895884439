import React from "react";

function PrivacyPolicy() {
  return (
    <div className="markdown_content_container privacy_terms">
      <div className="markdown_content blur_bg">
        <h2>Privacy Policy</h2>
        <h5 className="mt-1">
          This Privacy Policy applies to our website, and its associated
          subdomains (collectively ,our “Services”) .By accessing or using our
          services ,you signify that you have read ,understood and agree to our
          collection ,storage ,use and disclosure of your personal information
          as described in this Privacy Policy and our Terms if Service
        </h5>
        <h5 className="mt-1">
          This Privacy Policy is an electronic record in the form of an
          electronic contract formed under the information Technology Act, 2000
          and the rules made thereunder and the amended provisions pertaining to
          electronic documents / records in various statutes as amended by the
          information Technology Act, 2000. This Privacy Policy does not require
          any physical, electronic or digital signature.
        </h5>
        <h5 className="mt-1">
          This Privacy Policy is a legally binding document between you and
          RedTeam Hacker Academy.
        </h5>
        <h5 className="mt-1">
          The terms of this Privacy Policy will be effective upon your
          acceptance of the same (directly or indirectly in electronic form, by
          clicking on the accept tab or by use of the website or by other means)
          and will govern the relationship between you and RedTeam Hacker
          Academy for your use of the website “Pentest Garage”.
        </h5>
        <h5 className="mt-1">
          This document is published and shall be construed in accordance with
          the provisions of the Information Technology (reasonable security
          practices and procedures and sensitive personal data of information)
          rules, 2011 under Information Technology Act, 2000; that require
          publishing of the Privacy Policy for collection, use, storage and
          transfer of sensitive personal data or information.
        </h5>
        <h5 className="mt-1">
          Please read this Privacy Policy carefully by using the Website, you
          indicate that you understand, agree and consent to this Privacy
          Policy. If you do not agree with the terms of this Privacy Policy,
          please do not use this Website.
        </h5>
        <h5 className="mt-1">
          By providing us your Information or by making use of the facilities
          provided by the Website, You hereby consent to the collection,
          storage, processing and transfer of any or all of Your Personal
          Information and Non-Personal Information by us as specified under this
          Privacy Policy. You further agree that such collection, use, storage
          and transfer of Your Information shall not cause any loss or wrongful
          gain to you or any other person.
        </h5>

        <h2 className="mt-1"> USER INFORMATION </h2>
        <h3>What information are collected from you?</h3>
        <h5>
          We get information that you provided for contacting us that includes
          your name, email address.
        </h5>
        <h3> </h3>

        <h3 style={{ margin: "10px 0" }}>
          How do we Use the information we collect?
        </h3>
        <ul>
          <li>
            <h5>
              To personalize your experience (your information helps us to
              better respond to your individual needs )
            </h5>
          </li>
          <li>
            <h5>
              To improve our service (we continuously strive to improve our
              service offerings based on the information and feedback we receive
              from you )
            </h5>
          </li>
          <li>
            <h5>
              To improve our customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </h5>
          </li>
          <li>
            <h5>To send periodic email</h5>
          </li>
          <li>
            <h5>To contact you</h5>
          </li>
        </ul>

        <h5 className="mt-1">
          All required information is service dependent and we may use the above
          said user information to, maintain, protect, and improve its services
          (including advertising services) and for developing new services
        </h5>
        <h5 className="mt-1">
          Such information will not be considered as sensitive if it is freely
          available and accessible in the public domain or is furnished under
          the Right to Information Act, 2005 or any other law for the time being
          in force.
        </h5>
        <h3>COOKIES</h3>
        <h5 className="mt-1">
          To improve the responsiveness of the sites for our users, we may use
          “cookies”, or similar electronic tools to collect information to
          assign each visitor a unique, random number as a User Identification
          (User ID) to understand the user’s individual interests using the
          Identified Computer. Unless you voluntarily identify yourself (through
          registration, for example), we will have no way of knowing who you
          are, even if we assign a cookie to your computer. The only personal
          information a cookie can contain is information you supply (an example
          of this is when you ask for our Personalised Horoscope). A cookie
          cannot read data off your hard drive. Our advertisers may also assign
          their own cookies to your browser (if you click on their ads), a
          process that we do not control.{" "}
        </h5>
        <h5 className="mt-1">
          Our web servers automatically collect limited information about your
          computer’s connection to the Internet, including your IP address, when
          you visit our site. (Your IP address is a number that lets computers
          attached to the Internet know where to send you data — such as the web
          pages you view.) Your IP address does not identify you personally. We
          use this information to deliver our web pages to you upon request, to
          tailor our site to the interests of our users, to measure traffic
          within our site and let advertisers know the geographic locations from
          where our visitors come.{" "}
        </h5>
        <h3>LINKS TO THE OTHER SITES</h3>
        <h5>
          Our policy discloses the privacy practices for our own web site only.
          Our site provides links to other websites also that are beyond our
          control. We shall in no way be responsible for your use of such sites.
        </h5>
        <h2 className="mt-2">INFORMATION SHARING</h2>
        <h3 className="mt-1">
          Do we share the information we collect with third parties?
        </h3>
        <h5>No</h5>

        <h5 className="mt-2">
          We shares the sensitive personal information to any third party
          without obtaining the prior consent of the user in the following
          limited circumstances:
        </h5>
        <h5 className="mt-1">
          (a) When it is requested or required by law or by any court or
          governmental agency or authority to disclose, for the purpose of
          verification of identity, or for the prevention, detection,
          investigation including cyber incidents, or for prosecution and
          punishment of offences. These disclosures are made in good faith and
          belief that such disclosure is reasonably necessary for enforcing
          these Terms; for complying with the applicable laws and regulations.{" "}
        </h5>
        <h5 className="mt-1">
          (b) We proposes to share such information within its group companies
          and officers and employees of such group companies for the purpose of
          processing personal information on its behalf. We also ensure that
          these recipients of such information agree to process such information
          based on our instructions and in compliance with this Privacy Policy
          and any other appropriate confidentiality and security measures.
        </h5>

        <h3>Grievance Redressal</h3>
        <h5>
          Redressal Mechanism: Any complaints, abuse or concerns with regards to
          content and or comment or breach of these terms shall be immediately
          informed to the designated Grievance Officer as mentioned below via in
          writing or through email signed with the electronic signature to
          …………………………….. (“Grievance Officer”).
        </h5>
        <h5>Mr. …………………………. (Grievance Officer)</h5>
        <h5>www………………………………..com</h5>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
