import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
function Checkbox({ label, onChange, checked, id }) {
    let Icon = CheckBoxOutlineBlankIcon;
    if (checked) Icon = CheckBoxIcon;
    return (
        <div className="checkbox">
            <input type="checkbox" id={id} hidden onChange={onChange} />
            <label htmlFor={id} className="d-flex align-items-center">
                <Icon style={{ color: "var(--text_color)" }} />
                <h5 htmlFor={id}>{label}</h5>
            </label>
        </div>
    );
}

export default Checkbox;
