import React from "react";
import Toggle from "./../../components/Toggle";
import error from "../../assets/error.svg";
function ErrorFlag({ handleToggle, toggle, show = true }) {
    return (
        <Toggle style={{ zIndex: 100 }} handleToggle={handleToggle} show={show}>
            <div className="d-flex align-items-center flex-column">
                <div style={{ width: "11rem" }}>
                    <img src={error} alt="" />
                </div>
                <h2>Oops!</h2>
                <h3>{toggle}</h3>
                {show && (
                    <button
                        className="btn btn--medium"
                        style={{
                            margin: "2rem 0",
                        }}
                        onClick={handleToggle}
                    >
                        Close
                    </button>
                )}
            </div>
        </Toggle>
    );
}

export default ErrorFlag;
